import React, {useState} from "react";
import cookie from "react-cookies";

const CookiesContext = React.createContext();

export function CookiesProvider(props) {
    const [funcional, setFuncional] = useState(
        cookie.load("funcional") ? true : false
    );

    const [analitica, setAnalitica] = useState(
        cookie.load("analitica") || cookie.load("analiticas") ? true : false
    );

    const [publicidad, setPublicidad] = useState(
        cookie.load("publicidad") ? true : false
    );

    async function addCookies(name) {
        const current = new Date();
        const nextYear = new Date();
        nextYear.setFullYear(current.getFullYear() + 1);
        cookie.save(name, "cookie: acepted", {
            path: "/",
            expires: nextYear,
        });
        if (name === "funcional") {
            setFuncional(true);
        }
        if (name === "analitica") {
            setAnalitica(true);
        }
        if (name === "publicidad") {
            setPublicidad(true);
        }
    }

    return (<CookiesContext.Provider
        value={{
            funcional,
            analitica,
            publicidad,
            addCookies,
        }}
        {...props}
        />
    );
}

export function useCookies() {
    const context = React.useContext(CookiesContext);
    if(!context) {
        throw new Error("useCookie debe estar dentro del proveedor CookieContext");
    }
    return context;
}