import React from "react";
import CertificateLetter from "./Letters/CertificateLetter";

const CertificateAndalucia = ({certificate}) => {

    return (
        <>
            <div className="card-body">
                <p><strong>Fecha</strong>: {certificate.date}</p>
                <p><strong>Dirección</strong>: {certificate.address}</p>
                <p><strong>Municipio</strong>: {certificate.municipality}</p>
                <p><strong>Codigo postal</strong>: {certificate.postal_code}</p>
                <p><strong>Zona climatica</strong>: {certificate.climatic_zone}</p>
                <p><strong>Consumo</strong>: {certificate.consumption.vector_energy_final.EnergiaPrimariaNoRenovable.global}</p>
                <p><strong>Emisiones </strong>: {certificate.emissions.global}</p>
                <p><strong>Classificación consumo </strong>: <CertificateLetter
                    letter={certificate.ranking.primary_energy_no_renewable.global}/></p>
                <p><strong>Classificación emisiones </strong>:<CertificateLetter
                    letter={certificate.ranking.emissions_Co2.global}/></p>
            </div>
        </>
    );
}


export default CertificateAndalucia;