import isSSR from "../Config/isSSR";


function gtag() {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(arguments)
}

export const pushDataLayerInit = (path) => {
    if(!isSSR() && process.env.REACT_APP_ENV === 'production') {
        gtag('js', new Date())
        gtag('config', 'G-30YDD43156', {
            page_path: path
        })
        console.log('entra');
    }else {
        console.log('No analitics');
    }
}