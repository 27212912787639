import React from "react";
import SimpleFooter from "../../components/Footers/SimpleFooter";
import DemoNavbar from "../../components/Navbars/DemoNavbar";
import {Col, Container, Row, UncontrolledAlert} from "reactstrap";
import Metas from "../../components/Metas/Metas";
import {CookiesProvider} from "../../components/Hooks/cookies";
import {ModalProvider} from "../../components/Hooks/modal";

class NotFoundPage extends React.Component {

    render() {
        const isDevelop = () => {
            return process.env.REACT_APP_ENV === 'development';
        }
        return (
            <>
                <ModalProvider>
                    <CookiesProvider>
                        <Metas
                            title={"404 La página no se encuentra | EnergyCertiificate"}
                            description={"Lo sentimos, la página que buscas no se encuentra."}
                            keys={"certificado, energético, ApiRest, integración, comunidad autónoma, certificados energéticos, eficiencia energética, edificios, consulta, búsqueda, referencia catastral"}
                        />
                        {isDevelop() && <UncontrolledAlert className={'alert alert-danger'} style={{
                            "position": "fixed",
                            "z-index": "150",
                            "width": "100%"
                        }}><strong>Atención!</strong> Has entrado en una web en entorno de desarrollo, todos los datos
                            son de prueba y desarrollo.</UncontrolledAlert>}
                        <DemoNavbar/>
                        <main ref="main">
                            <section className="section section-lg section-shaped">
                                <div className="shape shape-style-1 shape-default">
                                    <span/>
                                    <span/>
                                    <span/>
                                    <span/>
                                    <span/>
                                    <span/>
                                    <span/>
                                    <span/>
                                </div>
                            </section>
                            <section className="section section-lg">
                                <Container>
                                    <Row className="row-grid align-items-center text-justify">
                                        <Col className="order-md-2 offset-3" md="6">
                                            <img
                                                className="img-fluid floating center"
                                                src={require("assets/img/404/404.webp")}
                                                alt="404 no found"
                                                width={600}/>
                                        </Col>
                                    </Row>
                                </Container>
                            </section>
                        </main>
                        <SimpleFooter/>
                    </CookiesProvider>
                </ModalProvider>
            </>
        );
    }
}

export default NotFoundPage;
