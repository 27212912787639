import React from "react";

import {
    Badge,
    Button,
    Card,
    CardBody,
    Container,
    Row,
    Col, UncontrolledAlert
} from "reactstrap";

// core components
import DemoNavbar from "components/Navbars/DemoNavbar.js";
import SearchRc from "../../components/Forms/SearchRc";
import Contact from "../../components/Forms/Contact";
import SliderState from "../../components/Slider/SliderState";
import SimpleFooter from "../../components/Footers/SimpleFooter";
import Metas from "../../components/Metas/Metas";
import {ModalProvider} from "../../components/Hooks/modal";
import {CookiesProvider} from "../../components/Hooks/cookies";

class Landing extends React.Component {
    state = {};
    urlLoginBack = process.env.REACT_APP_API_URL_SERVER_LOGIN_BACK;

    componentDidMount() {
        document.documentElement.scrollTop = 0;
        document.scrollingElement.scrollTop = 0;
        this.refs.main.scrollTop = 0;
    }

    render() {
        const searchAnchor = () => {
            window.location.href = "#search"
        };

        const easyIntegration = () => {
            window.location.href = "#easyIntegration"
        };
        const isDevelop = () => {
            return process.env.REACT_APP_ENV === 'development';
        }

        return (
            <>
                <ModalProvider>
                    <CookiesProvider>
                        <Metas
                            title={"ApiRest certificados energeticos | EnergyCertiificate"}
                            description={"EnergyCertiificate es una web para una integración ApiRest para obtener los certificados energéticos de los inmuebles en distintas comunidades autónomas."}
                            keys={"certificado, energético, ApiRest, integración, comunidad autónoma, certificados energéticos, eficiencia energética, edificios, consulta, búsqueda, referencia catastral, buscador de certificados energeticos, buscador certificados energeticos, buscador certificados energeticos aragon, buscador certificados energeticos cataluña, buscador certificados energeticos andalucia, buscador certificados energeticos madrid, buscador certificados energeticos asturias, apiRest certificado energetico"}
                            pageName={""}
                        />
                        {isDevelop() && <UncontrolledAlert className={'alert alert-danger'} style={{
                            "position": "fixed",
                            "z-index": "150",
                            "width": "100%"
                        }}><strong>Atención!</strong> Has entrado en una web en entorno de desarrollo, todos los datos
                            son de prueba y desarrollo.</UncontrolledAlert>}
                        <DemoNavbar/>
                        <main ref="main">
                            <div className="position-relative">
                                {/* shape Hero */}
                                <section className="section section-lg section-shaped pb-250">
                                    <div className="shape shape-style-1 shape-default">
                                        <span/>
                                        <span/>
                                        <span/>
                                        <span/>
                                        <span/>
                                        <span/>
                                        <span/>
                                        <span/>
                                        <span/>
                                    </div>
                                    <Container className="py-lg-md d-flex">
                                        <div className="col px-0">
                                            <Row>
                                                <Col lg="8">
                                                    <h1 className="display-3 text-white">
                                                        Api Rest
                                                        <span>Certificados energéticos</span>
                                                    </h1>
                                                    <p className="lead text-white">
                                                        Api Rest preparada para devolver los certificados energéticos de
                                                        las comunidades autónomas de España.
                                                    </p>
                                                </Col>
                                            </Row>
                                        </div>
                                    </Container>
                                    {/* SVG separator */}
                                    <div className="separator separator-bottom separator-skew">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            preserveAspectRatio="none"
                                            version="1.1"
                                            viewBox="0 0 2560 100"
                                            x="0"
                                            y="0"
                                        >
                                            <polygon
                                                className="fill-white"
                                                points="2560 0 2560 100 0 100"
                                            />
                                        </svg>
                                    </div>
                                </section>
                                {/* 1st Hero Variation */}
                            </div>
                            <section className="section section-lg pt-lg-0 mt--200">
                                <Container>
                                    <Row className="justify-content-center">
                                        <Col lg="12">
                                            <Row className="row-grid">
                                                <Col lg="4">
                                                    <Card className="card-lift--hover shadow border-0">
                                                        <CardBody className="py-5">
                                                            <div
                                                                className="icon icon-shape icon-shape-primary rounded-circle mb-4">
                                                                <i className="ni ni-check-bold"/>
                                                            </div>
                                                            <h6 className="text-primary text-uppercase">
                                                                Integración
                                                            </h6>
                                                            <p className="description mt-3">
                                                                Fácil integración en tus proyectos o en tu gestor de
                                                                contenido.
                                                            </p>
                                                            <div>
                                                                <Badge color="primary" pill className="mr-1">
                                                                    Facilidad
                                                                </Badge>
                                                                <Badge color="primary" pill className="mr-1">
                                                                    integración
                                                                </Badge>
                                                                <Badge color="primary" pill className="mr-1">
                                                                    proyectos
                                                                </Badge>
                                                            </div>
                                                            <Button
                                                                className="mt-4"
                                                                color="primary"
                                                                onClick={easyIntegration}
                                                            >
                                                                Ver más
                                                            </Button>
                                                        </CardBody>
                                                    </Card>
                                                </Col>
                                                <Col lg="4">
                                                    <Card className="card-lift--hover shadow border-0">
                                                        <CardBody className="py-5">
                                                            <div
                                                                className="icon icon-shape icon-shape-success rounded-circle mb-4">
                                                                <i className="ni ni-istanbul"/>
                                                            </div>
                                                            <h6 className="text-success text-uppercase">
                                                                Actualizaciones
                                                            </h6>
                                                            <p className="description mt-3">
                                                                Los certificados energéticos están actualizados en los
                                                                últimos ficheros facilitados por las comunidades
                                                                autónomas.
                                                            </p>
                                                            <div>
                                                                <Badge color="success" pill className="mr-1">
                                                                    Actualizados
                                                                </Badge>
                                                                <Badge color="success" pill className="mr-1">
                                                                    CCAA
                                                                </Badge>
                                                                <Badge color="success" pill className="mr-1">
                                                                    Al día
                                                                </Badge>
                                                            </div>
                                                            <Button
                                                                className="mt-4"
                                                                color="success"
                                                                onClick={searchAnchor}
                                                            >
                                                                Prueba nuestro buscador
                                                            </Button>
                                                        </CardBody>
                                                    </Card>
                                                </Col>
                                                <Col lg="4">
                                                    <Card className="card-lift--hover shadow border-0">
                                                        <CardBody className="py-5">
                                                            <div
                                                                className="icon icon-shape icon-shape-warning rounded-circle mb-4">
                                                                <i className="ni ni-planet"/>
                                                            </div>
                                                            <h6 className="text-warning text-uppercase">
                                                                Suscripción
                                                            </h6>
                                                            <p className="description mt-3">
                                                                Mediante una suscripción mensual podrás acceder a la
                                                                API-Rest. Suscríbete desde 9,99 € /mes.
                                                            </p>
                                                            <div>
                                                                <Badge color="warning" pill className="mr-1">
                                                                    Suscripción
                                                                </Badge>
                                                                <Badge color="warning" pill className="mr-1">
                                                                    Api-Rest
                                                                </Badge>
                                                                <Badge color="warning" pill className="mr-1">
                                                                    Mensual
                                                                </Badge>
                                                            </div>
                                                            <Button
                                                                className="mt-4"
                                                                color="warning"
                                                                href={this.urlLoginBack}
                                                                innerRef={this.urlLoginBack}
                                                            >
                                                                Suscríbete
                                                            </Button>
                                                        </CardBody>
                                                    </Card>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Container>
                            </section>
                            <section className="section section-lg" id='easyIntegration'>
                                <Container>
                                    <Row className="row-grid align-items-center">
                                        <Col className="order-md-2" md="6">
                                            <img
                                                alt="facil-integracion"
                                                className="img-fluid floating"
                                                src={require("assets/img/theme/promo-1.png")}
                                            />
                                        </Col>
                                        <Col className="order-md-1" md="6">
                                            <div className="pr-md-5">
                                                <div
                                                    className="icon icon-lg icon-shape icon-shape-success shadow rounded-circle mb-5">
                                                    <i className="ni ni-settings-gear-65"/>
                                                </div>
                                                <h3>Fácil integración</h3>
                                                <p>
                                                    Integra nuestra ApiRest en tu proyecto o Crm. Dispone de
                                                    certificados energéticos de varias comunidades autónomas.
                                                </p>
                                                <ul className="list-unstyled mt-5">
                                                    <li className="py-2">
                                                        <div className="d-flex align-items-center">
                                                            <div>
                                                                <Badge
                                                                    className="badge-circle mr-3"
                                                                    color="success"
                                                                >
                                                                    <i className="ni ni-settings-gear-65"/>
                                                                </Badge>
                                                            </div>
                                                            <div>
                                                                <h6 className="mb-0">
                                                                    Consulta certificados energéticos de 15 comunidades
                                                                    autónomas.
                                                                </h6>
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li className="py-2">
                                                        <div className="d-flex align-items-center">
                                                            <div>
                                                                <Badge
                                                                    className="badge-circle mr-3"
                                                                    color="success"
                                                                >
                                                                    <i className="ni ni-html5"/>
                                                                </Badge>
                                                            </div>
                                                            <div>
                                                                <h6 className="mb-0">Dispone de documentación
                                                                    detallada.</h6>
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li className="py-2">
                                                        <div className="d-flex align-items-center">
                                                            <div>
                                                                <Badge
                                                                    className="badge-circle mr-3"
                                                                    color="success"
                                                                >
                                                                    <i className="ni ni-satisfied"/>
                                                                </Badge>
                                                            </div>
                                                            <div>
                                                                <h6 className="mb-0">
                                                                    Consulta en nuestra web las últimas actualizaciones.
                                                                </h6>
                                                            </div>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                        </Col>
                                    </Row>
                                </Container>
                            </section>
                            <section className="section bg-secondary" id="search">
                                <Row className="justify-content-center">
                                    <Col lg="8">
                                        <Card className="bg-gradient-secondary shadow">
                                            <CardBody className="p-lg-5">
                                                <h4 className="mb-1">Buscador de certificados energéticos</h4>
                                                <p className="mt-0">
                                                    Selecciona una comunidad autónoma y introduce la referencia
                                                    catastral del inmueble que buscas.
                                                </p>
                                                <SearchRc/>

                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            </section>
                            <section className="section section-lg">
                                <Container>
                                    <Row className="justify-content-center text-center mb-lg">
                                        <Col lg="8">
                                            <h2 className="display-3">Actualizaciones de certificados energéticos</h2>
                                            <p className="lead text-muted">
                                                Consulta aquí sobre las últimas actualizaciones de las comunidades
                                                autónomas
                                            </p>
                                        </Col>
                                    </Row>
                                    <SliderState/>
                                </Container>
                            </section>

                            <section className="section section-lg bg-gradient-purple">
                                <Container className="pt-lg pb-300">
                                    <Row className="text-center justify-content-center">
                                        <Col lg="10">
                                            <h2 className="display-3 text-white">Construimos un futuro de
                                                facilidades</h2>
                                            <p className="lead text-white">
                                                Desde EnergyCertiificates queremos facilitar el acceso a los
                                                certificados energéticos a nuestros clientes
                                                para que puedan integrarlo tanto en su web como en sus gestores de
                                                contenidos.
                                            </p>
                                        </Col>
                                    </Row>
                                    <Row className="row-grid mt-5">
                                        <Col lg="4">
                                            <div
                                                className="icon icon-lg icon-shape bg-gradient-white shadow rounded-circle text-primary">
                                                <i className="ni ni-settings text-primary"/>
                                            </div>
                                            <h5 className="text-white mt-3">Herramientas</h5>
                                            <p className="text-white mt-3">
                                                Acede a toda la documentación necesaria para integrar la ApiRest una vez
                                                suscrito a nuestro servicio.
                                            </p>
                                        </Col>
                                        <Col lg="4">
                                            <div
                                                className="icon icon-lg icon-shape bg-gradient-white shadow rounded-circle text-primary">
                                                <i className="ni ni-ruler-pencil text-primary"/>
                                            </div>
                                            <h5 className="text-white mt-3">Area cliente</h5>
                                            <p className="text-white mt-3">
                                                Desde el área cliente puedes acceder tanto a las facturas, como a todos
                                                los datos necesarios para la ApiRest.
                                            </p>
                                        </Col>
                                        <Col lg="4">
                                            <div
                                                className="icon icon-lg icon-shape bg-gradient-white shadow rounded-circle text-primary">
                                                <i className="ni ni-atom text-primary"/>
                                            </div>
                                            <h5 className="text-white mt-3">Actualizaciones constantes</h5>
                                            <p className="text-white mt-3">
                                                Se añadirán más funcionalidades, tanto a nivel de ApiRest como de área
                                                cliente. Recibe también las últimas actualizaciones.
                                            </p>
                                        </Col>
                                    </Row>
                                </Container>
                                {/* SVG separator */}
                                <div className="separator separator-bottom separator-skew zindex-100">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        preserveAspectRatio="none"
                                        version="1.1"
                                        viewBox="0 0 2560 100"
                                        x="0"
                                        y="0"
                                    >
                                        <polygon
                                            className="fill-white"
                                            points="2560 0 2560 100 0 100"
                                        />
                                    </svg>
                                </div>
                            </section>
                            <section className="section section-lg pt-lg-0 section-contact-us">
                                <Container>
                                    <Row className="justify-content-center mt--300">
                                        <Col lg="8">
                                            <Card className="bg-gradient-secondary shadow">
                                                <CardBody className="p-lg-5">
                                                    <h4 className="mb-1">¿Necesitas mas información?</h4>
                                                    <p className="mt-0">
                                                        Contacta con nosotros.
                                                    </p>
                                                    <Contact/>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Row>
                                </Container>
                            </section>
                        </main>
                        <SimpleFooter/>
                    </CookiesProvider>
                </ModalProvider>

            </>
        );
    }
}

export default Landing;
