import React from "react";
import CertificateLetter from "./Letters/CertificateLetter";

const CertificatePaisVasco = ({certificate}) => {

    return (
        <>
            <div className="card-body">
                <p><strong>Codigo de registro</strong>: {certificate.num_register}</p>
                <p><strong>Dirección</strong>: {certificate.full_address}</p>
                <p><strong>Municipio</strong>: {certificate.municipality}</p>
                <p><strong>Codigo postal</strong>: {certificate.postal_code}</p>
                <p><strong>Zona climatica</strong>: {certificate.climatic_zone}</p>

                <p><strong>Consumo global</strong>: {certificate.consumption}</p>
                <p><strong>Emisiones </strong>: {certificate.emissions}</p>
                <p><strong>Classificación consumo </strong>: <CertificateLetter
                    letter={certificate.ranking_consumption}/></p>
                <p><strong>Classificación emisiones </strong>:<CertificateLetter
                    letter={certificate.ranking_emissions}/></p>
            </div>
        </>

    );
}


export default CertificatePaisVasco;