import React from "react";

import {
    Card, CardBody,
    Col,
    Container, Nav, NavItem, NavLink,
    Row, TabContent, TabPane, UncontrolledAlert
} from "reactstrap";

// core components
import DemoNavbar from "components/Navbars/DemoNavbar.js";
import SimpleFooter from "../../components/Footers/SimpleFooter";
import classnames from "classnames";
import {Prism as SyntaxHighlighter} from "react-syntax-highlighter";
import {dark} from "react-syntax-highlighter/src/styles/prism";
import Metas from "../../components/Metas/Metas";
import {CookiesProvider} from "../../components/Hooks/cookies";
import {ModalProvider} from "../../components/Hooks/modal";

class Documentation extends React.Component {
    state = {
        iconTabs: 1,
        plainTabs: 1,
    };
    toggleNavs = (e, state, index) => {
        e.preventDefault();
        this.setState({
            [state]: index,
        });
    };

    render() {
        const isDevelop = () => {
            return process.env.REACT_APP_ENV === 'development';
        }

        return (
            <>
                <ModalProvider>
                    <CookiesProvider>
                        <Metas
                            title={"Documentación | EnergyCertiificate"}
                            description={"EnergyCertiificate es una web para una integración ApiRest para obtener los certificados energéticos de los inmuebles en distintas comunidades autónomas."}
                            keys={"certificado, energetico, ApiRest, integración, comunidad autonoma, inmuebles, inmobiliarias"}
                            pageName={"documentacion"}
                        />
                        {isDevelop() && <UncontrolledAlert className={'alert alert-danger'} style={{
                            "position": "fixed",
                            "z-index": "150",
                            "width": "100%"
                        }}><strong>Atención!</strong> Has entrado en una web en entorno de desarrollo, todos los datos
                            son de
                            prueba y desarrollo.</UncontrolledAlert>}
                        <DemoNavbar/>
                        <main ref="main">
                            <div className="position-relative">
                                <section className="section section-lg section-shaped ">
                                    <div className="shape shape-style-1 shape-default">
                                        <span/>
                                        <span/>
                                        <span/>
                                        <span/>
                                        <span/>
                                        <span/>
                                        <span/>
                                        <span/>
                                    </div>
                                    <Container className="py-lg-md d-flex">
                                        <div className="col px-0">
                                            <Row>
                                                <Col lg="8">
                                                    <h1 className="display-3 text-white">
                                                        Documentación
                                                    </h1>
                                                </Col>
                                            </Row>
                                        </div>
                                    </Container>
                                </section>

                                <section className="section section-lg pt-lg-0 mt-5">
                                    <Container>
                                        <Row className="justify-content-center">
                                            <div className="nav-wrapper">
                                                <Nav
                                                    className="nav-fill flex-column flex-md-row"
                                                    id="tabs-icons-text"
                                                    pills
                                                    role="tablist"
                                                >
                                                    <NavItem>
                                                        <NavLink
                                                            aria-selected={this.state.iconTabs === 1}
                                                            className={classnames("mb-sm-3 mb-md-2", {
                                                                active: this.state.iconTabs === 1,
                                                            })}
                                                            onClick={(e) => this.toggleNavs(e, "iconTabs", 1)}
                                                            href="#login"
                                                            role="tab"
                                                        >
                                                            <i className="ni ni-user-run mr-2"/>
                                                            Login
                                                        </NavLink>
                                                    </NavItem>
                                                    <NavItem>
                                                        <NavLink
                                                            aria-selected={this.state.iconTabs === 2}
                                                            className={classnames("mb-sm-3  mb-md-2", {
                                                                active: this.state.iconTabs === 2,
                                                            })}
                                                            onClick={(e) => this.toggleNavs(e, "iconTabs", 2)}
                                                            href="#andalucia"
                                                            role="tab"
                                                        >
                                                            <i className="ni ni-building mr-2"/>
                                                            Andalucía
                                                        </NavLink>
                                                    </NavItem>
                                                    <NavItem>
                                                        <NavLink
                                                            aria-selected={this.state.iconTabs === 3}
                                                            className={classnames("mb-sm-3 mb-md-2", {
                                                                active: this.state.iconTabs === 3,
                                                            })}
                                                            onClick={(e) => this.toggleNavs(e, "iconTabs", 3)}
                                                            href="#aragon"
                                                            role="tab"
                                                        >
                                                            <i className="ni ni-building mr-2"/>
                                                            Aragón
                                                        </NavLink>
                                                    </NavItem>
                                                    <NavItem>
                                                        <NavLink
                                                            aria-selected={this.state.iconTabs === 4}
                                                            className={classnames("mb-sm-3 mb-md-2", {
                                                                active: this.state.iconTabs === 4,
                                                            })}
                                                            onClick={(e) => this.toggleNavs(e, "iconTabs", 4)}
                                                            href="#asturias"
                                                            role="tab"
                                                        >
                                                            <i className="ni ni-building mr-2"/>
                                                            Asturias
                                                        </NavLink>
                                                    </NavItem>
                                                    <NavItem>
                                                        <NavLink
                                                            aria-selected={this.state.iconTabs === 5}
                                                            className={classnames("mb-sm-3 mb-md-2", {
                                                                active: this.state.iconTabs === 5,
                                                            })}
                                                            onClick={(e) => this.toggleNavs(e, "iconTabs", 5)}
                                                            href="#baleares"
                                                            role="tab"
                                                        >
                                                            <i className="ni ni-building mr-2"/>
                                                            Baleares
                                                        </NavLink>
                                                    </NavItem>
                                                    <NavItem>
                                                        <NavLink
                                                            aria-selected={this.state.iconTabs === 6}
                                                            className={classnames("mb-sm-3 mb-md-2", {
                                                                active: this.state.iconTabs === 6,
                                                            })}
                                                            onClick={(e) => this.toggleNavs(e, "iconTabs", 6)}
                                                            href="#canarias"
                                                            role="tab"
                                                        >
                                                            <i className="ni ni-building mr-2"/>
                                                            Canarias
                                                        </NavLink>
                                                    </NavItem>
                                                    <NavItem>
                                                        <NavLink
                                                            aria-selected={this.state.iconTabs === 7}
                                                            className={classnames("mb-sm-3 mb-md-2", {
                                                                active: this.state.iconTabs === 7,
                                                            })}
                                                            onClick={(e) => this.toggleNavs(e, "iconTabs", 7)}
                                                            href="#cantabria"
                                                            role="tab"
                                                        >
                                                            <i className="ni ni-building mr-2"/>
                                                            Cantabria
                                                        </NavLink>
                                                    </NavItem>
                                                    <NavItem>
                                                        <NavLink
                                                            aria-selected={this.state.iconTabs === 8}
                                                            className={classnames("mb-sm-3 mb-md-2", {
                                                                active: this.state.iconTabs === 8,
                                                            })}
                                                            onClick={(e) => this.toggleNavs(e, "iconTabs", 8)}
                                                            href="#castilla-la-mancha"
                                                            role="tab"
                                                        >
                                                            <i className="ni ni-building mr-2"/>
                                                            Castilla La Mancha
                                                        </NavLink>
                                                    </NavItem>
                                                    <NavItem>
                                                        <NavLink
                                                            aria-selected={this.state.iconTabs === 9}
                                                            className={classnames("mb-sm-3 mb-md-2", {
                                                                active: this.state.iconTabs === 9,
                                                            })}
                                                            onClick={(e) => this.toggleNavs(e, "iconTabs", 9)}
                                                            href="#castilla-y-leon"
                                                            role="tab"
                                                        >
                                                            <i className="ni ni-building mr-2"/>
                                                            Castilla y León
                                                        </NavLink>
                                                    </NavItem>
                                                    <NavItem>
                                                        <NavLink
                                                            aria-selected={this.state.iconTabs === 10}
                                                            className={classnames("mb-sm-3 mb-md-2", {
                                                                active: this.state.iconTabs === 10,
                                                            })}
                                                            onClick={(e) => this.toggleNavs(e, "iconTabs", 10)}
                                                            href="#catalunia"
                                                            role="tab"
                                                        >
                                                            <i className="ni ni-building mr-2"/>
                                                            Cataluña
                                                        </NavLink>
                                                    </NavItem>
                                                    <NavItem>
                                                        <NavLink
                                                            aria-selected={this.state.iconTabs === 11}
                                                            className={classnames("mb-sm-3 mb-md-2", {
                                                                active: this.state.iconTabs === 11,
                                                            })}
                                                            onClick={(e) => this.toggleNavs(e, "iconTabs", 11)}
                                                            href="#extremadura"
                                                            role="tab"
                                                        >
                                                            <i className="ni ni-building mr-2"/>
                                                            Extremadura
                                                        </NavLink>
                                                    </NavItem>
                                                    <NavItem>
                                                        <NavLink
                                                            aria-selected={this.state.iconTabs === 12}
                                                            className={classnames("mb-sm-3 mb-md-2", {
                                                                active: this.state.iconTabs === 12,
                                                            })}
                                                            onClick={(e) => this.toggleNavs(e, "iconTabs", 12)}
                                                            href="#galicia"
                                                            role="tab"
                                                        >
                                                            <i className="ni ni-building mr-2"/>
                                                            Galicia
                                                        </NavLink>
                                                    </NavItem>
                                                    <NavItem>
                                                        <NavLink
                                                            aria-selected={this.state.iconTabs === 13}
                                                            className={classnames("mb-sm-3 mb-md-2", {
                                                                active: this.state.iconTabs === 13,
                                                            })}
                                                            onClick={(e) => this.toggleNavs(e, "iconTabs", 13)}
                                                            href="#madrid"
                                                            role="tab"
                                                        >
                                                            <i className="ni ni-building mr-2"/>
                                                            Madrid
                                                        </NavLink>
                                                    </NavItem>
                                                    <NavItem>
                                                        <NavLink
                                                            aria-selected={this.state.iconTabs === 14}
                                                            className={classnames("mb-sm-3 mb-md-2", {
                                                                active: this.state.iconTabs === 14,
                                                            })}
                                                            onClick={(e) => this.toggleNavs(e, "iconTabs", 14)}
                                                            href="#murcia"
                                                            role="tab"
                                                        >
                                                            <i className="ni ni-building mr-2"/>
                                                            Murcia
                                                        </NavLink>
                                                    </NavItem>
                                                    <NavItem>
                                                        <NavLink
                                                            aria-selected={this.state.iconTabs === 15}
                                                            className={classnames("mb-sm-3 mb-md-2", {
                                                                active: this.state.iconTabs === 15,
                                                            })}
                                                            onClick={(e) => this.toggleNavs(e, "iconTabs", 15)}
                                                            href="#navarra"
                                                            role="tab"
                                                        >
                                                            <i className="ni ni-building mr-2"/>
                                                            Navarra
                                                        </NavLink>
                                                    </NavItem>
                                                    <NavItem>
                                                        <NavLink
                                                            aria-selected={this.state.iconTabs === 16}
                                                            className={classnames("mb-sm-3 mb-md-2", {
                                                                active: this.state.iconTabs === 16,
                                                            })}
                                                            onClick={(e) => this.toggleNavs(e, "iconTabs", 16)}
                                                            href="#pais-vasco"
                                                            role="tab"
                                                        >
                                                            <i className="ni ni-building mr-2"/>
                                                            Pais Vasco
                                                        </NavLink>
                                                    </NavItem>
                                                    <NavItem>
                                                        <NavLink
                                                            aria-selected={this.state.iconTabs === 17}
                                                            className={classnames("mb-sm-3 mb-md-2", {
                                                                active: this.state.iconTabs === 17,
                                                            })}
                                                            onClick={(e) => this.toggleNavs(e, "iconTabs", 17)}
                                                            href="#la-rioja"
                                                            role="tab"
                                                        >
                                                            <i className="ni ni-building mr-2"/>
                                                            La Rioja
                                                        </NavLink>
                                                    </NavItem>
                                                    <NavItem>
                                                        <NavLink
                                                            aria-selected={this.state.iconTabs === 18}
                                                            className={classnames("mb-sm-3 mb-md-2", {
                                                                active: this.state.iconTabs === 18,
                                                            })}
                                                            onClick={(e) => this.toggleNavs(e, "iconTabs", 18)}
                                                            href="#valencia"
                                                            role="tab"
                                                        >
                                                            <i className="ni ni-building mr-2"/>
                                                            Valencia
                                                        </NavLink>
                                                    </NavItem>
                                                    <NavItem>
                                                        <NavLink
                                                            aria-selected={this.state.iconTabs === 19}
                                                            className={classnames("mb-sm-3 mb-md-2", {
                                                                active: this.state.iconTabs === 19,
                                                            })}
                                                            onClick={(e) => this.toggleNavs(e, "iconTabs", 19)}
                                                            href="#valencia"
                                                            role="tab"
                                                        >
                                                            <i className="ni ni-building mr-2"/>
                                                            Ceuta
                                                        </NavLink>
                                                    </NavItem>
                                                    <NavItem>
                                                        <NavLink
                                                            aria-selected={this.state.iconTabs === 20}
                                                            className={classnames("mb-sm-3 mb-md-2", {
                                                                active: this.state.iconTabs === 20,
                                                            })}
                                                            onClick={(e) => this.toggleNavs(e, "iconTabs", 20)}
                                                            href="#valencia"
                                                            role="tab"
                                                        >
                                                            <i className="ni ni-building mr-2"/>
                                                            Melilla
                                                        </NavLink>
                                                    </NavItem>
                                                </Nav>
                                            </div>
                                            <Card className="shadow">
                                                <CardBody>
                                                    <TabContent activeTab={"iconTabs" + this.state.iconTabs}>
                                                        <TabPane tabId="iconTabs1">
                                                            <h3>Url</h3>
                                                            <p>Inicio de sesión con el usuario.</p>
                                                            <SyntaxHighlighter
                                                                language={"json"}
                                                                style={dark}
                                                            >
                                                                {
                                                                    `POST api/login`
                                                                }
                                                            </SyntaxHighlighter>
                                                            <h3>Request</h3>
                                                            <SyntaxHighlighter
                                                                language={"json"}
                                                                style={dark}
                                                            >
                                                                {
                                                                    `{
    "email": "tuemail@email.com",
    "password": "******"
}`
                                                                }
                                                            </SyntaxHighlighter>
                                                            <h3>Response</h3>
                                                            <SyntaxHighlighter
                                                                language={"json"}
                                                                style={dark}
                                                            >
                                                                {`
{
    "data": {
        "token": "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1Nig6.eyJpc3MiOiJodHRwczotL2Rldi5iYWNrLmVuZXJneWNlcnRpaWZpY2F0ZS5jb20vYXBpL2xvZ2luIiwiaWF0IjoxNzExMjIyNjUwLCJleHAiOjE3MTEyMjYyNTAsIm5iZiI6MTcxMTIyMjY1MCwianRpIjoicTdNTlk4ZDBZVmY2NDg0dyIsInN1YiI6IjY1ZmYyZjViNDAxMjZhZjVhYzA2M2JhYyIsInBydiI6IjNjODNhNWJlOWE5OTU0ZTZlOTI5YjgxZmNjMWEwYzBlZTcwMTExYTcifQ.XhAffhbHcIqQ1UxfI1T-7VDLKH7iZ58LB1s85IZCvvE",
        "minutes_to_expire": 60
    },
    "meta": {
        "success": true,
        "errors": []
    }
}
                                                            `}
                                                            </SyntaxHighlighter>
                                                        </TabPane>
                                                        <TabPane tabId="iconTabs2">
                                                            <h3>Url</h3>
                                                            <p>Búsqueda de certificados por referencia catastral.</p>
                                                            <SyntaxHighlighter
                                                                language={"json"}
                                                                style={dark}
                                                            >
                                                                {
                                                                    `GET api/andalucia/energy-certificate/{rc}`
                                                                }
                                                            </SyntaxHighlighter>
                                                            <h3>Request</h3>
                                                            <SyntaxHighlighter
                                                                language={"json"}
                                                                style={dark}
                                                            >
                                                                {
                                                                    `{

}`
                                                                }
                                                            </SyntaxHighlighter>

                                                            <h3>Response</h3>
                                                            <SyntaxHighlighter
                                                                language={"json"}
                                                                style={dark}
                                                            >
                                                                {
                                                                    `{
    "data": {
        "certificate": [
            {
                "date": "2019-06-02",
                "address": "C/TRINQUETE, Nº19- ESC 23-2º-2-ALMERIMAR",
                "municipality": "El Ejido",
                "postal_code": "04711",
                "province": "Almería",
                "climatic_zone": "A4",
                "rc": "9214501WF1691S0193QP",
                "building_type": "ViviendaIndividualEnBloque",
                "thermal_installations": {
                    "heating_generators": [
                        {
                            "name": "MITSUBISHI MS-18RV",
                            "type": "Bomba de Calor",
                            "nominal_power": "5.40",
                            "seasonal_power": "1.71",
                            "energetic_vector": "ElectricidadPeninsular",
                            "method_of_production": "Estimado"
                        }
                    ],
                    "refrigeration_generators": [
                        {
                            "name": "MITSUBISHI MS-18RV",
                            "type": "Bomba de Calor",
                            "nominal_power": "5.10",
                            "seasonal_power": "1.59",
                            "energetic_vector": "ElectricidadPeninsular",
                            "method_of_production": "Estimado"
                        }
                    ],
                    "ACS_installations": [
                        {
                            "name": "CALDERA VAILLANT atmoMAG",
                            "type": "Caldera Estándar",
                            "nominal_power": "21.00",
                            "seasonal_power": "0.66",
                            "energetic_vector": "GLP",
                            "method_of_production": "Estimado"
                        }
                    ]
                },
                "lightning_installation": {
                    "total_power_installation": "0"
                },
                "renewable_energy": {
                    "thermal": [],
                    "electric": []
                },
                "uses": {
                    "global": "87.01",
                    "heating": "44.50",
                    "refrigeration": "26.95",
                    "ACS": "15.56"
                },
                "consumption": {
                    "vector_energy_final": {
                        "EnergiaFinalVectores": [],
                        "EnergiaPrimariaNoRenovable": {
                            "global": "112.36",
                            "heating": "53.74",
                            "refrigeration": "30.10",
                            "cs": "0",
                            "lightning": "0.00"
                        }
                    }
                },
                "emissions": {
                    "global": "21.30",
                    "heating": "10.17",
                    "refrigeration": "5.10",
                    "ACS": "6.03",
                    "lightning": "0.00",
                    "lighting_consumption": "9.94",
                    "others_consumption": "11.37",
                    "lightning_total_consumption": "368.31",
                    "Others_total_consumption": "421.33"
                },
                "ranking": {
                    "uses": {
                        "heating_ranking": {
                            "A": "3.00",
                            "B": "7.00",
                            "C": "12.70",
                            "D": "21.20",
                            "E": "46.60",
                            "F": "50.70"
                        },
                        "refrigeration_ranking": {
                            "A": "7.80",
                            "B": "12.60",
                            "C": "19.50",
                            "D": "30.00",
                            "E": "36.90",
                            "F": "45.40"
                        }
                    },
                    "primary_energy_no_renewable": {
                        "global_ranking": {
                            "A": "13.70",
                            "B": "25.90",
                            "C": "43.80",
                            "D": "70.20",
                            "E": "144.60",
                            "F": "157.60"
                        },
                        "global": "E",
                        "heating": "E",
                        "refrigeration": "D",
                        "ACS": "G",
                        "lightning": []
                    },
                    "emissions_Co2": {
                        "global_ranking": {
                            "A": "3.20",
                            "B": "6.10",
                            "C": "10.30",
                            "D": "16.40",
                            "E": "35.20",
                            "F": "38.40"
                        },
                        "global": "E",
                        "heating": "E",
                        "refrigeration": "D",
                        "ACS": "G",
                        "lightning": []
                    }
                }
            }
        ]
    },
    "meta": {
        "success": true,
        "errors": []
    }
}`
                                                                }
                                                            </SyntaxHighlighter>

                                                            <h3>Url</h3>
                                                            <p>Búsqueda de certificados por filtros.</p>
                                                            <SyntaxHighlighter
                                                                language={"json"}
                                                                style={dark}
                                                            >
                                                                {
                                                                    `POST api/andalucia/energy-certificate/search`
                                                                }
                                                            </SyntaxHighlighter>
                                                            <h3>Request</h3>
                                                            <p>
                                                                Filtrar por la letra de consumo:
                                                                <ul>
                                                                    <li><strong>ranking_consumption_letter:</strong> A,
                                                                        B, C, D, E, F
                                                                    </li>
                                                                </ul>
                                                                Filtrar por la letra de emisiones:
                                                                <ul>
                                                                    <li><strong>ranking_emissions_letter:</strong> A, B,
                                                                        C, D, E, F
                                                                    </li>
                                                                </ul>
                                                                Filtrar por el código postal:
                                                                <ul>
                                                                    <li><strong>postal_code:</strong>04711</li>
                                                                </ul>
                                                                Filtrar por la dirección:
                                                                <ul>
                                                                    <li><strong>address:</strong> C/TRINQUETE</li>
                                                                </ul>
                                                            </p>
                                                            <SyntaxHighlighter
                                                                language={"json"}
                                                                style={dark}
                                                            >
                                                                {
                                                                    `{
    "ranking_consumption_letter":"E",
    "ranking_emissions_letter":"E",
    "postal_code": "04711",
    "address": "C/TRINQUETE, ",
    "page": 1
}`
                                                                }
                                                            </SyntaxHighlighter>
                                                            <h3>Response</h3>
                                                            <SyntaxHighlighter
                                                                language={"json"}
                                                                style={dark}
                                                            >
                                                                {
                                                                    `{
    "data": {
        "certificate": [
            {
                "date": "2019-06-02",
                "address": "C/TRINQUETE, Nº19- ESC 23-2º-2-ALMERIMAR",
                "municipality": "El Ejido",
                "postal_code": "04711",
                "province": "Almería",
                "climatic_zone": "A4",
                "rc": "9214501WF1691S0193QP",
                "building_type": "ViviendaIndividualEnBloque",
                "thermal_installations": {
                    "heating_generators": [
                        {
                            "name": "MITSUBISHI MS-18RV",
                            "type": "Bomba de Calor",
                            "nominal_power": "5.40",
                            "seasonal_power": "1.71",
                            "energetic_vector": "ElectricidadPeninsular",
                            "method_of_production": "Estimado"
                        }
                    ],
                    "refrigeration_generators": [
                        {
                            "name": "MITSUBISHI MS-18RV",
                            "type": "Bomba de Calor",
                            "nominal_power": "5.10",
                            "seasonal_power": "1.59",
                            "energetic_vector": "ElectricidadPeninsular",
                            "method_of_production": "Estimado"
                        }
                    ],
                    "ACS_installations": [
                        {
                            "name": "CALDERA VAILLANT atmoMAG",
                            "type": "Caldera Estándar",
                            "nominal_power": "21.00",
                            "seasonal_power": "0.66",
                            "energetic_vector": "GLP",
                            "method_of_production": "Estimado"
                        }
                    ]
                },
                "lightning_installation": {
                    "total_power_installation": "0"
                },
                "renewable_energy": {
                    "thermal": [],
                    "electric": []
                },
                "uses": {
                    "global": "87.01",
                    "heating": "44.50",
                    "refrigeration": "26.95",
                    "ACS": "15.56"
                },
                "consumption": {
                    "vector_energy_final": {
                        "EnergiaFinalVectores": [],
                        "EnergiaPrimariaNoRenovable": {
                            "global": "112.36",
                            "heating": "53.74",
                            "refrigeration": "30.10",
                            "cs": "0",
                            "lightning": "0.00"
                        }
                    }
                },
                "emissions": {
                    "global": "21.30",
                    "heating": "10.17",
                    "refrigeration": "5.10",
                    "ACS": "6.03",
                    "lightning": "0.00",
                    "lighting_consumption": "9.94",
                    "others_consumption": "11.37",
                    "lightning_total_consumption": "368.31",
                    "Others_total_consumption": "421.33"
                },
                "ranking": {
                    "uses": {
                        "heating_ranking": {
                            "A": "3.00",
                            "B": "7.00",
                            "C": "12.70",
                            "D": "21.20",
                            "E": "46.60",
                            "F": "50.70"
                        },
                        "refrigeration_ranking": {
                            "A": "7.80",
                            "B": "12.60",
                            "C": "19.50",
                            "D": "30.00",
                            "E": "36.90",
                            "F": "45.40"
                        }
                    },
                    "primary_energy_no_renewable": {
                        "global_ranking": {
                            "A": "13.70",
                            "B": "25.90",
                            "C": "43.80",
                            "D": "70.20",
                            "E": "144.60",
                            "F": "157.60"
                        },
                        "global": "E",
                        "heating": "E",
                        "refrigeration": "D",
                        "ACS": "G",
                        "lightning": []
                    },
                    "emissions_Co2": {
                        "global_ranking": {
                            "A": "3.20",
                            "B": "6.10",
                            "C": "10.30",
                            "D": "16.40",
                            "E": "35.20",
                            "F": "38.40"
                        },
                        "global": "E",
                        "heating": "E",
                        "refrigeration": "D",
                        "ACS": "G",
                        "lightning": []
                    }
                }
            }
        ]
    },
    "meta": {
        "success": true,
        "errors": []
    }
}`
                                                                }
                                                            </SyntaxHighlighter>
                                                        </TabPane>
                                                        <TabPane tabId="iconTabs3">
                                                            <h3>Url</h3>
                                                            <p>Búsqueda de certificados por referencia catastral.</p>
                                                            <SyntaxHighlighter
                                                                language={"json"}
                                                                style={dark}
                                                            >
                                                                {
                                                                    `GET api/aragon/energy-certificate/{rc}`
                                                                }
                                                            </SyntaxHighlighter>
                                                            <h3>Request</h3>
                                                            <SyntaxHighlighter
                                                                language={"json"}
                                                                style={dark}
                                                            >
                                                                {
                                                                    `{

}`
                                                                }
                                                            </SyntaxHighlighter>

                                                            <h3>Response</h3>
                                                            <SyntaxHighlighter
                                                                language={"json"}
                                                                style={dark}
                                                            >
                                                                {
                                                                    `{
    "data": {
        "certificate": [
            {
                "num_certificate": "2014HEVV-000000186",
                "emission_date": "13-04-2014",
                "expired_date": "13-04-2024",
                "co_emission": "97,91 kgCO2/m2 año",
                "emission_ranked": "G",
                "consumption_energy": "392,55 kWh/m2 año",
                "consumption_ranked": "F",
                "property_type": "Bloque: Vivienda individual",
                "property_status": "Existente",
                "year": 1989,
                "surface": "46.94",
                "municipality": "JACA",
                "province": "HUESCA",
                "rc": "1761802YN0116S0048TQ/0",
                "address": "CL MEMBRILLERAS 3 Es: 2 Pl: 02 Pt: A",
                "coordinates": "701579,18 , 4716000,08"
            }
        ]
    },
    "meta": {
        "success": true,
        "errors": []
    }
}`
                                                                }
                                                            </SyntaxHighlighter>

                                                            <h3>Url</h3>
                                                            <p>Búsqueda de certificados por filtros.</p>
                                                            <SyntaxHighlighter
                                                                language={"json"}
                                                                style={dark}
                                                            >
                                                                {
                                                                    `POST api/aragon/energy-certificate/search`
                                                                }
                                                            </SyntaxHighlighter>
                                                            <h3>Request</h3>
                                                            <p>
                                                                Filtrar por la letra de consumo:
                                                                <ul>
                                                                    <li><strong>ranking_consumption_letter:</strong> A,
                                                                        B, C, D, E, F
                                                                    </li>
                                                                </ul>
                                                                Filtrar por la letra de emisiones:
                                                                <ul>
                                                                    <li><strong>ranking_emissions_letter:</strong> A, B,
                                                                        C, D, E, F
                                                                    </li>
                                                                </ul>
                                                                Filtrar por la dirección:
                                                                <ul>
                                                                    <li><strong>address:</strong> CL MEMBRILLERAS</li>
                                                                </ul>
                                                                Filtrar por la página:
                                                                <ul>
                                                                    <li><strong>page:</strong> 1</li>
                                                                </ul>
                                                            </p>
                                                            <SyntaxHighlighter
                                                                language={"json"}
                                                                style={dark}
                                                            >
                                                                {
                                                                    `{
    "ranking_consumption_letter":"F",
    "ranking_emissions_letter":"G",
    "address": "CL MEMBRILLERAS",
    "certificate_number":"2014HEVV-000009714",
    "page": 1
}`
                                                                }
                                                            </SyntaxHighlighter>
                                                            <h3>Response</h3>
                                                            <SyntaxHighlighter
                                                                language={"json"}
                                                                style={dark}
                                                            >
                                                                {
                                                                    `{
    "data": {
        "certificate": [
            {
                "num_certificate": "2014HEVV-000000186",
                "emission_date": "13-04-2014",
                "expired_date": "13-04-2024",
                "co_emission": "97,91 kgCO2/m2 año",
                "emission_ranked": "G",
                "consumption_energy": "392,55 kWh/m2 año",
                "consumption_ranked": "F",
                "property_type": "Bloque: Vivienda individual",
                "property_status": "Existente",
                "year": 1989,
                "surface": "46.94",
                "municipality": "JACA",
                "province": "HUESCA",
                "rc": "1761802YN0116S0048TQ/0",
                "address": "CL MEMBRILLERAS 3 Es: 2 Pl: 02 Pt: A",
                "coordinates": "701579,18 , 4716000,08"
            }
        ]
    },
    "meta": {
        "success": true,
        "errors": []
    }
}`
                                                                }
                                                            </SyntaxHighlighter>
                                                        </TabPane>
                                                        <TabPane tabId="iconTabs4">
                                                            <h3>Url</h3>
                                                            <p>Búsqueda de certificados por referencia catastral.</p>
                                                            <SyntaxHighlighter
                                                                language={"json"}
                                                                style={dark}
                                                            >
                                                                {
                                                                    `GET api/asturias/energy-certificate/{rc}`
                                                                }
                                                            </SyntaxHighlighter>
                                                            <h3>Request</h3>
                                                            <SyntaxHighlighter
                                                                language={"json"}
                                                                style={dark}
                                                            >
                                                                {
                                                                    `{

}`
                                                                }
                                                            </SyntaxHighlighter>

                                                            <h3>Response</h3>
                                                            <SyntaxHighlighter
                                                                language={"json"}
                                                                style={dark}
                                                            >
                                                                {
                                                                    `{
    "data": {
        "certificate": [
            {
                "type": "EDIFICIO EXISTENTE",
                "register_mode": "1ª INSCRIPCION",
                "registration_method": "VOLUNTARIA",
                "register_number": 1,
                "register_date": "2013-05-31",
                "expired_data": "2023-05-31",
                "building_type": "LOCAL",
                "year": 1945,
                "address": "PLAZA DE ESPAÑA 1",
                "location": "OVIEDO",
                "municipality": "OVIEDO",
                "postal_code": 33007,
                "rc": "8951003TP6085S0001PQ",
                "building_regulations": "ANTERIOR NBE-CT-79",
                "thermal_building_regulations": "OTROS",
                "ranking_method": "SIMPLIFICADA",
                "software": "CE3X",
                "climatic_zone": "C1",
                "useful_surface": 3163,
                "has_solar_thermal_energy": false,
                "has_solar_photovoltaic_energy": false,
                "has_geothermal_energy": false,
                "has_biomass_energy": false,
                "ranking_emissions_co2": "C",
                "emissions_co2": 57,
                "ranking_emissions": "C",
                "energy_primary_consumption": 228,
                "ranking_energy_primary_consumption": "C",
                "ranking_heating_emissions": "D",
                "ranking_refrigeration_emissions": "C",
                "ranking_ACS_emissions": "G",
                "ranking_lighting_emissions": "C",
                "heating_request": 45,
                "refrigeration_request": 6,
                "ranking_building_general": "C"
            }
        ]
    },
    "meta": {
        "success": true,
        "errors": []
    }
}`
                                                                }
                                                            </SyntaxHighlighter>

                                                            <h3>Url</h3>
                                                            <p>Búsqueda de certificados por filtros.</p>
                                                            <SyntaxHighlighter
                                                                language={"json"}
                                                                style={dark}
                                                            >
                                                                {
                                                                    `POST api/asturias/energy-certificate/search`
                                                                }
                                                            </SyntaxHighlighter>
                                                            <h3>Request</h3>
                                                            <p>
                                                                Filtrar por la letra de consumo:
                                                                <ul>
                                                                    <li><strong>ranking_consumption_letter:</strong> A,
                                                                        B, C, D, E, F
                                                                    </li>
                                                                </ul>
                                                                Filtrar por la letra de emisiones:
                                                                <ul>
                                                                    <li><strong>ranking_emissions_letter:</strong> A, B,
                                                                        C, D, E, F
                                                                    </li>
                                                                </ul>
                                                                Filtrar por la dirección:
                                                                <ul>
                                                                    <li><strong>address:</strong> CL MEMBRILLERAS</li>
                                                                </ul>
                                                                Filtrar por el código postal:
                                                                <ul>
                                                                    <li><strong>postal_code:</strong> 04711</li>
                                                                </ul>
                                                                Filtrar por la página:
                                                                <ul>
                                                                    <li><strong>page:</strong> 1</li>
                                                                </ul>
                                                            </p>
                                                            <SyntaxHighlighter
                                                                language={"json"}
                                                                style={dark}
                                                            >
                                                                {
                                                                    `{
    "ranking_consumption_letter":"C",
    "ranking_emissions_letter":"C",
    "address": "PLAZA DE ESPAÑA",
    "postal_code": "33007",
    "page": 1
}`
                                                                }
                                                            </SyntaxHighlighter>
                                                            <h3>Response</h3>
                                                            <SyntaxHighlighter
                                                                language={"json"}
                                                                style={dark}
                                                            >
                                                                {
                                                                    `{
    "data": {
        "certificate": [
            {
                "type": "EDIFICIO EXISTENTE",
                "register_mode": "1ª INSCRIPCION",
                "registration_method": "VOLUNTARIA",
                "register_number": 1,
                "register_date": "2013-05-31",
                "expired_data": "2023-05-31",
                "building_type": "LOCAL",
                "year": 1945,
                "address": "PLAZA DE ESPAÑA 1",
                "location": "OVIEDO",
                "municipality": "OVIEDO",
                "postal_code": 33007,
                "rc": "8951003TP6085S0001PQ",
                "building_regulations": "ANTERIOR NBE-CT-79",
                "thermal_building_regulations": "OTROS",
                "ranking_method": "SIMPLIFICADA",
                "software": "CE3X",
                "climatic_zone": "C1",
                "useful_surface": 3163,
                "has_solar_thermal_energy": false,
                "has_solar_photovoltaic_energy": false,
                "has_geothermal_energy": false,
                "has_biomass_energy": false,
                "ranking_emissions_co2": "C",
                "emissions_co2": 57,
                "ranking_emissions": "C",
                "energy_primary_consumption": 228,
                "ranking_energy_primary_consumption": "C",
                "ranking_heating_emissions": "D",
                "ranking_refrigeration_emissions": "C",
                "ranking_ACS_emissions": "G",
                "ranking_lighting_emissions": "C",
                "heating_request": 45,
                "refrigeration_request": 6,
                "ranking_building_general": "C"
            }
        ]
    },
    "meta": {
        "success": true,
        "errors": []
    }
}`
                                                                }
                                                            </SyntaxHighlighter>
                                                        </TabPane>
                                                        <TabPane tabId="iconTabs5">
                                                            <h3>Url</h3>
                                                            <p>Búsqueda de certificados por referencia catastral.</p>
                                                            <SyntaxHighlighter
                                                                language={"json"}
                                                                style={dark}
                                                            >
                                                                {
                                                                    `GET api/islas-baleares/energy-certificate/{rc}`
                                                                }
                                                            </SyntaxHighlighter>
                                                            <h3>Request</h3>
                                                            <SyntaxHighlighter
                                                                language={"json"}
                                                                style={dark}
                                                            >
                                                                {
                                                                    `{

}`
                                                                }
                                                            </SyntaxHighlighter>

                                                            <h3>Response</h3>
                                                            <SyntaxHighlighter
                                                                language={"json"}
                                                                style={dark}
                                                            >
                                                                {
                                                                    `{
    "data": {
        "certificate": [
            {
                "num_file": "2018/1",
                "processing_type": "Telemàtica",
                "num_register": "1",
                "certificate_type": "Certificat edifici existent",
                "date": "20/09/2018",
                "expired_date": "20/09/2018",
                "address": "C/ La Pinta, 51",
                "island": "MALLORCA",
                "municipality": "MANACOR",
                "postal_code": "07860",
                "locale": "Porto Cristo",
                "rc": "9275012ED2797N",
                "year": "1955",
                "surface": "162.0",
                "building_type": "Habitatge edifici complet",
                "regulation": "CE3X",
                "consumption": "234.8",
                "emission_co2": "58.4",
                "ranking": "G"
            }
        ]
    },
    "meta": {
        "success": true,
        "errors": []
    }
}`
                                                                }
                                                            </SyntaxHighlighter>

                                                            <h3>Url</h3>
                                                            <p>Búsqueda de certificados por filtros.</p>
                                                            <SyntaxHighlighter
                                                                language={"json"}
                                                                style={dark}
                                                            >
                                                                {
                                                                    `POST api/islas-baleares/energy-certificate/search`
                                                                }
                                                            </SyntaxHighlighter>
                                                            <h3>Request</h3>
                                                            <p>
                                                                Filtrar por la letra clasificación:
                                                                <ul>
                                                                    <li><strong>letter:</strong> A, B, C, D, E, F</li>
                                                                </ul>
                                                                Filtrar por la dirección:
                                                                <ul>
                                                                    <li><strong>address:</strong> CL MEMBRILLERAS</li>
                                                                </ul>
                                                                Filtrar número de fichero:
                                                                <ul>
                                                                    <li><strong>address:</strong> 2018/1</li>
                                                                </ul>
                                                                Filtrar por el código postal:
                                                                <ul>
                                                                    <li><strong>postal_code:</strong> 04711</li>
                                                                </ul>
                                                                Filtrar por la página:
                                                                <ul>
                                                                    <li><strong>page:</strong> 1</li>
                                                                </ul>
                                                            </p>
                                                            <SyntaxHighlighter
                                                                language={"json"}
                                                                style={dark}
                                                            >
                                                                {
                                                                    `{
    "letter":"G",
    "address": "C/ La Pinta",
    "num_file": "2018/1",
    "postal_code": "07860",
    "page": 1
}`
                                                                }
                                                            </SyntaxHighlighter>
                                                            <h3>Response</h3>
                                                            <SyntaxHighlighter
                                                                language={"json"}
                                                                style={dark}
                                                            >
                                                                {
                                                                    `{
    "data": {
        "certificate": [
            {
                "num_file": "2018/1",
                "processing_type": "Telemàtica",
                "num_register": "1",
                "certificate_type": "Certificat edifici existent",
                "date": "20/09/2018",
                "expired_date": "20/09/2018",
                "address": "C/ La Pinta, 51",
                "island": "MALLORCA",
                "municipality": "MANACOR",
                "postal_code": "07860",
                "locale": "Porto Cristo",
                "rc": "9275012ED2797N",
                "year": "1955",
                "surface": "162.0",
                "building_type": "Habitatge edifici complet",
                "regulation": "CE3X",
                "consumption": "234.8",
                "emission_co2": "58.4",
                "ranking": "G"
            }
        ]
    },
    "meta": {
        "success": true,
        "errors": []
    }
}`
                                                                }
                                                            </SyntaxHighlighter>
                                                        </TabPane>
                                                        <TabPane tabId="iconTabs6">
                                                            <h3>Url</h3>
                                                            <p>Búsqueda de certificados por referencia catastral.</p>
                                                            <SyntaxHighlighter
                                                                language={"json"}
                                                                style={dark}
                                                            >
                                                                {
                                                                    `GET api/canarias/energy-certificate/{rc}`
                                                                }
                                                            </SyntaxHighlighter>
                                                            <h3>Request</h3>
                                                            <SyntaxHighlighter
                                                                language={"json"}
                                                                style={dark}
                                                            >
                                                                {
                                                                    `{

}`
                                                                }
                                                            </SyntaxHighlighter>

                                                            <h3>Response</h3>
                                                            <SyntaxHighlighter
                                                                language={"json"}
                                                                style={dark}
                                                            >
                                                                {
                                                                    `{
    "data": {
        "certificate": [
            {
                "date": "06/09/2023",
                "num_register": "142351",
                "register_date": "16/01/2017",
                "expired_date": "14/01/2027",
                "address": "CALLE EL RISCO DE JIMÉNEZ 29, VIVIENDA 8",
                "municipality": "TEROR",
                "island": "Gran Canaria",
                "province": "PALMAS (LAS)",
                "rc": "9759809DS4095N0008AA",
                "climatic_zone": "alpha3",
                "emission": "49,41",
                "ranking_emission": "G",
                "consumption": "186,45",
                "ranking_consumption": "G",
                "use_renewables": false,
                "use_thermal_solar": false,
                "use_solar_photovoltaic": false,
                "use_geothermal": false,
                "use_biomass_system": false,
                "heating_emissions": "7,09",
                "ranking_heating_emissions": "",
                "heating_consumption": "26,99",
                "ranking_heating_consumption": "",
                "refrigeration_emissions": "22,58",
                "ranking_refrigeration_emissions": "G",
                "refrigeration_consumption": "85,07",
                "ranking_refrigeration_consumption": "G",
                "ACS_emissions": "19,74",
                "ranking_ACS_emissions": "G",
                "ACS_consumption": "74,39",
                "ranking_ACS_consumption": "G",
                "lightning_emissions": "0",
                "ranking_lightning_emissions": "",
                "lightning_consumption": "0",
                "ranking_lightning_consumption": "",
                "installation": "ACS",
                "type_installation": "Efecto Joule",
                "installation_vector_energetic": "ElectricidadCanarias"
            }
        ]
    },
    "meta": {
        "success": true,
        "errors": []
    }
}`
                                                                }
                                                            </SyntaxHighlighter>

                                                            <h3>Url</h3>
                                                            <p>Búsqueda de certificados por filtros.</p>
                                                            <SyntaxHighlighter
                                                                language={"json"}
                                                                style={dark}
                                                            >
                                                                {
                                                                    `POST api/canarias/energy-certificate/search`
                                                                }
                                                            </SyntaxHighlighter>
                                                            <h3>Request</h3>
                                                            <p>
                                                                Filtrar por la letra de consumo:
                                                                <ul>
                                                                    <li><strong>ranking_consumption_letter:</strong> A,
                                                                        B, C, D, E, F
                                                                    </li>
                                                                </ul>
                                                                Filtrar por la letra de emisiones:
                                                                <ul>
                                                                    <li><strong>ranking_emissions_letter:</strong> A, B,
                                                                        C, D, E, F
                                                                    </li>
                                                                </ul>
                                                                Filtrar por la dirección:
                                                                <ul>
                                                                    <li><strong>address:</strong> CL MEMBRILLERAS</li>
                                                                </ul>
                                                                Filtrar por el número de certificado:
                                                                <ul>
                                                                    <li><strong>certificate_number:</strong> 142351</li>
                                                                </ul>
                                                                Filtrar por la página:
                                                                <ul>
                                                                    <li><strong>page:</strong> 1</li>
                                                                </ul>
                                                            </p>
                                                            <SyntaxHighlighter
                                                                language={"json"}
                                                                style={dark}
                                                            >
                                                                {
                                                                    `{
    "ranking_consumption_letter":"B",
    "ranking_emissions_letter":"B",
    "address": "CALLE EL RISCO DE JIMÉNEZ",
    "certificate_number":"142351",
    "page": 1
}`
                                                                }
                                                            </SyntaxHighlighter>
                                                            <h3>Response</h3>
                                                            <SyntaxHighlighter
                                                                language={"json"}
                                                                style={dark}
                                                            >
                                                                {
                                                                    `{
    "data": {
        "certificate": [
            {
                "date": "06/09/2023",
                "num_register": "142351",
                "register_date": "16/01/2017",
                "expired_date": "14/01/2027",
                "address": "CALLE EL RISCO DE JIMÉNEZ 29, VIVIENDA 8",
                "municipality": "TEROR",
                "island": "Gran Canaria",
                "province": "PALMAS (LAS)",
                "rc": "9759809DS4095N0008AA",
                "climatic_zone": "alpha3",
                "emission": "49,41",
                "ranking_emission": "G",
                "consumption": "186,45",
                "ranking_consumption": "G",
                "use_renewables": false,
                "use_thermal_solar": false,
                "use_solar_photovoltaic": false,
                "use_geothermal": false,
                "use_biomass_system": false,
                "heating_emissions": "7,09",
                "ranking_heating_emissions": "",
                "heating_consumption": "26,99",
                "ranking_heating_consumption": "",
                "refrigeration_emissions": "22,58",
                "ranking_refrigeration_emissions": "G",
                "refrigeration_consumption": "85,07",
                "ranking_refrigeration_consumption": "G",
                "ACS_emissions": "19,74",
                "ranking_ACS_emissions": "G",
                "ACS_consumption": "74,39",
                "ranking_ACS_consumption": "G",
                "lightning_emissions": "0",
                "ranking_lightning_emissions": "",
                "lightning_consumption": "0",
                "ranking_lightning_consumption": "",
                "installation": "ACS",
                "type_installation": "Efecto Joule",
                "installation_vector_energetic": "ElectricidadCanarias"
            }
        ]
    },
    "meta": {
        "success": true,
        "errors": []
    }
}`
                                                                }
                                                            </SyntaxHighlighter>
                                                        </TabPane>
                                                        <TabPane tabId="iconTabs7">
                                                            <h3>Url</h3>
                                                            <p>Búsqueda de certificados por referencia catastral.</p>
                                                            <SyntaxHighlighter
                                                                language={"json"}
                                                                style={dark}
                                                            >
                                                                {
                                                                    `GET api/cantabria/energy-certificate/{rc}`
                                                                }
                                                            </SyntaxHighlighter>
                                                            <h3>Request</h3>
                                                            <SyntaxHighlighter
                                                                language={"json"}
                                                                style={dark}
                                                            >
                                                                {
                                                                    `{

}`
                                                                }
                                                            </SyntaxHighlighter>

                                                            <h3>Response</h3>
                                                            <SyntaxHighlighter
                                                                language={"json"}
                                                                style={dark}
                                                            >
                                                                {
                                                                    `{
    "data": {
        "certificate": [
            {
                "certificate_number": "1/2013",
                "certificate_status": "2 - Caducado",
                "address": "AV - AVENIDA ALBERT EINSTEIN, 2   -39011 SANTANDER",
                "rc": "9718708VP2191H0001UL",
                "climatic_zone": "5 - Zona C1",
                "heating_global_emissions": "C - Calificación C",
                "year_emissions": "N/D",
                "year_m2_emissions": "42,8",
                "heating_consumption": "D - Calificación D",
                "year_consumption": "N/D",
                "year_m2_consumption": "187,36",
                "register_data": 20130531,
                "register_data_2": "31/05/2013",
                "expired_data": 20230531,
                "expired_data_2": "31/05/2023",
                "ranking_heating": "N/D",
                "ranking_refrigeration": "N/D",
                "ranking_lighting": "N/D",
                "ranking_ACS": "N/D",
                "building_standard": "CTE (>2006)",
                "energy_standard": "RD 1027/2007 RITE - Reg. Instalaciones térmicas edificios",
                "building_use": "Oficina"
            }
        ]
    },
    "meta": {
        "success": true,
        "errors": []
    }
}`
                                                                }
                                                            </SyntaxHighlighter>

                                                            <h3>Url</h3>
                                                            <p>Búsqueda de certificados por filtros.</p>
                                                            <SyntaxHighlighter
                                                                language={"json"}
                                                                style={dark}
                                                            >
                                                                {
                                                                    `POST api/cantabria/energy-certificate/search`
                                                                }
                                                            </SyntaxHighlighter>
                                                            <h3>Request</h3>
                                                            <p>
                                                                Filtrar por la letra de consumo:
                                                                <ul>
                                                                    <li><strong>ranking_consumption_letter:</strong> A,
                                                                        B, C, D, E, F
                                                                    </li>
                                                                </ul>
                                                                Filtrar por la letra de emisiones:
                                                                <ul>
                                                                    <li><strong>ranking_emissions_letter:</strong> A, B,
                                                                        C, D, E, F
                                                                    </li>
                                                                </ul>
                                                                Filtrar por el número de certificado:
                                                                <ul>
                                                                    <li><strong>certificate_number:</strong> 1/2013</li>
                                                                </ul>
                                                                Filtrar por la dirección:
                                                                <ul>
                                                                    <li><strong>address:</strong> AV - AVENIDA ALBERT
                                                                    </li>
                                                                </ul>
                                                                Filtrar por la página:
                                                                <ul>
                                                                    <li><strong>page:</strong> 1</li>
                                                                </ul>
                                                            </p>
                                                            <SyntaxHighlighter
                                                                language={"json"}
                                                                style={dark}
                                                            >
                                                                {
                                                                    `{
    "ranking_consumption_letter":"D",
    "ranking_emissions_letter":"C",
    "certificate_number": "1/2013",
    "address": "AV - AVENIDA ALBERT EINSTEIN",
    "page": 1
}`
                                                                }
                                                            </SyntaxHighlighter>
                                                            <h3>Response</h3>
                                                            <SyntaxHighlighter
                                                                language={"json"}
                                                                style={dark}
                                                            >
                                                                {
                                                                    `{
    "data": {
        "certificate": [
            {
                "certificate_number": "1/2013",
                "certificate_status": "2 - Caducado",
                "address": "AV - AVENIDA ALBERT EINSTEIN, 2   -39011 SANTANDER",
                "rc": "9718708VP2191H0001UL",
                "climatic_zone": "5 - Zona C1",
                "heating_global_emissions": "C - Calificación C",
                "year_emissions": "N/D",
                "year_m2_emissions": "42,8",
                "heating_consumption": "D - Calificación D",
                "year_consumption": "N/D",
                "year_m2_consumption": "187,36",
                "register_data": 20130531,
                "register_data_2": "31/05/2013",
                "expired_data": 20230531,
                "expired_data_2": "31/05/2023",
                "ranking_heating": "N/D",
                "ranking_refrigeration": "N/D",
                "ranking_lighting": "N/D",
                "ranking_ACS": "N/D",
                "building_standard": "CTE (>2006)",
                "energy_standard": "RD 1027/2007 RITE - Reg. Instalaciones térmicas edificios",
                "building_use": "Oficina"
            }
        ]
    },
    "meta": {
        "success": true,
        "errors": []
    }
}`
                                                                }
                                                            </SyntaxHighlighter>
                                                        </TabPane>
                                                        <TabPane tabId="iconTabs8">
                                                            <h3>Url</h3>
                                                            <p>Búsqueda de certificados por referencia catastral.</p>
                                                            <SyntaxHighlighter
                                                                language={"json"}
                                                                style={dark}
                                                            >
                                                                {
                                                                    `GET api/castilla-la-mancha/energy-certificate/{rc}`
                                                                }
                                                            </SyntaxHighlighter>
                                                            <h3>Request</h3>
                                                            <SyntaxHighlighter
                                                                language={"json"}
                                                                style={dark}
                                                            >
                                                                {
                                                                    `{

}`
                                                                }
                                                            </SyntaxHighlighter>

                                                            <h3>Response</h3>
                                                            <SyntaxHighlighter
                                                                language={"json"}
                                                                style={dark}
                                                            >
                                                                {
                                                                    `{
    "data": {
        "certificate": [
            {
                "register_data": {
                    "register_type": "Existente",
                    "state_code": "RCE-1E/203271"
                },
                "building_identification": {
                    "address": "C/. TADEO PEREDA, Nº 23 2º PTA M",
                    "municipality": "Almansa",
                    "postal_code": "02640",
                    "province": "Albacete",
                    "climatic_zone": "D3",
                    "rc": "5147404XJ6054N0011HP",
                    "year": "2003",
                    "current_regulation": "NBE-CT-79",
                    "building_type": "BloqueDeViviendaCompleto",
                    "procedure": "CEXv2.3"
                },
                "certificate_data": {
                    "date": "03/02/2023"
                },
                "general_geometry_data": {
                    "surface": "88.27",
                    "compactness": "2.95",
                    "percentage_heated_surface": "100",
                    "percentage_refrigeration_surface": "100",
                    "percentage_glazed_area": {
                        "N": "47",
                        "NE": "0",
                        "E": "0",
                        "SE": "0",
                        "S": "21",
                        "SO": "0",
                        "O": "17",
                        "NO": "0"
                    },
                    "daily_demand": "112.00"
                },
                "thermal_installations": {
                    "heating_generators": [
                        {
                            "name": "Calefacción y ACS",
                            "type": "Caldera Condensación",
                            "nominal_power": "24.00",
                            "seasonal_performance": "0.83",
                            "energy_vector": "GasNatural",
                            "method_production": "Estimado"
                        }
                    ],
                    "refrigeration_generators": [],
                    "acs_installations": [
                        {
                            "name": "Calefacción y ACS",
                            "type": "Caldera Condensación",
                            "nominal_power": "24.00",
                            "seasonal_performance": "0.83",
                            "energy_vector": "GasNatural",
                            "method_production": "Estimado"
                        }
                    ]
                },
                "request": [
                    {
                        "global": "149.32",
                        "heating": "116.47",
                        "refrigeration": "7.82",
                        "acs": "25.03"
                    }
                ],
                "consumption": {
                    "vector_final_energy": {
                        "natural_gas": {
                            "global": "170.90",
                            "heating": "140.66",
                            "refrigeration": "0.00",
                            "acs": "30.23",
                            "lighting": "0.00"
                        },
                        "diesel_c": {
                            "global": "0.00",
                            "heating": "0.00",
                            "refrigeration": "0.00",
                            "acs": "0.00",
                            "lighting": "0.00"
                        },
                        "glp": {
                            "global": "0.00",
                            "heating": "0.00",
                            "refrigeration": "0.00",
                            "acs": "0.00",
                            "lighting": "0.00"
                        },
                        "carbon": {
                            "global": "0.00",
                            "heating": "0.00",
                            "refrigeration": "0.00",
                            "acs": "0.00",
                            "lighting": "0.00"
                        },
                        "biomass_other": {
                            "global": "0.00",
                            "heating": "0.00",
                            "refrigeration": "0.00",
                            "acs": "0.00",
                            "lighting": "0.00"
                        },
                        "biomass_pellet": {
                            "global": "0.00",
                            "heating": "0.00",
                            "refrigeration": "0.00",
                            "acs": "0.00",
                            "lighting": "0.00"
                        },
                        "peninsular_ligthing": {
                            "global": "3.91",
                            "heating": "0.00",
                            "refrigeration": "3.91",
                            "acs": "0.00",
                            "lighting": "0.00"
                        }
                    },
                    "primary_energy_no_renewable": {
                        "global": "211.01",
                        "heating": "167.39",
                        "refrigeration": "7.64",
                        "acs": "35.98",
                        "lighting": "0.00"
                    }
                },
                "emissions_co2": {
                    "global": "44.36",
                    "heating": "35.45",
                    "refrigeration": "1.29",
                    "acs": "7.62",
                    "lighting": "0.00",
                    "lighting_consumption": "1.29",
                    "other_consumption": "43.07",
                    "total_lighting_consumption": "1.29",
                    "total_other_consumption": "43.07"
                },
                "rankings": {
                    "request": {
                        "ranking_heating": {
                            "A": "11.70",
                            "B": "27.00",
                            "C": "48.70",
                            "D": "81.60",
                            "E": "144.10",
                            "F": "157.10"
                        },
                        "ranking_refrigeration": {
                            "A": "5.50",
                            "B": "8.90",
                            "C": "13.90",
                            "D": "21.30",
                            "E": "26.30",
                            "F": "32.40"
                        },
                        "heating": "E",
                        "refrigeration": "B"
                    },
                    "energy_primary_no_renewable": {
                        "ranking_global": {
                            "A": "37.10",
                            "B": "60.10",
                            "C": "93.20",
                            "D": "143.30",
                            "E": "298.10",
                            "F": "336.80"
                        },
                        "global": "E",
                        "heating": "E",
                        "refrigeration": "B",
                        "acs": "G"
                    },
                    "emissions_co2": {
                        "ranking_global": {
                            "A": "8.40",
                            "B": "13.60",
                            "C": "21.10",
                            "D": "32.40",
                            "E": "66.30",
                            "F": "79.60"
                        },
                        "global": "E",
                        "heating": "E",
                        "refrigeration": "A",
                        "acs": "G"
                    }
                }
            }
        ]
    },
    "meta": {
        "success": true,
        "errors": []
    }
}`
                                                                }
                                                            </SyntaxHighlighter>

                                                            <h3>Url</h3>
                                                            <p>Búsqueda de certificados por filtros.</p>
                                                            <SyntaxHighlighter
                                                                language={"json"}
                                                                style={dark}
                                                            >
                                                                {
                                                                    `POST api/castilla-la-mancha/energy-certificate/search`
                                                                }
                                                            </SyntaxHighlighter>
                                                            <h3>Request</h3>
                                                            <p>
                                                                Filtrar por la letra de consumo:
                                                                <ul>
                                                                    <li><strong>ranking_consumption_letter:</strong> A,
                                                                        B, C, D, E, F
                                                                    </li>
                                                                </ul>
                                                                Filtrar por la letra de emisiones:
                                                                <ul>
                                                                    <li><strong>ranking_emissions_letter:</strong> A, B,
                                                                        C, D, E, F
                                                                    </li>
                                                                </ul>
                                                                Filtrar por el número de certificado:
                                                                <ul>
                                                                    <li><strong>code:</strong> RCE-1E/203271</li>
                                                                </ul>
                                                                Filtrar por la dirección:
                                                                <ul>
                                                                    <li><strong>address:</strong> C/. TADEO PEREDA</li>
                                                                </ul>
                                                                Filtrar por código postal:
                                                                <ul>
                                                                    <li><strong>postal_code:</strong> 02640</li>
                                                                </ul>
                                                                Filtrar por la página:
                                                                <ul>
                                                                    <li><strong>page:</strong> 1</li>
                                                                </ul>
                                                            </p>
                                                            <SyntaxHighlighter
                                                                language={"json"}
                                                                style={dark}
                                                            >
                                                                {
                                                                    `{
    "ranking_consumption_letter":"D",
    "ranking_emissions_letter":"C",
    "code": "RCE-1E/203271",
    "address": "C/. TADEO PEREDA",
    "postal_code": "02640",
    "page": 1
}`
                                                                }
                                                            </SyntaxHighlighter>
                                                            <h3>Response</h3>
                                                            <SyntaxHighlighter
                                                                language={"json"}
                                                                style={dark}
                                                            >
                                                                {
                                                                    `{
    "data": {
        "certificate": [
            {
                "register_data": {
                    "register_type": "Existente",
                    "state_code": "RCE-1E/203271"
                },
                "building_identification": {
                    "address": "C/. TADEO PEREDA, Nº 23 2º PTA M",
                    "municipality": "Almansa",
                    "postal_code": "02640",
                    "province": "Albacete",
                    "climatic_zone": "D3",
                    "rc": "5147404XJ6054N0011HP",
                    "year": "2003",
                    "current_regulation": "NBE-CT-79",
                    "building_type": "BloqueDeViviendaCompleto",
                    "procedure": "CEXv2.3"
                },
                "certificate_data": {
                    "date": "03/02/2023"
                },
                "general_geometry_data": {
                    "surface": "88.27",
                    "compactness": "2.95",
                    "percentage_heated_surface": "100",
                    "percentage_refrigeration_surface": "100",
                    "percentage_glazed_area": {
                        "N": "47",
                        "NE": "0",
                        "E": "0",
                        "SE": "0",
                        "S": "21",
                        "SO": "0",
                        "O": "17",
                        "NO": "0"
                    },
                    "daily_demand": "112.00"
                },
                "thermal_installations": {
                    "heating_generators": [
                        {
                            "name": "Calefacción y ACS",
                            "type": "Caldera Condensación",
                            "nominal_power": "24.00",
                            "seasonal_performance": "0.83",
                            "energy_vector": "GasNatural",
                            "method_production": "Estimado"
                        }
                    ],
                    "refrigeration_generators": [],
                    "acs_installations": [
                        {
                            "name": "Calefacción y ACS",
                            "type": "Caldera Condensación",
                            "nominal_power": "24.00",
                            "seasonal_performance": "0.83",
                            "energy_vector": "GasNatural",
                            "method_production": "Estimado"
                        }
                    ]
                },
                "request": [
                    {
                        "global": "149.32",
                        "heating": "116.47",
                        "refrigeration": "7.82",
                        "acs": "25.03"
                    }
                ],
                "consumption": {
                    "vector_final_energy": {
                        "natural_gas": {
                            "global": "170.90",
                            "heating": "140.66",
                            "refrigeration": "0.00",
                            "acs": "30.23",
                            "lighting": "0.00"
                        },
                        "diesel_c": {
                            "global": "0.00",
                            "heating": "0.00",
                            "refrigeration": "0.00",
                            "acs": "0.00",
                            "lighting": "0.00"
                        },
                        "glp": {
                            "global": "0.00",
                            "heating": "0.00",
                            "refrigeration": "0.00",
                            "acs": "0.00",
                            "lighting": "0.00"
                        },
                        "carbon": {
                            "global": "0.00",
                            "heating": "0.00",
                            "refrigeration": "0.00",
                            "acs": "0.00",
                            "lighting": "0.00"
                        },
                        "biomass_other": {
                            "global": "0.00",
                            "heating": "0.00",
                            "refrigeration": "0.00",
                            "acs": "0.00",
                            "lighting": "0.00"
                        },
                        "biomass_pellet": {
                            "global": "0.00",
                            "heating": "0.00",
                            "refrigeration": "0.00",
                            "acs": "0.00",
                            "lighting": "0.00"
                        },
                        "peninsular_ligthing": {
                            "global": "3.91",
                            "heating": "0.00",
                            "refrigeration": "3.91",
                            "acs": "0.00",
                            "lighting": "0.00"
                        }
                    },
                    "primary_energy_no_renewable": {
                        "global": "211.01",
                        "heating": "167.39",
                        "refrigeration": "7.64",
                        "acs": "35.98",
                        "lighting": "0.00"
                    }
                },
                "emissions_co2": {
                    "global": "44.36",
                    "heating": "35.45",
                    "refrigeration": "1.29",
                    "acs": "7.62",
                    "lighting": "0.00",
                    "lighting_consumption": "1.29",
                    "other_consumption": "43.07",
                    "total_lighting_consumption": "1.29",
                    "total_other_consumption": "43.07"
                },
                "rankings": {
                    "request": {
                        "ranking_heating": {
                            "A": "11.70",
                            "B": "27.00",
                            "C": "48.70",
                            "D": "81.60",
                            "E": "144.10",
                            "F": "157.10"
                        },
                        "ranking_refrigeration": {
                            "A": "5.50",
                            "B": "8.90",
                            "C": "13.90",
                            "D": "21.30",
                            "E": "26.30",
                            "F": "32.40"
                        },
                        "heating": "E",
                        "refrigeration": "B"
                    },
                    "energy_primary_no_renewable": {
                        "ranking_global": {
                            "A": "37.10",
                            "B": "60.10",
                            "C": "93.20",
                            "D": "143.30",
                            "E": "298.10",
                            "F": "336.80"
                        },
                        "global": "E",
                        "heating": "E",
                        "refrigeration": "B",
                        "acs": "G"
                    },
                    "emissions_co2": {
                        "ranking_global": {
                            "A": "8.40",
                            "B": "13.60",
                            "C": "21.10",
                            "D": "32.40",
                            "E": "66.30",
                            "F": "79.60"
                        },
                        "global": "E",
                        "heating": "E",
                        "refrigeration": "A",
                        "acs": "G"
                    }
                }
            }
        ]
    },
    "meta": {
        "success": true,
        "errors": []
    }
}`
                                                                }
                                                            </SyntaxHighlighter>
                                                        </TabPane>
                                                        <TabPane tabId="iconTabs9">
                                                            <h3>Url</h3>
                                                            <p>Búsqueda de certificados por referencia catastral.</p>
                                                            <SyntaxHighlighter
                                                                language={"json"}
                                                                style={dark}
                                                            >
                                                                {
                                                                    `GET api/castilla-leon/energy-certificate/{rc}`
                                                                }
                                                            </SyntaxHighlighter>
                                                            <h3>Request</h3>
                                                            <SyntaxHighlighter
                                                                language={"json"}
                                                                style={dark}
                                                            >
                                                                {
                                                                    `{

}`
                                                                }
                                                            </SyntaxHighlighter>

                                                            <h3>Response</h3>
                                                            <SyntaxHighlighter
                                                                language={"json"}
                                                                style={dark}
                                                            >
                                                                {
                                                                    `{
    "data": {
        "certificate": [
            {
                "rc": "4892018VM4849S0023TT",
                "emission_date": "2023-10-06",
                "finished_date": "05-10-2033",
                "consumption_rate_primary": 318,
                "emission_co2_rate": 67,
                "latitude": 42.35090964011772,
                "longitude": -3.671974071263859,
                "count": 7,
                "emission_rating": "E",
                "consumption_rating": "F",
                "energy_primary_rating": "E",
                "num_registration": "090590001VI15296T1",
                "address": "PLAZA SAN BRUNO  4 PL 5 PT B, CP:09007, BURGOS",
                "municipality": "BURGOS",
                "province": "BURGOS",
                "building_use": "VIVIENDA INDIVIDUAL EN BLOQUE",
                "refrigeration_consumption": 0.26,
                "dataset_id": "certificados-de-eficiencia-energetica",
                "record_id": "29ec641d5ff3d754f903e2a4a1383825250fb731"
            }
        ]
    },
    "meta": {
        "success": true,
        "errors": []
    }
}`
                                                                }
                                                            </SyntaxHighlighter>

                                                            <h3>Url</h3>
                                                            <p>Búsqueda de certificados por filtros.</p>
                                                            <SyntaxHighlighter
                                                                language={"json"}
                                                                style={dark}
                                                            >
                                                                {
                                                                    `POST api/castilla-leon/energy-certificate/search`
                                                                }
                                                            </SyntaxHighlighter>
                                                            <h3>Request</h3>
                                                            <p>
                                                                Filtrar por la letra de consumo:
                                                                <ul>
                                                                    <li><strong>ranking_consumption_letter:</strong> A,
                                                                        B, C, D, E, F
                                                                    </li>
                                                                </ul>
                                                                Filtrar por la letra de emisiones:
                                                                <ul>
                                                                    <li><strong>ranking_emissions_letter:</strong> A, B,
                                                                        C, D, E, F
                                                                    </li>
                                                                </ul>
                                                                Filtrar por el número de certificado:
                                                                <ul>
                                                                    <li>
                                                                        <strong>num_registration:</strong> 090590001VI15296T1
                                                                    </li>
                                                                </ul>
                                                                Filtrar por la dirección:
                                                                <ul>
                                                                    <li><strong>address:</strong> PLAZA SAN BRUNO</li>
                                                                </ul>
                                                                Filtrar por la página:
                                                                <ul>
                                                                    <li><strong>page:</strong> 1</li>
                                                                </ul>
                                                            </p>
                                                            <SyntaxHighlighter
                                                                language={"json"}
                                                                style={dark}
                                                            >
                                                                {
                                                                    `{
    "ranking_consumption_letter":"D",
    "ranking_emissions_letter":"C",
    "num_registration": "090590001VI15296T1",
    "address": "PLAZA SAN BRUNO",
    "page": 1
}`
                                                                }
                                                            </SyntaxHighlighter>
                                                            <h3>Response</h3>
                                                            <SyntaxHighlighter
                                                                language={"json"}
                                                                style={dark}
                                                            >
                                                                {
                                                                    `{
    "data": {
        "certificate": [
            {
                "rc": "4892018VM4849S0023TT",
                "emission_date": "2023-10-06",
                "finished_date": "05-10-2033",
                "consumption_rate_primary": 318,
                "emission_co2_rate": 67,
                "latitude": 42.35090964011772,
                "longitude": -3.671974071263859,
                "count": 7,
                "emission_rating": "E",
                "consumption_rating": "F",
                "energy_primary_rating": "E",
                "num_registration": "090590001VI15296T1",
                "address": "PLAZA SAN BRUNO  4 PL 5 PT B, CP:09007, BURGOS",
                "municipality": "BURGOS",
                "province": "BURGOS",
                "building_use": "VIVIENDA INDIVIDUAL EN BLOQUE",
                "refrigeration_consumption": 0.26,
                "dataset_id": "certificados-de-eficiencia-energetica",
                "record_id": "29ec641d5ff3d754f903e2a4a1383825250fb731"
            }
        ]
    },
    "meta": {
        "success": true,
        "errors": []
    }
}`
                                                                }
                                                            </SyntaxHighlighter>
                                                        </TabPane>
                                                        <TabPane tabId="iconTabs10">
                                                            <h3>Url</h3>
                                                            <p>Búsqueda de certificados por referencia catastral.</p>
                                                            <SyntaxHighlighter
                                                                language={"json"}
                                                                style={dark}
                                                            >
                                                                {
                                                                    `GET api/catalonia/energy-certificate/{rc}`
                                                                }
                                                            </SyntaxHighlighter>
                                                            <h3>Request</h3>
                                                            <SyntaxHighlighter
                                                                language={"json"}
                                                                style={dark}
                                                            >
                                                                {
                                                                    `{

}`
                                                                }
                                                            </SyntaxHighlighter>

                                                            <h3>Response</h3>
                                                            <SyntaxHighlighter
                                                                language={"json"}
                                                                style={dark}
                                                            >
                                                                {
                                                                    `{
    "data": {
        "certificate": [
            {
                "num_certificate": "44LSBJBG7",
                "address": "Ronda Catalunya",
                "number": "52",
                "step": "",
                "floor": "",
                "door": "",
                "postal_code": 8210,
                "municipality": "Barberà del Vallès",
                "region": "Vallès Occidental",
                "province": "Barcelona",
                "code_municipality": 8252,
                "code_region": 40,
                "code_province": 8,
                "rc": "7258013DF2975N0000SD",
                "climatic_zone": "C2",
                "surface": "120.6",
                "year": "",
                "building_type": "Habitatge unifamiliar",
                "ranking_consumption": "B",
                "consumption_energy_primary": "54.15",
                "ranking_emissions_co2": "B",
                "emissions_co2": "11.2",
                "final_consumption": "10.91",
                "annual_energy_cost": "121.42",
                "electric_car": false,
                "thermic_solar": false,
                "solar_photovoltaic": false,
                "biomass_system": false,
                "district_network": false,
                "geotermic_energy": false,
                "technical_installation_report_building": "",
                "certification_tool": "CERMA",
                "isolation_value": "0.25",
                "window_value": "1.57",
                "certification_reason": "Nova construcció",
                "CTE_isolation_value": "0.49",
                "CTE_window_value": "2.1",
                "utm_x": "",
                "utm_y": "",
                "building_regulation": "CTE 2013",
                "type_procedure": "Edificis acabats",
                "tertiary_type": "",
                "ranking_heating_emissions": "B",
                "heating_emissions": "6.35",
                "ranking_refrigeration_emissions": "B",
                "refrigeration_emissions": "1.17",
                "ranking_ACS_emissions": "B",
                "ACS_emissions": "3.68",
                "ranking_lightning": "",
                "lightning_emissions": "",
                "ranking_heating_consumption": "B",
                "heating_consumption": "29.97",
                "ranking_refrigeration_consumption": "C",
                "refrigeration_consumption": "6.91",
                "ranking_ACS_consumption": "C",
                "ACS_consumption": "17.27",
                "ranking_lightning_consumption": "",
                "lightning_consumption": "",
                "ranking_request_heating": "B",
                "request_heating": "27.17",
                "ranking_request_refrigeration": "C",
                "request_refrigeration": "7.07",
                "refrigeration_residential_use": "0.63",
                "latitude": "",
                "longitude": "",
                "geo_reference": "",
                "energy_rehabilitation": false,
                "rehabilitation_actions": "",
                "date": "28-05-2018"
            }
        ]
    },
    "meta": {
        "success": true,
        "errors": []
    }
}`
                                                                }
                                                            </SyntaxHighlighter>

                                                            <h3>Url</h3>
                                                            <p>Búsqueda de certificados por filtros.</p>
                                                            <SyntaxHighlighter
                                                                language={"json"}
                                                                style={dark}
                                                            >
                                                                {
                                                                    `POST api/catalonia/energy-certificate/search`
                                                                }
                                                            </SyntaxHighlighter>
                                                            <h3>Request</h3>
                                                            <p>
                                                                Filtrar por la letra de consumo:
                                                                <ul>
                                                                    <li><strong>ranking_consumption_letter:</strong> A,
                                                                        B, C, D, E, F
                                                                    </li>
                                                                </ul>
                                                                Filtrar por la letra de emisiones:
                                                                <ul>
                                                                    <li><strong>ranking_emissions_letter:</strong> A, B,
                                                                        C, D, E, F
                                                                    </li>
                                                                </ul>
                                                                Filtrar por el número de certificado:
                                                                <ul>
                                                                    <li><strong>certificate_number:</strong> 44LSBJBG7
                                                                    </li>
                                                                </ul>
                                                                Filtrar por la dirección:
                                                                <ul>
                                                                    <li><strong>address:</strong> Ronda Catalunya</li>
                                                                </ul>
                                                                Filtrar por código postal:
                                                                <ul>
                                                                    <li><strong>postal_code:</strong> 08210</li>
                                                                </ul>
                                                                Filtrar por la página:
                                                                <ul>
                                                                    <li><strong>page:</strong> 1</li>
                                                                </ul>
                                                            </p>
                                                            <SyntaxHighlighter
                                                                language={"json"}
                                                                style={dark}
                                                            >
                                                                {
                                                                    `{
    "ranking_consumption_letter":"D",
    "ranking_emissions_letter":"C",
    "certificate_number": "44LSBJBG7",
    "postal_code": "08210",
    "address": "Ronda Catalunya",
    "page": 1
}`
                                                                }
                                                            </SyntaxHighlighter>
                                                            <h3>Response</h3>
                                                            <SyntaxHighlighter
                                                                language={"json"}
                                                                style={dark}
                                                            >
                                                                {
                                                                    `{
    "data": {
        "certificate": [
            {
                "num_certificate": "44LSBJBG7",
                "address": "Ronda Catalunya",
                "number": "52",
                "step": "",
                "floor": "",
                "door": "",
                "postal_code": 8210,
                "municipality": "Barberà del Vallès",
                "region": "Vallès Occidental",
                "province": "Barcelona",
                "code_municipality": 8252,
                "code_region": 40,
                "code_province": 8,
                "rc": "7258013DF2975N0000SD",
                "climatic_zone": "C2",
                "surface": "120.6",
                "year": "",
                "building_type": "Habitatge unifamiliar",
                "ranking_consumption": "B",
                "consumption_energy_primary": "54.15",
                "ranking_emissions_co2": "B",
                "emissions_co2": "11.2",
                "final_consumption": "10.91",
                "annual_energy_cost": "121.42",
                "electric_car": false,
                "thermic_solar": false,
                "solar_photovoltaic": false,
                "biomass_system": false,
                "district_network": false,
                "geotermic_energy": false,
                "technical_installation_report_building": "",
                "certification_tool": "CERMA",
                "isolation_value": "0.25",
                "window_value": "1.57",
                "certification_reason": "Nova construcció",
                "CTE_isolation_value": "0.49",
                "CTE_window_value": "2.1",
                "utm_x": "",
                "utm_y": "",
                "building_regulation": "CTE 2013",
                "type_procedure": "Edificis acabats",
                "tertiary_type": "",
                "ranking_heating_emissions": "B",
                "heating_emissions": "6.35",
                "ranking_refrigeration_emissions": "B",
                "refrigeration_emissions": "1.17",
                "ranking_ACS_emissions": "B",
                "ACS_emissions": "3.68",
                "ranking_lightning": "",
                "lightning_emissions": "",
                "ranking_heating_consumption": "B",
                "heating_consumption": "29.97",
                "ranking_refrigeration_consumption": "C",
                "refrigeration_consumption": "6.91",
                "ranking_ACS_consumption": "C",
                "ACS_consumption": "17.27",
                "ranking_lightning_consumption": "",
                "lightning_consumption": "",
                "ranking_request_heating": "B",
                "request_heating": "27.17",
                "ranking_request_refrigeration": "C",
                "request_refrigeration": "7.07",
                "refrigeration_residential_use": "0.63",
                "latitude": "",
                "longitude": "",
                "geo_reference": "",
                "energy_rehabilitation": false,
                "rehabilitation_actions": "",
                "date": "28-05-2018"
            }
        ]
    },
    "meta": {
        "success": true,
        "errors": []
    }
}`
                                                                }
                                                            </SyntaxHighlighter>
                                                        </TabPane>
                                                        <TabPane tabId="iconTabs11">
                                                            <h3>Url</h3>
                                                            <p>Búsqueda de certificados por referencia catastral.</p>
                                                            <SyntaxHighlighter
                                                                language={"json"}
                                                                style={dark}
                                                            >
                                                                {
                                                                    `GET api/extremadura/energy-certificate/{rc}`
                                                                }
                                                            </SyntaxHighlighter>
                                                            <h3>Request</h3>
                                                            <SyntaxHighlighter
                                                                language={"json"}
                                                                style={dark}
                                                            >
                                                                {
                                                                    `{

}`
                                                                }
                                                            </SyntaxHighlighter>

                                                            <h3>Response</h3>
                                                            <SyntaxHighlighter
                                                                language={"json"}
                                                                style={dark}
                                                            >
                                                                {
                                                                    `{
    "data": {
        "certificate": [
            {
                "rating_primary_energy_consumption": "G",
                "rating_emissions_co2": "F",
                "energy_primary_consumption": "",
                "postal_code": 6003,
                "address": "C/ ESTADIUM, 14 5ºD",
                "municipality": "Badajoz",
                "building_name": "VIVIENDA",
                "province": "Badajoz",
                "rc": "6450704PD7065A0040FT",
                "emissions_co2": "",
                "expired_date": "",
                "date": "",
                "certificate_type": "CEEE de Edificio existente o parte del mismo.",
                "building_type": "Vivienda individual en bloque Públicas"
            }
        ]
    },
    "meta": {
        "success": true,
        "errors": []
    }
}`
                                                                }
                                                            </SyntaxHighlighter>

                                                            <h3>Url</h3>
                                                            <p>Búsqueda de certificados por filtros.</p>
                                                            <SyntaxHighlighter
                                                                language={"json"}
                                                                style={dark}
                                                            >
                                                                {
                                                                    `POST api/extremadura/energy-certificate/search`
                                                                }
                                                            </SyntaxHighlighter>
                                                            <h3>Request</h3>
                                                            <p>
                                                                Filtrar por la letra de consumo:
                                                                <ul>
                                                                    <li><strong>ranking_consumption_letter:</strong> A,
                                                                        B, C, D, E, F
                                                                    </li>
                                                                </ul>
                                                                Filtrar por la letra de emisiones:
                                                                <ul>
                                                                    <li><strong>ranking_emissions_letter:</strong> A, B,
                                                                        C, D, E, F
                                                                    </li>
                                                                </ul>
                                                                Filtrar por el número de certificado:
                                                                <ul>
                                                                    <li><strong>certificate_number:</strong> 44LSBJBG7
                                                                    </li>
                                                                </ul>
                                                                Filtrar por la dirección:
                                                                <ul>
                                                                    <li><strong>address:</strong> Ronda Catalunya</li>
                                                                </ul>
                                                                Filtrar por código postal:
                                                                <ul>
                                                                    <li><strong>postal_code:</strong> 08210</li>
                                                                </ul>
                                                                Filtrar por la página:
                                                                <ul>
                                                                    <li><strong>page:</strong> 1</li>
                                                                </ul>
                                                            </p>
                                                            <SyntaxHighlighter
                                                                language={"json"}
                                                                style={dark}
                                                            >
                                                                {
                                                                    `{
    "ranking_consumption_letter":"D",
    "ranking_emissions_letter":"C",
    "certificate_number": "44LSBJBG7",
    "postal_code": "06003",
    "address": "C/ ESTADIUM,",
    "page": 1
}`
                                                                }
                                                            </SyntaxHighlighter>
                                                            <h3>Response</h3>
                                                            <SyntaxHighlighter
                                                                language={"json"}
                                                                style={dark}
                                                            >
                                                                {
                                                                    `{
    "data": {
        "certificate": [
            {
                "rating_primary_energy_consumption": "G",
                "rating_emissions_co2": "F",
                "energy_primary_consumption": "",
                "postal_code": 6003,
                "address": "C/ ESTADIUM, 14 5ºD",
                "municipality": "Badajoz",
                "building_name": "VIVIENDA",
                "province": "Badajoz",
                "rc": "6450704PD7065A0040FT",
                "emissions_co2": "",
                "expired_date": "",
                "date": "",
                "certificate_type": "CEEE de Edificio existente o parte del mismo.",
                "building_type": "Vivienda individual en bloque Públicas"
            }
        ]
    },
    "meta": {
        "success": true,
        "errors": []
    }
}`
                                                                }
                                                            </SyntaxHighlighter>
                                                        </TabPane>
                                                        <TabPane tabId="iconTabs12">
                                                            <h3>Url</h3>
                                                            <p>Búsqueda de certificados por referencia catastral.</p>
                                                            <SyntaxHighlighter
                                                                language={"json"}
                                                                style={dark}
                                                            >
                                                                {
                                                                    `GET api/galicia/energy-certificate/{rc}`
                                                                }
                                                            </SyntaxHighlighter>
                                                            <h3>Request</h3>
                                                            <SyntaxHighlighter
                                                                language={"json"}
                                                                style={dark}
                                                            >
                                                                {
                                                                    `{

}`
                                                                }
                                                            </SyntaxHighlighter>

                                                            <h3>Response</h3>
                                                            <SyntaxHighlighter
                                                                language={"json"}
                                                                style={dark}
                                                            >
                                                                {
                                                                    `{
    "data": {
        "certificate": [
            {
                "request_number": "100002",
                "register_number": "IN413D/1/15/R",
                "register_type": "CEE EDIFICIO EXISTENTE",
                "expired_date": "01/06/2025",
                "request_year": "2015",
                "building_regulation": "CTE 2006",
                "building_use": "VIVENDA INDIVIDUAL DENTRO DUN BLOQUE",
                "address": " CALLE MONTROIG 1  06 E",
                "municipality": "A CORUÑA",
                "province": "A CORUÑA",
                "postal_code": "15001",
                "rc": "9128217NJ4092N0082BD",
                "ranking_consumption": "D",
                "consumption": "74.65",
                "ranking_emissions": "D",
                "emissions": "16.33"
            }
        ]
    },
    "meta": {
        "success": true,
        "errors": []
    }
}`
                                                                }
                                                            </SyntaxHighlighter>

                                                            <h3>Url</h3>
                                                            <p>Búsqueda de certificados por filtros.</p>
                                                            <SyntaxHighlighter
                                                                language={"json"}
                                                                style={dark}
                                                            >
                                                                {
                                                                    `POST api/galicia/energy-certificate/search`
                                                                }
                                                            </SyntaxHighlighter>
                                                            <h3>Request</h3>
                                                            <p>
                                                                Filtrar por la letra de consumo:
                                                                <ul>
                                                                    <li><strong>ranking_consumption_letter:</strong> A,
                                                                        B, C, D, E, F
                                                                    </li>
                                                                </ul>
                                                                Filtrar por la letra de emisiones:
                                                                <ul>
                                                                    <li><strong>ranking_emissions_letter:</strong> A, B,
                                                                        C, D, E, F
                                                                    </li>
                                                                </ul>
                                                                Filtrar por la dirección:
                                                                <ul>
                                                                    <li><strong>address:</strong> CALLE MONTROIG</li>
                                                                </ul>
                                                                Filtrar por código postal:
                                                                <ul>
                                                                    <li><strong>postal_code:</strong> 15001</li>
                                                                </ul>
                                                                Filtrar por la página:
                                                                <ul>
                                                                    <li><strong>page:</strong> 1</li>
                                                                </ul>
                                                            </p>
                                                            <SyntaxHighlighter
                                                                language={"json"}
                                                                style={dark}
                                                            >
                                                                {
                                                                    `{
    "ranking_consumption_letter":"D",
    "ranking_emissions_letter":"C",
    "postal_code": "15001",
    "address": "CALLE MONTROIG",
    "page": 1
}`
                                                                }
                                                            </SyntaxHighlighter>
                                                            <h3>Response</h3>
                                                            <SyntaxHighlighter
                                                                language={"json"}
                                                                style={dark}
                                                            >
                                                                {
                                                                    `{
    "data": {
        "certificate": [
            {
                "request_number": "100002",
                "register_number": "IN413D/1/15/R",
                "register_type": "CEE EDIFICIO EXISTENTE",
                "expired_date": "01/06/2025",
                "request_year": "2015",
                "building_regulation": "CTE 2006",
                "building_use": "VIVENDA INDIVIDUAL DENTRO DUN BLOQUE",
                "address": " CALLE MONTROIG 1  06 E",
                "municipality": "A CORUÑA",
                "province": "A CORUÑA",
                "postal_code": "15001",
                "rc": "9128217NJ4092N0082BD",
                "ranking_consumption": "D",
                "consumption": "74.65",
                "ranking_emissions": "D",
                "emissions": "16.33"
            }
        ]
    },
    "meta": {
        "success": true,
        "errors": []
    }
}`
                                                                }
                                                            </SyntaxHighlighter>
                                                        </TabPane>
                                                        <TabPane tabId="iconTabs13">
                                                            <h3>Url</h3>
                                                            <p>Búsqueda de certificados por referencia catastral.</p>
                                                            <SyntaxHighlighter
                                                                language={"json"}
                                                                style={dark}
                                                            >
                                                                {
                                                                    `GET api/madrid/energy-certificate/{rc}`
                                                                }
                                                            </SyntaxHighlighter>
                                                            <h3>Request</h3>
                                                            <SyntaxHighlighter
                                                                language={"json"}
                                                                style={dark}
                                                            >
                                                                {
                                                                    `{

}`
                                                                }
                                                            </SyntaxHighlighter>

                                                            <h3>Response</h3>
                                                            <SyntaxHighlighter
                                                                language={"json"}
                                                                style={dark}
                                                            >
                                                                {
                                                                    `{
    "data": {
        "certificate": [
            {
                "register_type": "",
                "register_code": "05/187736.9/13",
                "address": "ALTO ARENAL 12 BLOQUE BLOQ, PORTAL E, ESCALERA INT, PLANTA 5º, PUERTA A,",
                "municipality": "RASCAFRÍA",
                "postal_code": "28942",
                "province": "",
                "climatic_zone": "d3",
                "year": "1978",
                "regulation": "",
                "rc": "1325432465",
                "building_type": "Edificio terciario",
                "software": "",
                "date": "31/05/2013",
                "surface": "465655",
                "surface_total": "465655",
                "heating": "",
                "refrigeration": "",
                "windows": "",
                "acs": "",
                "heating_name": "",
                "heating_type": "",
                "heating_power": "",
                "heating_performance": "",
                "heating_vector": "",
                "heating_mode": "",
                "refrigeration_name": "",
                "refrigeration_type": "",
                "refrigeration_power": "",
                "refrigeration_performance": "",
                "refrigeration_vector": "",
                "refrigeration_mode": "",
                "acs_name": "",
                "acs_type": "",
                "acs_power": "",
                "acs_performance": "",
                "acs_vector": "",
                "acs_mode": "",
                "acs_total_power": "",
                "thermal_name": "",
                "thermal_heating": "",
                "thermal_refrigeration": "",
                "thermal_acs_consumption": "",
                "thermal_acs_request": "",
                "lightning_name": "",
                "lightning_energy": "",
                "lightning_reduction_global": "",
                "lightning_reduction_emissions_co2": "",
                "lightning_request_global": "",
                "lightning_request_heating": "",
                "lightning_request_refrigeration": "",
                "lightning_request_acs": "",
                "final_global": "",
                "final_heating": "",
                "final_refrigeration": "",
                "final_acs": "",
                "final_lighting": "",
                "energy_no_renewable_global": "4565",
                "energy_no_renewable_heating": "",
                "energy_no_renewable_refrigeration": "",
                "energy_no_renewable_acs": "",
                "energy_no_renewable_lightning": "",
                "energy_no_renewable_emissions_co2_global": "4656",
                "energy_no_renewable_emissions_co2_heating": "",
                "energy_no_renewable_emissions_co2_refrigeration": "",
                "energy_no_renewable_emissions_co2_acs": "",
                "energy_no_renewable_emissions_co2_lightning": "",
                "energy_no_renewable_emissions_co2_electric": "",
                "energy_no_renewable_emissions_co2_others": "",
                "energy_no_renewable_emissions_co2_total_electric": "",
                "energy_no_renewable_emissions_co2_total_others": "",
                "ranking_heating_limit": "",
                "ranking_refrigeration_limit": "",
                "ranking_heating_request": "",
                "ranking_refrigeration_request": "",
                "ranking_no_renewable_limit": "",
                "ranking_no_renewable_global": "",
                "ranking_no_renewable_heating": "",
                "ranking_no_renewable_refrigeration": "",
                "ranking_no_renewable_acs": "",
                "ranking_no_renewable_lightning": "",
                "ranking_emissions_co2_limit": "",
                "ranking_emissions_co2_global": "A",
                "ranking_emissions_co2_heating": "",
                "ranking_emissions_co2_refrigeration": "",
                "ranking_emissions_co2_acs": "",
                "ranking_emissions_co2_lighting": ""
            }
        ]
    },
    "meta": {
        "success": true,
        "errors": []
    }
}`
                                                                }
                                                            </SyntaxHighlighter>

                                                            <h3>Url</h3>
                                                            <p>Búsqueda de certificados por filtros.</p>
                                                            <SyntaxHighlighter
                                                                language={"json"}
                                                                style={dark}
                                                            >
                                                                {
                                                                    `POST api/madrid/energy-certificate/search`
                                                                }
                                                            </SyntaxHighlighter>
                                                            <h3>Request</h3>
                                                            <p>
                                                                Filtrar por la letra de consumo:
                                                                <ul>
                                                                    <li><strong>ranking_consumption_letter:</strong> A,
                                                                        B, C, D, E, F
                                                                    </li>
                                                                </ul>
                                                                Filtrar por la letra de emisiones:
                                                                <ul>
                                                                    <li><strong>ranking_emissions_letter:</strong> A, B,
                                                                        C, D, E, F
                                                                    </li>
                                                                </ul>
                                                                Filtrar por la dirección:
                                                                <ul>
                                                                    <li><strong>address:</strong> ALTO ARENAL 12</li>
                                                                </ul>
                                                                Filtrar por código de registro:
                                                                <ul>
                                                                    <li><strong>register_code:</strong> 05/187736.9/13
                                                                    </li>
                                                                </ul>
                                                                Filtrar por código postal:
                                                                <ul>
                                                                    <li><strong>postal_code:</strong> 28942</li>
                                                                </ul>
                                                                Filtrar por la página:
                                                                <ul>
                                                                    <li><strong>page:</strong> 1</li>
                                                                </ul>
                                                            </p>
                                                            <SyntaxHighlighter
                                                                language={"json"}
                                                                style={dark}
                                                            >
                                                                {
                                                                    `{
    "ranking_consumption_letter":"D",
    "ranking_emissions_letter":"C",
    "postal_code": "28942",
    "address": "ALTO ARENAL 12",
    "register_code":"05/187736.9/13",
    "page": 1
}`
                                                                }
                                                            </SyntaxHighlighter>
                                                            <h3>Response</h3>
                                                            <SyntaxHighlighter
                                                                language={"json"}
                                                                style={dark}
                                                            >
                                                                {
                                                                    `{
    "data": {
        "certificate": [
            {
                "register_type": "",
                "register_code": "05/187736.9/13",
                "address": "ALTO ARENAL 12 BLOQUE BLOQ, PORTAL E, ESCALERA INT, PLANTA 5º, PUERTA A,",
                "municipality": "RASCAFRÍA",
                "postal_code": "28942",
                "province": "",
                "climatic_zone": "d3",
                "year": "1978",
                "regulation": "",
                "rc": "1325432465",
                "building_type": "Edificio terciario",
                "software": "",
                "date": "31/05/2013",
                "surface": "465655",
                "surface_total": "465655",
                "heating": "",
                "refrigeration": "",
                "windows": "",
                "acs": "",
                "heating_name": "",
                "heating_type": "",
                "heating_power": "",
                "heating_performance": "",
                "heating_vector": "",
                "heating_mode": "",
                "refrigeration_name": "",
                "refrigeration_type": "",
                "refrigeration_power": "",
                "refrigeration_performance": "",
                "refrigeration_vector": "",
                "refrigeration_mode": "",
                "acs_name": "",
                "acs_type": "",
                "acs_power": "",
                "acs_performance": "",
                "acs_vector": "",
                "acs_mode": "",
                "acs_total_power": "",
                "thermal_name": "",
                "thermal_heating": "",
                "thermal_refrigeration": "",
                "thermal_acs_consumption": "",
                "thermal_acs_request": "",
                "lightning_name": "",
                "lightning_energy": "",
                "lightning_reduction_global": "",
                "lightning_reduction_emissions_co2": "",
                "lightning_request_global": "",
                "lightning_request_heating": "",
                "lightning_request_refrigeration": "",
                "lightning_request_acs": "",
                "final_global": "",
                "final_heating": "",
                "final_refrigeration": "",
                "final_acs": "",
                "final_lighting": "",
                "energy_no_renewable_global": "4565",
                "energy_no_renewable_heating": "",
                "energy_no_renewable_refrigeration": "",
                "energy_no_renewable_acs": "",
                "energy_no_renewable_lightning": "",
                "energy_no_renewable_emissions_co2_global": "4656",
                "energy_no_renewable_emissions_co2_heating": "",
                "energy_no_renewable_emissions_co2_refrigeration": "",
                "energy_no_renewable_emissions_co2_acs": "",
                "energy_no_renewable_emissions_co2_lightning": "",
                "energy_no_renewable_emissions_co2_electric": "",
                "energy_no_renewable_emissions_co2_others": "",
                "energy_no_renewable_emissions_co2_total_electric": "",
                "energy_no_renewable_emissions_co2_total_others": "",
                "ranking_heating_limit": "",
                "ranking_refrigeration_limit": "",
                "ranking_heating_request": "",
                "ranking_refrigeration_request": "",
                "ranking_no_renewable_limit": "",
                "ranking_no_renewable_global": "",
                "ranking_no_renewable_heating": "",
                "ranking_no_renewable_refrigeration": "",
                "ranking_no_renewable_acs": "",
                "ranking_no_renewable_lightning": "",
                "ranking_emissions_co2_limit": "",
                "ranking_emissions_co2_global": "A",
                "ranking_emissions_co2_heating": "",
                "ranking_emissions_co2_refrigeration": "",
                "ranking_emissions_co2_acs": "",
                "ranking_emissions_co2_lighting": ""
            }
        ]
    },
    "meta": {
        "success": true,
        "errors": []
    }
}`
                                                                }
                                                            </SyntaxHighlighter>
                                                        </TabPane>
                                                        <TabPane tabId="iconTabs14">
                                                            <h3>Url</h3>
                                                            <p>Búsqueda de certificados por referencia catastral.</p>
                                                            <SyntaxHighlighter
                                                                language={"json"}
                                                                style={dark}
                                                            >
                                                                {
                                                                    `GET api/murcia/energy-certificate/{rc}`
                                                                }
                                                            </SyntaxHighlighter>
                                                            <h3>Request</h3>
                                                            <SyntaxHighlighter
                                                                language={"json"}
                                                                style={dark}
                                                            >
                                                                {
                                                                    `{

}`
                                                                }
                                                            </SyntaxHighlighter>

                                                            <h3>Response</h3>
                                                            <SyntaxHighlighter
                                                                language={"json"}
                                                                style={dark}
                                                            >
                                                                {
                                                                    `{
    "data": {
        "certificate": [
            {
                "address": "CALLE MAYOR, 12, ALBUDEITE (MURCIA). CP 30190",
                "code": "4P23RCE03206",
                "municipality": "ALBUDEITE",
                "locale": "",
                "building_type": "Vivienda Unifamiliar",
                "rc": "1803006XH4110D0001LP",
                "date": "01/03/2023",
                "ranking_consumption": "E",
                "consumption": "182",
                "ranking_emissions_co2": "E",
                "emissions_co2": "37.3",
                "url_xml": "https://mui.carm.es/rest-services/services/restFileDB/calificacionEnergeticaFichero/4P23RCE03206_ES_A14036704_2023_DOCH179897442M1677670124816RBF_6/Documentación acreditativaVIVIENDA ALBUDEITE.xml",
                "certificate_type": "Edificio existente",
                "postal_code": "30190",
                "province": "MURCIA",
                "year": "1940",
                "current_regulation": "Anterior a NBE-CT-79",
                "building_surface": "203"
            }
        ]
    },
    "meta": {
        "success": true,
        "errors": []
    }
}`
                                                                }
                                                            </SyntaxHighlighter>

                                                            <h3>Url</h3>
                                                            <p>Búsqueda de certificados por filtros.</p>
                                                            <SyntaxHighlighter
                                                                language={"json"}
                                                                style={dark}
                                                            >
                                                                {
                                                                    `POST api/murcia/energy-certificate/search`
                                                                }
                                                            </SyntaxHighlighter>
                                                            <h3>Request</h3>
                                                            <p>
                                                                Filtrar por la letra de consumo:
                                                                <ul>
                                                                    <li><strong>ranking_consumption_letter:</strong> A,
                                                                        B, C, D, E, F
                                                                    </li>
                                                                </ul>
                                                                Filtrar por la letra de emisiones:
                                                                <ul>
                                                                    <li><strong>ranking_emissions_letter:</strong> A, B,
                                                                        C, D, E, F
                                                                    </li>
                                                                </ul>
                                                                Filtrar por la dirección:
                                                                <ul>
                                                                    <li><strong>address:</strong> CALLE MAYOR,</li>
                                                                </ul>
                                                                Filtrar por código de registro:
                                                                <ul>
                                                                    <li><strong>register_code:</strong> 4P23RCE03206
                                                                    </li>
                                                                </ul>
                                                                Filtrar por código postal:
                                                                <ul>
                                                                    <li><strong>postal_code:</strong> 30190</li>
                                                                </ul>
                                                                Filtrar por la página:
                                                                <ul>
                                                                    <li><strong>page:</strong> 1</li>
                                                                </ul>
                                                            </p>
                                                            <SyntaxHighlighter
                                                                language={"json"}
                                                                style={dark}
                                                            >
                                                                {
                                                                    `{
    "ranking_consumption_letter":"D",
    "ranking_emissions_letter":"C",
    "postal_code": "30190",
    "address": "CALLE MAYOR,",
    "code":"4P23RCE03206",
    "page": 1
}`
                                                                }
                                                            </SyntaxHighlighter>
                                                            <h3>Response</h3>
                                                            <SyntaxHighlighter
                                                                language={"json"}
                                                                style={dark}
                                                            >
                                                                {
                                                                    `{
    "data": {
        "certificate": [
            {
                "address": "CALLE MAYOR, 12, ALBUDEITE (MURCIA). CP 30190",
                "code": "4P23RCE03206",
                "municipality": "ALBUDEITE",
                "locale": "",
                "building_type": "Vivienda Unifamiliar",
                "rc": "1803006XH4110D0001LP",
                "date": "01/03/2023",
                "ranking_consumption": "E",
                "consumption": "182",
                "ranking_emissions_co2": "E",
                "emissions_co2": "37.3",
                "url_xml": "https://mui.carm.es/rest-services/services/restFileDB/calificacionEnergeticaFichero/4P23RCE03206_ES_A14036704_2023_DOCH179897442M1677670124816RBF_6/Documentación acreditativaVIVIENDA ALBUDEITE.xml",
                "certificate_type": "Edificio existente",
                "postal_code": "30190",
                "province": "MURCIA",
                "year": "1940",
                "current_regulation": "Anterior a NBE-CT-79",
                "building_surface": "203"
            }
        ]
    },
    "meta": {
        "success": true,
        "errors": []
    }
}`
                                                                }
                                                            </SyntaxHighlighter>
                                                        </TabPane>
                                                        <TabPane tabId="iconTabs15">
                                                            <h3>Url</h3>
                                                            <p>Búsqueda de certificados por referencia catastral.</p>
                                                            <SyntaxHighlighter
                                                                language={"json"}
                                                                style={dark}
                                                            >
                                                                {
                                                                    `GET api/navarra/energy-certificate/{rc}`
                                                                }
                                                            </SyntaxHighlighter>
                                                            <h3>Request</h3>
                                                            <SyntaxHighlighter
                                                                language={"json"}
                                                                style={dark}
                                                            >
                                                                {
                                                                    `{

}`
                                                                }
                                                            </SyntaxHighlighter>

                                                            <h3>Response</h3>
                                                            <SyntaxHighlighter
                                                                language={"json"}
                                                                style={dark}
                                                            >
                                                                {
                                                                    `{
    "data": {
        "certificate": [
            {
                "building_code": "130120",
                "type": "EDIFICIO EXISTENTE",
                "register_code": "2023/1368169",
                "address": "CALLE ADOBERIA, 21, 1º, DERECHA",
                "municipality": "MENDAVIA",
                "postal_code": "31587",
                "province": "NAVARRA",
                "zone": "D1",
                "year": "1981",
                "current_legislation": "NBE-CT-79",
                "rc": "310000000001843201OH",
                "building_type": "ALQUILER",
                "process": "CE3X",
                "date": "09/10/2023",
                "surface": "",
                "habitable_rate_surface_heating": "",
                "habitable_rate_surface_refrigeration": "",
                "habitable_rate_surface_glazed": "",
                "ASC_consumption_day": "",
                "heating_generator": {
                    "NOMBRE": " CALEFACCIóN Y ACS",
                    "TIPO": " CALDERA ESTáNDAR",
                    " POT.NOMINAL": " 23.30",
                    " RENDIMIENTOEST.": " 0.77",
                    " VEC.ENERGETICO": " GASOLEOC",
                    " MODOOBTENCION": " ESTIMADO "
                },
                "refrigeration_generator": {
                    "NOMBRE": " ",
                    "TIPO": " ",
                    " POT.NOMINAL": " 0",
                    " RENDIMIENTOEST.": " 0",
                    " VEC.ENERGETICO": " ",
                    " MODOOBTENCION": "  "
                },
                "ACS_installations": {
                    "NOMBRE": " CALEFACCIóN Y ACS",
                    "TIPO": " CALDERA ESTáNDAR",
                    " POT.NOMINAL": " 23.30",
                    " RENDIMIENTOEST.": " 0.77",
                    " VEC.ENERGETICO": " GASOLEOC",
                    " MODOOBTENCION": " ESTIMADO "
                },
                "thermionic_systems": {
                    "NOMBRE": " ",
                    "CONS.FIN.CALEFACCION": " 0",
                    " CONS.FIN.REFRIGERACION": " 0",
                    " CONS.FIN.ACS.": " 0",
                    " DEMANDAACS": " 0 "
                },
                "electric_systems": {
                    "NOMBRE": " ",
                    "ENER.GEN.AUTOCONSUMIDA": " 0 "
                },
                "installed_total_power": "",
                "name": "",
                "heating_consumption_final": "0.0",
                "refrigeration_consumption_final": "0.0",
                "ACS_consumption_final": "0.0",
                "ASC_consumption": "0.0",
                "name_1": "",
                "auto_consumption_generate_energy": "0.0",
                "no_renovable_refuction_global_primary_energy": "",
                "global": "",
                "heating": "",
                "refrigeration": "",
                "ACS": "",
                "natural_gas": {
                    "GLOBAL": " 0.00",
                    "CALEFACCIóN": " 0.00",
                    "REFRIGERACIóN": " 0.00",
                    "ACS": " ",
                    "ILUMINACIóN": " 0.00 "
                },
                "diesel_c": {
                    "GLOBAL": " 183.92",
                    "CALEFACCIóN": " 151.75",
                    "REFRIGERACIóN": " 0.00",
                    "ACS": " 32.17",
                    "ILUMINACIóN": " 0.00 "
                },
                "GLP": {
                    "GLOBAL": " 0.00",
                    "CALEFACCIóN": " 0.00",
                    "REFRIGERACIóN": " 0.00",
                    "ACS": " 0.00",
                    "ILUMINACIóN": " 0.00 "
                },
                "carbon": {
                    "GLOBAL": " 0.00",
                    "CALEFACCIóN": " 0.00",
                    "REFRIGERACIóN": " 0.00",
                    "ACS": " 0.00",
                    "ILUMINACIóN": " 0.00 "
                },
                "pellet_biomass": {
                    "GLOBAL": " 0.00",
                    "CALEFACCIóN": " 0.00",
                    "REFRIGERACIóN": " 0.00",
                    "ACS": " 0.00",
                    "ILUMINACIóN": " 0.00 "
                },
                "other_biomass": {
                    "GLOBAL": " 0.00",
                    "CALEFACCIóN": " 0.00",
                    "REFRIGERACIóN": " 0.00",
                    "ACS": " 0.00",
                    "ILUMINACIóN": " 0.00 "
                },
                "peninsular_electric": {
                    "GLOBAL": " 0.00",
                    "CALEFACCIóN": " 0.00",
                    "REFRIGERACIóN": " 0.00",
                    "ACS": " 0.00",
                    "ILUMINACIóN": " 0.00 "
                },
                "balears_electric": {
                    "GLOBAL": " ",
                    "CALEFACCIóN": " ",
                    "REFRIGERACIóN": " ",
                    "ACS": " ",
                    "ILUMINACIóN": "  "
                },
                "canary_electric": {
                    "GLOBAL": " ",
                    "CALEFACCIóN": " ",
                    "REFRIGERACIóN": " ",
                    "ACS": " ",
                    "ILUMINACIóN": "  "
                },
                "ceuta_and_melilla_electric": {
                    "GLOBAL": " ",
                    "CALEFACCIóN": " ",
                    "REFRIGERACIóN": " ",
                    "ACS": " ",
                    "ILUMINACIóN": "  "
                },
                "biofuel": {
                    "GLOBAL": " 0.00",
                    "CALEFACCIóN": " 0.00",
                    "REFRIGERACIóN": " 0.00",
                    "ACS": " 0.00",
                    "ILUMINACIóN": " 0.00 "
                },
                "no_renovable_primary_energy": {
                    "GLOBAL": " 216.84",
                    "CALEFACCIóN": " 178.91",
                    "REFRIGERACIóN": " 0.00",
                    "ACS": " 37.92",
                    "ILUMINACIóN": " 0.00 "
                },
                "emissions_co2": {
                    "GLOBAL": " 57.20",
                    "CALEFACCIóN": " 47.19",
                    "REFRIGERACIóN": " 0.00",
                    "ACS": " 10.00",
                    "ILUMINACIóN": " 0.00",
                    "CONS.ELéCTRICO": " 0.00",
                    "CONS.OTROS": " 57.20",
                    "TOT.CONS.ELECTRICO": " 0.00",
                    "TOT.CONS.OTROS": " 5262.24 "
                },
                "ranking_demand_heating_scale": {
                    "A": " 11.70",
                    "B": " 27.00",
                    "C": " 48.70",
                    "D": " 81.60",
                    "E": " 144.10",
                    "F": " 157.10 "
                },
                "ranking_demand_refrigeration_scale": {
                    "A": " ",
                    "B": " ",
                    "C": " ",
                    "D": " ",
                    "E": " ",
                    "F": "  "
                },
                "ranking_heating": "E",
                "ranking_refrigeration": "",
                "ranking_energy_no_renewable_primary_global_scale": {
                    "A": " 37.50",
                    "B": " 57.70",
                    "C": " 86.10",
                    "D": " 128.20",
                    "E": " 271.90",
                    "F": " 318.10 "
                },
                "ranking_energy_no_renewable_primary_global": "E",
                "ranking_energy_no_renewable_primary_heating": "E",
                "ranking_energy_no_renewable_primary_refrigeration": "",
                "ranking_energy_no_renewable_primary_ACS": "G",
                "ranking_energy_no_renewable_primary_lighting": "",
                "ranking_emission_co2_scale_global": {
                    "A": " 8.40",
                    "B": " 12.90",
                    "C": " 19.30",
                    "D": " 28.70",
                    "E": " 59.90",
                    "F": " 71.80 "
                },
                "ranking_emission_co2_global": "E",
                "ranking_emission_co2_heating": "E",
                "ranking_emission_co2_refrigeration": "",
                "ranking_emission_co2_ACS": "G",
                "ranking_emission_co2_lighting": ""
            },
            {
                "building_code": "130120",
                "type": "EDIFICIO EXISTENTE",
                "register_code": "2023/1368169",
                "address": "CALLE ADOBERIA, 21, 1º, DERECHA",
                "municipality": "MENDAVIA",
                "postal_code": "31587",
                "province": "NAVARRA",
                "zone": "D1",
                "year": "1981",
                "current_legislation": "NBE-CT-79",
                "rc": "310000000001843201OH",
                "building_type": "ALQUILER",
                "process": "CE3X",
                "date": "09/10/2023",
                "surface": "",
                "habitable_rate_surface_heating": "",
                "habitable_rate_surface_refrigeration": "",
                "habitable_rate_surface_glazed": "",
                "ASC_consumption_day": "",
                "heating_generator": {
                    "NOMBRE": " CALEFACCIóN Y ACS",
                    "TIPO": " CALDERA ESTáNDAR",
                    " POT.NOMINAL": " 23.30",
                    " RENDIMIENTOEST.": " 0.77",
                    " VEC.ENERGETICO": " GASOLEOC",
                    " MODOOBTENCION": " ESTIMADO "
                },
                "refrigeration_generator": {
                    "NOMBRE": " ",
                    "TIPO": " ",
                    " POT.NOMINAL": " 0",
                    " RENDIMIENTOEST.": " 0",
                    " VEC.ENERGETICO": " ",
                    " MODOOBTENCION": "  "
                },
                "ACS_installations": {
                    "NOMBRE": " CALEFACCIóN Y ACS",
                    "TIPO": " CALDERA ESTáNDAR",
                    " POT.NOMINAL": " 23.30",
                    " RENDIMIENTOEST.": " 0.77",
                    " VEC.ENERGETICO": " GASOLEOC",
                    " MODOOBTENCION": " ESTIMADO "
                },
                "thermionic_systems": {
                    "NOMBRE": " ",
                    "CONS.FIN.CALEFACCION": " 0",
                    " CONS.FIN.REFRIGERACION": " 0",
                    " CONS.FIN.ACS.": " 0",
                    " DEMANDAACS": " 0 "
                },
                "electric_systems": {
                    "NOMBRE": " ",
                    "ENER.GEN.AUTOCONSUMIDA": " 0 "
                },
                "installed_total_power": "",
                "name": "",
                "heating_consumption_final": "0.0",
                "refrigeration_consumption_final": "0.0",
                "ACS_consumption_final": "0.0",
                "ASC_consumption": "0.0",
                "name_1": "",
                "auto_consumption_generate_energy": "0.0",
                "no_renovable_refuction_global_primary_energy": "",
                "global": "",
                "heating": "",
                "refrigeration": "",
                "ACS": "",
                "natural_gas": {
                    "GLOBAL": " 0.00",
                    "CALEFACCIóN": " 0.00",
                    "REFRIGERACIóN": " 0.00",
                    "ACS": " ",
                    "ILUMINACIóN": " 0.00 "
                },
                "diesel_c": {
                    "GLOBAL": " 183.92",
                    "CALEFACCIóN": " 151.75",
                    "REFRIGERACIóN": " 0.00",
                    "ACS": " 32.17",
                    "ILUMINACIóN": " 0.00 "
                },
                "GLP": {
                    "GLOBAL": " 0.00",
                    "CALEFACCIóN": " 0.00",
                    "REFRIGERACIóN": " 0.00",
                    "ACS": " 0.00",
                    "ILUMINACIóN": " 0.00 "
                },
                "carbon": {
                    "GLOBAL": " 0.00",
                    "CALEFACCIóN": " 0.00",
                    "REFRIGERACIóN": " 0.00",
                    "ACS": " 0.00",
                    "ILUMINACIóN": " 0.00 "
                },
                "pellet_biomass": {
                    "GLOBAL": " 0.00",
                    "CALEFACCIóN": " 0.00",
                    "REFRIGERACIóN": " 0.00",
                    "ACS": " 0.00",
                    "ILUMINACIóN": " 0.00 "
                },
                "other_biomass": {
                    "GLOBAL": " 0.00",
                    "CALEFACCIóN": " 0.00",
                    "REFRIGERACIóN": " 0.00",
                    "ACS": " 0.00",
                    "ILUMINACIóN": " 0.00 "
                },
                "peninsular_electric": {
                    "GLOBAL": " 0.00",
                    "CALEFACCIóN": " 0.00",
                    "REFRIGERACIóN": " 0.00",
                    "ACS": " 0.00",
                    "ILUMINACIóN": " 0.00 "
                },
                "balears_electric": {
                    "GLOBAL": " ",
                    "CALEFACCIóN": " ",
                    "REFRIGERACIóN": " ",
                    "ACS": " ",
                    "ILUMINACIóN": "  "
                },
                "canary_electric": {
                    "GLOBAL": " ",
                    "CALEFACCIóN": " ",
                    "REFRIGERACIóN": " ",
                    "ACS": " ",
                    "ILUMINACIóN": "  "
                },
                "ceuta_and_melilla_electric": {
                    "GLOBAL": " ",
                    "CALEFACCIóN": " ",
                    "REFRIGERACIóN": " ",
                    "ACS": " ",
                    "ILUMINACIóN": "  "
                },
                "biofuel": {
                    "GLOBAL": " 0.00",
                    "CALEFACCIóN": " 0.00",
                    "REFRIGERACIóN": " 0.00",
                    "ACS": " 0.00",
                    "ILUMINACIóN": " 0.00 "
                },
                "no_renovable_primary_energy": {
                    "GLOBAL": " 216.84",
                    "CALEFACCIóN": " 178.91",
                    "REFRIGERACIóN": " 0.00",
                    "ACS": " 37.92",
                    "ILUMINACIóN": " 0.00 "
                },
                "emissions_co2": {
                    "GLOBAL": " 57.20",
                    "CALEFACCIóN": " 47.19",
                    "REFRIGERACIóN": " 0.00",
                    "ACS": " 10.00",
                    "ILUMINACIóN": " 0.00",
                    "CONS.ELéCTRICO": " 0.00",
                    "CONS.OTROS": " 57.20",
                    "TOT.CONS.ELECTRICO": " 0.00",
                    "TOT.CONS.OTROS": " 5262.24 "
                },
                "ranking_demand_heating_scale": {
                    "A": " 11.70",
                    "B": " 27.00",
                    "C": " 48.70",
                    "D": " 81.60",
                    "E": " 144.10",
                    "F": " 157.10 "
                },
                "ranking_demand_refrigeration_scale": {
                    "A": " ",
                    "B": " ",
                    "C": " ",
                    "D": " ",
                    "E": " ",
                    "F": "  "
                },
                "ranking_heating": "E",
                "ranking_refrigeration": "",
                "ranking_energy_no_renewable_primary_global_scale": {
                    "A": " 37.50",
                    "B": " 57.70",
                    "C": " 86.10",
                    "D": " 128.20",
                    "E": " 271.90",
                    "F": " 318.10 "
                },
                "ranking_energy_no_renewable_primary_global": "E",
                "ranking_energy_no_renewable_primary_heating": "E",
                "ranking_energy_no_renewable_primary_refrigeration": "",
                "ranking_energy_no_renewable_primary_ACS": "G",
                "ranking_energy_no_renewable_primary_lighting": "",
                "ranking_emission_co2_scale_global": {
                    "A": " 8.40",
                    "B": " 12.90",
                    "C": " 19.30",
                    "D": " 28.70",
                    "E": " 59.90",
                    "F": " 71.80 "
                },
                "ranking_emission_co2_global": "E",
                "ranking_emission_co2_heating": "E",
                "ranking_emission_co2_refrigeration": "",
                "ranking_emission_co2_ACS": "G",
                "ranking_emission_co2_lighting": ""
            }
        ]
    },
    "meta": {
        "success": true,
        "errors": []
    }
}`
                                                                }
                                                            </SyntaxHighlighter>

                                                            <h3>Url</h3>
                                                            <p>Búsqueda de certificados por filtros.</p>
                                                            <SyntaxHighlighter
                                                                language={"json"}
                                                                style={dark}
                                                            >
                                                                {
                                                                    `POST api/navarra/energy-certificate/search`
                                                                }
                                                            </SyntaxHighlighter>
                                                            <h3>Request</h3>
                                                            <p>
                                                                Filtrar por la letra de consumo:
                                                                <ul>
                                                                    <li><strong>ranking_consumption_letter:</strong> A,
                                                                        B, C, D, E, F
                                                                    </li>
                                                                </ul>
                                                                Filtrar por la letra de emisiones:
                                                                <ul>
                                                                    <li><strong>ranking_emissions_letter:</strong> A, B,
                                                                        C, D, E, F
                                                                    </li>
                                                                </ul>
                                                                Filtrar por la dirección:
                                                                <ul>
                                                                    <li><strong>address:</strong> CALLE ADOBERIA</li>
                                                                </ul>
                                                                Filtrar por código postal:
                                                                <ul>
                                                                    <li><strong>postal_code:</strong> 33519</li>
                                                                </ul>
                                                                Filtrar por la página:
                                                                <ul>
                                                                    <li><strong>page:</strong> 1</li>
                                                                </ul>
                                                            </p>
                                                            <SyntaxHighlighter
                                                                language={"json"}
                                                                style={dark}
                                                            >
                                                                {
                                                                    `{
    "ranking_consumption_letter":"D",
    "ranking_emissions_letter":"C",
    "postal_code": "33519",
    "address": "CALLE ADOBERIA",
    "page": 1
}`
                                                                }
                                                            </SyntaxHighlighter>
                                                            <h3>Response</h3>
                                                            <SyntaxHighlighter
                                                                language={"json"}
                                                                style={dark}
                                                            >
                                                                {
                                                                    `{
    "data": {
        "certificate": [
            {
                "building_code": "130120",
                "type": "EDIFICIO EXISTENTE",
                "register_code": "2023/1368169",
                "address": "CALLE ADOBERIA, 21, 1º, DERECHA",
                "municipality": "MENDAVIA",
                "postal_code": "31587",
                "province": "NAVARRA",
                "zone": "D1",
                "year": "1981",
                "current_legislation": "NBE-CT-79",
                "rc": "310000000001843201OH",
                "building_type": "ALQUILER",
                "process": "CE3X",
                "date": "09/10/2023",
                "surface": "",
                "habitable_rate_surface_heating": "",
                "habitable_rate_surface_refrigeration": "",
                "habitable_rate_surface_glazed": "",
                "ASC_consumption_day": "",
                "heating_generator": {
                    "NOMBRE": " CALEFACCIóN Y ACS",
                    "TIPO": " CALDERA ESTáNDAR",
                    " POT.NOMINAL": " 23.30",
                    " RENDIMIENTOEST.": " 0.77",
                    " VEC.ENERGETICO": " GASOLEOC",
                    " MODOOBTENCION": " ESTIMADO "
                },
                "refrigeration_generator": {
                    "NOMBRE": " ",
                    "TIPO": " ",
                    " POT.NOMINAL": " 0",
                    " RENDIMIENTOEST.": " 0",
                    " VEC.ENERGETICO": " ",
                    " MODOOBTENCION": "  "
                },
                "ACS_installations": {
                    "NOMBRE": " CALEFACCIóN Y ACS",
                    "TIPO": " CALDERA ESTáNDAR",
                    " POT.NOMINAL": " 23.30",
                    " RENDIMIENTOEST.": " 0.77",
                    " VEC.ENERGETICO": " GASOLEOC",
                    " MODOOBTENCION": " ESTIMADO "
                },
                "thermionic_systems": {
                    "NOMBRE": " ",
                    "CONS.FIN.CALEFACCION": " 0",
                    " CONS.FIN.REFRIGERACION": " 0",
                    " CONS.FIN.ACS.": " 0",
                    " DEMANDAACS": " 0 "
                },
                "electric_systems": {
                    "NOMBRE": " ",
                    "ENER.GEN.AUTOCONSUMIDA": " 0 "
                },
                "installed_total_power": "",
                "name": "",
                "heating_consumption_final": "0.0",
                "refrigeration_consumption_final": "0.0",
                "ACS_consumption_final": "0.0",
                "ASC_consumption": "0.0",
                "name_1": "",
                "auto_consumption_generate_energy": "0.0",
                "no_renovable_refuction_global_primary_energy": "",
                "global": "",
                "heating": "",
                "refrigeration": "",
                "ACS": "",
                "natural_gas": {
                    "GLOBAL": " 0.00",
                    "CALEFACCIóN": " 0.00",
                    "REFRIGERACIóN": " 0.00",
                    "ACS": " ",
                    "ILUMINACIóN": " 0.00 "
                },
                "diesel_c": {
                    "GLOBAL": " 183.92",
                    "CALEFACCIóN": " 151.75",
                    "REFRIGERACIóN": " 0.00",
                    "ACS": " 32.17",
                    "ILUMINACIóN": " 0.00 "
                },
                "GLP": {
                    "GLOBAL": " 0.00",
                    "CALEFACCIóN": " 0.00",
                    "REFRIGERACIóN": " 0.00",
                    "ACS": " 0.00",
                    "ILUMINACIóN": " 0.00 "
                },
                "carbon": {
                    "GLOBAL": " 0.00",
                    "CALEFACCIóN": " 0.00",
                    "REFRIGERACIóN": " 0.00",
                    "ACS": " 0.00",
                    "ILUMINACIóN": " 0.00 "
                },
                "pellet_biomass": {
                    "GLOBAL": " 0.00",
                    "CALEFACCIóN": " 0.00",
                    "REFRIGERACIóN": " 0.00",
                    "ACS": " 0.00",
                    "ILUMINACIóN": " 0.00 "
                },
                "other_biomass": {
                    "GLOBAL": " 0.00",
                    "CALEFACCIóN": " 0.00",
                    "REFRIGERACIóN": " 0.00",
                    "ACS": " 0.00",
                    "ILUMINACIóN": " 0.00 "
                },
                "peninsular_electric": {
                    "GLOBAL": " 0.00",
                    "CALEFACCIóN": " 0.00",
                    "REFRIGERACIóN": " 0.00",
                    "ACS": " 0.00",
                    "ILUMINACIóN": " 0.00 "
                },
                "balears_electric": {
                    "GLOBAL": " ",
                    "CALEFACCIóN": " ",
                    "REFRIGERACIóN": " ",
                    "ACS": " ",
                    "ILUMINACIóN": "  "
                },
                "canary_electric": {
                    "GLOBAL": " ",
                    "CALEFACCIóN": " ",
                    "REFRIGERACIóN": " ",
                    "ACS": " ",
                    "ILUMINACIóN": "  "
                },
                "ceuta_and_melilla_electric": {
                    "GLOBAL": " ",
                    "CALEFACCIóN": " ",
                    "REFRIGERACIóN": " ",
                    "ACS": " ",
                    "ILUMINACIóN": "  "
                },
                "biofuel": {
                    "GLOBAL": " 0.00",
                    "CALEFACCIóN": " 0.00",
                    "REFRIGERACIóN": " 0.00",
                    "ACS": " 0.00",
                    "ILUMINACIóN": " 0.00 "
                },
                "no_renovable_primary_energy": {
                    "GLOBAL": " 216.84",
                    "CALEFACCIóN": " 178.91",
                    "REFRIGERACIóN": " 0.00",
                    "ACS": " 37.92",
                    "ILUMINACIóN": " 0.00 "
                },
                "emissions_co2": {
                    "GLOBAL": " 57.20",
                    "CALEFACCIóN": " 47.19",
                    "REFRIGERACIóN": " 0.00",
                    "ACS": " 10.00",
                    "ILUMINACIóN": " 0.00",
                    "CONS.ELéCTRICO": " 0.00",
                    "CONS.OTROS": " 57.20",
                    "TOT.CONS.ELECTRICO": " 0.00",
                    "TOT.CONS.OTROS": " 5262.24 "
                },
                "ranking_demand_heating_scale": {
                    "A": " 11.70",
                    "B": " 27.00",
                    "C": " 48.70",
                    "D": " 81.60",
                    "E": " 144.10",
                    "F": " 157.10 "
                },
                "ranking_demand_refrigeration_scale": {
                    "A": " ",
                    "B": " ",
                    "C": " ",
                    "D": " ",
                    "E": " ",
                    "F": "  "
                },
                "ranking_heating": "E",
                "ranking_refrigeration": "",
                "ranking_energy_no_renewable_primary_global_scale": {
                    "A": " 37.50",
                    "B": " 57.70",
                    "C": " 86.10",
                    "D": " 128.20",
                    "E": " 271.90",
                    "F": " 318.10 "
                },
                "ranking_energy_no_renewable_primary_global": "E",
                "ranking_energy_no_renewable_primary_heating": "E",
                "ranking_energy_no_renewable_primary_refrigeration": "",
                "ranking_energy_no_renewable_primary_ACS": "G",
                "ranking_energy_no_renewable_primary_lighting": "",
                "ranking_emission_co2_scale_global": {
                    "A": " 8.40",
                    "B": " 12.90",
                    "C": " 19.30",
                    "D": " 28.70",
                    "E": " 59.90",
                    "F": " 71.80 "
                },
                "ranking_emission_co2_global": "E",
                "ranking_emission_co2_heating": "E",
                "ranking_emission_co2_refrigeration": "",
                "ranking_emission_co2_ACS": "G",
                "ranking_emission_co2_lighting": ""
            },
            {
                "building_code": "130120",
                "type": "EDIFICIO EXISTENTE",
                "register_code": "2023/1368169",
                "address": "CALLE ADOBERIA, 21, 1º, DERECHA",
                "municipality": "MENDAVIA",
                "postal_code": "31587",
                "province": "NAVARRA",
                "zone": "D1",
                "year": "1981",
                "current_legislation": "NBE-CT-79",
                "rc": "310000000001843201OH",
                "building_type": "ALQUILER",
                "process": "CE3X",
                "date": "09/10/2023",
                "surface": "",
                "habitable_rate_surface_heating": "",
                "habitable_rate_surface_refrigeration": "",
                "habitable_rate_surface_glazed": "",
                "ASC_consumption_day": "",
                "heating_generator": {
                    "NOMBRE": " CALEFACCIóN Y ACS",
                    "TIPO": " CALDERA ESTáNDAR",
                    " POT.NOMINAL": " 23.30",
                    " RENDIMIENTOEST.": " 0.77",
                    " VEC.ENERGETICO": " GASOLEOC",
                    " MODOOBTENCION": " ESTIMADO "
                },
                "refrigeration_generator": {
                    "NOMBRE": " ",
                    "TIPO": " ",
                    " POT.NOMINAL": " 0",
                    " RENDIMIENTOEST.": " 0",
                    " VEC.ENERGETICO": " ",
                    " MODOOBTENCION": "  "
                },
                "ACS_installations": {
                    "NOMBRE": " CALEFACCIóN Y ACS",
                    "TIPO": " CALDERA ESTáNDAR",
                    " POT.NOMINAL": " 23.30",
                    " RENDIMIENTOEST.": " 0.77",
                    " VEC.ENERGETICO": " GASOLEOC",
                    " MODOOBTENCION": " ESTIMADO "
                },
                "thermionic_systems": {
                    "NOMBRE": " ",
                    "CONS.FIN.CALEFACCION": " 0",
                    " CONS.FIN.REFRIGERACION": " 0",
                    " CONS.FIN.ACS.": " 0",
                    " DEMANDAACS": " 0 "
                },
                "electric_systems": {
                    "NOMBRE": " ",
                    "ENER.GEN.AUTOCONSUMIDA": " 0 "
                },
                "installed_total_power": "",
                "name": "",
                "heating_consumption_final": "0.0",
                "refrigeration_consumption_final": "0.0",
                "ACS_consumption_final": "0.0",
                "ASC_consumption": "0.0",
                "name_1": "",
                "auto_consumption_generate_energy": "0.0",
                "no_renovable_refuction_global_primary_energy": "",
                "global": "",
                "heating": "",
                "refrigeration": "",
                "ACS": "",
                "natural_gas": {
                    "GLOBAL": " 0.00",
                    "CALEFACCIóN": " 0.00",
                    "REFRIGERACIóN": " 0.00",
                    "ACS": " ",
                    "ILUMINACIóN": " 0.00 "
                },
                "diesel_c": {
                    "GLOBAL": " 183.92",
                    "CALEFACCIóN": " 151.75",
                    "REFRIGERACIóN": " 0.00",
                    "ACS": " 32.17",
                    "ILUMINACIóN": " 0.00 "
                },
                "GLP": {
                    "GLOBAL": " 0.00",
                    "CALEFACCIóN": " 0.00",
                    "REFRIGERACIóN": " 0.00",
                    "ACS": " 0.00",
                    "ILUMINACIóN": " 0.00 "
                },
                "carbon": {
                    "GLOBAL": " 0.00",
                    "CALEFACCIóN": " 0.00",
                    "REFRIGERACIóN": " 0.00",
                    "ACS": " 0.00",
                    "ILUMINACIóN": " 0.00 "
                },
                "pellet_biomass": {
                    "GLOBAL": " 0.00",
                    "CALEFACCIóN": " 0.00",
                    "REFRIGERACIóN": " 0.00",
                    "ACS": " 0.00",
                    "ILUMINACIóN": " 0.00 "
                },
                "other_biomass": {
                    "GLOBAL": " 0.00",
                    "CALEFACCIóN": " 0.00",
                    "REFRIGERACIóN": " 0.00",
                    "ACS": " 0.00",
                    "ILUMINACIóN": " 0.00 "
                },
                "peninsular_electric": {
                    "GLOBAL": " 0.00",
                    "CALEFACCIóN": " 0.00",
                    "REFRIGERACIóN": " 0.00",
                    "ACS": " 0.00",
                    "ILUMINACIóN": " 0.00 "
                },
                "balears_electric": {
                    "GLOBAL": " ",
                    "CALEFACCIóN": " ",
                    "REFRIGERACIóN": " ",
                    "ACS": " ",
                    "ILUMINACIóN": "  "
                },
                "canary_electric": {
                    "GLOBAL": " ",
                    "CALEFACCIóN": " ",
                    "REFRIGERACIóN": " ",
                    "ACS": " ",
                    "ILUMINACIóN": "  "
                },
                "ceuta_and_melilla_electric": {
                    "GLOBAL": " ",
                    "CALEFACCIóN": " ",
                    "REFRIGERACIóN": " ",
                    "ACS": " ",
                    "ILUMINACIóN": "  "
                },
                "biofuel": {
                    "GLOBAL": " 0.00",
                    "CALEFACCIóN": " 0.00",
                    "REFRIGERACIóN": " 0.00",
                    "ACS": " 0.00",
                    "ILUMINACIóN": " 0.00 "
                },
                "no_renovable_primary_energy": {
                    "GLOBAL": " 216.84",
                    "CALEFACCIóN": " 178.91",
                    "REFRIGERACIóN": " 0.00",
                    "ACS": " 37.92",
                    "ILUMINACIóN": " 0.00 "
                },
                "emissions_co2": {
                    "GLOBAL": " 57.20",
                    "CALEFACCIóN": " 47.19",
                    "REFRIGERACIóN": " 0.00",
                    "ACS": " 10.00",
                    "ILUMINACIóN": " 0.00",
                    "CONS.ELéCTRICO": " 0.00",
                    "CONS.OTROS": " 57.20",
                    "TOT.CONS.ELECTRICO": " 0.00",
                    "TOT.CONS.OTROS": " 5262.24 "
                },
                "ranking_demand_heating_scale": {
                    "A": " 11.70",
                    "B": " 27.00",
                    "C": " 48.70",
                    "D": " 81.60",
                    "E": " 144.10",
                    "F": " 157.10 "
                },
                "ranking_demand_refrigeration_scale": {
                    "A": " ",
                    "B": " ",
                    "C": " ",
                    "D": " ",
                    "E": " ",
                    "F": "  "
                },
                "ranking_heating": "E",
                "ranking_refrigeration": "",
                "ranking_energy_no_renewable_primary_global_scale": {
                    "A": " 37.50",
                    "B": " 57.70",
                    "C": " 86.10",
                    "D": " 128.20",
                    "E": " 271.90",
                    "F": " 318.10 "
                },
                "ranking_energy_no_renewable_primary_global": "E",
                "ranking_energy_no_renewable_primary_heating": "E",
                "ranking_energy_no_renewable_primary_refrigeration": "",
                "ranking_energy_no_renewable_primary_ACS": "G",
                "ranking_energy_no_renewable_primary_lighting": "",
                "ranking_emission_co2_scale_global": {
                    "A": " 8.40",
                    "B": " 12.90",
                    "C": " 19.30",
                    "D": " 28.70",
                    "E": " 59.90",
                    "F": " 71.80 "
                },
                "ranking_emission_co2_global": "E",
                "ranking_emission_co2_heating": "E",
                "ranking_emission_co2_refrigeration": "",
                "ranking_emission_co2_ACS": "G",
                "ranking_emission_co2_lighting": ""
            }
        ]
    },
    "meta": {
        "success": true,
        "errors": []
    }
}`
                                                                }
                                                            </SyntaxHighlighter>
                                                        </TabPane>
                                                        <TabPane tabId="iconTabs16">
                                                            <h3>Url</h3>
                                                            <p>Búsqueda de certificados por referencia catastral.</p>
                                                            <SyntaxHighlighter
                                                                language={"json"}
                                                                style={dark}
                                                            >
                                                                {
                                                                    `GET api/pais-vasco/energy-certificate/{rc}`
                                                                }
                                                            </SyntaxHighlighter>
                                                            <h3>Request</h3>
                                                            <SyntaxHighlighter
                                                                language={"json"}
                                                                style={dark}
                                                            >
                                                                {
                                                                    `{

}`
                                                                }
                                                            </SyntaxHighlighter>

                                                            <h3>Response</h3>
                                                            <SyntaxHighlighter
                                                                language={"json"}
                                                                style={dark}
                                                            >
                                                                {
                                                                    `{
    "data": {
        "certificate": [
            {
                "num_register": "CEE/CAPV/2013/00000094/R01",
                "part": "1",
                "address_type": "Etorbidea/Avenida",
                "address": "Praga",
                "full_address": "Praga, 9, 11, 13 y 15. Boulevard, 22, 24 y 26. Nicosia, 1 y Varsovia 8",
                "address_num": "9",
                "float": ",11,13 y 15. Boulevard 22, 24 y 26",
                "extra": "Nicosia 1 y Varsovia 8",
                "municipality": "Vitoria-Gasteiz",
                "locale": "Vitoria-Gasteiz",
                "postal_code": "01003",
                "province": "Araba/Álava",
                "climatic_zone": "D1",
                "year": "2006",
                "regulation": "Anterior NBE-CT-79",
                "rc": "RC pa-213224349111",
                "building_type": "BloqueDeViviendaCompleto",
                "building_range": "Edif. Terminado",
                "surface": "18970.07",
                "compacity": "2.12",
                "heating_surface": "100",
                "refrigerate_surface": "0",
                "request_acs": "12520.25",
                "global": "142.5",
                "heating": "128.97",
                "refrigeration": "0.07",
                "acs": "13.46",
                "consumption": "180",
                "emissions": "38.1",
                "no_renewable_energy_consumption": {
                    "heating": "162.97",
                    "refrigeration": "0.07",
                    "acs": "17.01",
                    "lighting": "0"
                },
                "emissions_co2": {
                    "heating": "34.51",
                    "refrigeration": "0.01",
                    "acs": "3.6",
                    "lighting": "0"
                },
                "ranking_consumption": "E",
                "ranking_emissions": "E",
                "more_data": "https://api.euskadi.eus/energy-efficiency/certificates/CEE-CAPV-2013-00000094-R01",
                "address_code": "130105900008460",
                "portal_code": "80105900176490",
                "province_code": "01",
                "municipality_code": "059",
                "locale_code": "10105900000734",
                "num_houses": "249",
                "state": "Euskadi",
                "process": "CEXv2.3",
                "living_area": "47425.18",
                "glass_area": {
                    "N": "23",
                    "NE": "0",
                    "E": "11",
                    "SE": "0",
                    "S": "19",
                    "SO": "0",
                    "O": "18",
                    "NO": "0"
                },
                "residencial_use_airing": "0.63",
                "total_airing": "0.76",
                "building_request_join": "129.01",
                "consumption_emissions_co2": {
                    "lighting": "0.01",
                    "others": "0.01",
                    "total_lighting": "212.66",
                    "total_others": "723018.31"
                },
                "heating_ranking": {
                    "A": "11.7",
                    "B": "27",
                    "C": "48.7",
                    "D": "81.6",
                    "E": "144.1",
                    "F": "157.1"
                },
                "heating_request_ranking": "E",
                "energy_primary_no_renewable_ranking": {
                    "A": "37.5",
                    "B": "57.7",
                    "C": "86.1",
                    "D": "128.2",
                    "E": "271.9",
                    "F": "318.1"
                },
                "energy_primary_no_renewable_heating_ranking": "E",
                "energy_primary_no_renewable_acs_ranking": "D",
                "emissions_co2_ranking_global": {
                    "A": "8.4",
                    "B": "12.9",
                    "C": "19.3",
                    "D": "28.7",
                    "E": "59.9",
                    "F": "71.8"
                },
                "emissions_co2_heating": "E",
                "emissions_co2_acs": "D",
                "vector_final_energy": {
                    "natural_gas": {
                        "global": "151.24",
                        "heating": "136.95",
                        "refrigeration": "0",
                        "acs": "14.3",
                        "lighting": "0"
                    },
                    "diesel_c": {
                        "global": "0",
                        "heating": "0",
                        "refrigeration": "0",
                        "acs": "0",
                        "lighting": "0"
                    },
                    "GLP": {
                        "global": "0",
                        "heating": "0",
                        "refrigeration": "0",
                        "acs": "0",
                        "lighting": "0"
                    },
                    "carbon": {
                        "global": "0",
                        "heating": "0",
                        "refrigeration": "0",
                        "acs": "0",
                        "lighting": "0"
                    },
                    "biomass": {
                        "global": "0",
                        "heating": "0",
                        "refrigeration": "0",
                        "acs": "0",
                        "lighting": "0"
                    },
                    "biomass_others": {
                        "global": "0",
                        "heating": "0",
                        "refrigeration": "0",
                        "acs": "0",
                        "lighting": "0"
                    },
                    "peninsular_lighting": {
                        "global": "0.03",
                        "heating": "0",
                        "refrigeration": "0.03",
                        "acs": "0",
                        "lighting": "0"
                    },
                    "biofuel": {
                        "global": "0",
                        "heating": "0",
                        "refrigeration": "0",
                        "acs": "0",
                        "lighting": "0"
                    }
                }
            }
        ]
    },
    "meta": {
        "success": true,
        "errors": []
    }
}`
                                                                }
                                                            </SyntaxHighlighter>

                                                            <h3>Url</h3>
                                                            <p>Búsqueda de certificados por filtros.</p>
                                                            <SyntaxHighlighter
                                                                language={"json"}
                                                                style={dark}
                                                            >
                                                                {
                                                                    `POST api/pais-vasco/energy-certificate/search`
                                                                }
                                                            </SyntaxHighlighter>
                                                            <h3>Request</h3>
                                                            <p>
                                                                Filtrar por la letra de consumo:
                                                                <ul>
                                                                    <li><strong>ranking_consumption_letter:</strong> A,
                                                                        B, C, D, E, F
                                                                    </li>
                                                                </ul>
                                                                Filtrar por la letra de emisiones:
                                                                <ul>
                                                                    <li><strong>ranking_emissions_letter:</strong> A, B,
                                                                        C, D, E, F
                                                                    </li>
                                                                </ul>
                                                                Filtrar por la dirección:
                                                                <ul>
                                                                    <li><strong>address:</strong> Praga, 9,</li>
                                                                </ul>
                                                                Filtrar por número de registro:
                                                                <ul>
                                                                    <li>
                                                                        <strong>num_register:</strong> CEE/CAPV/2013/00000094/R01
                                                                    </li>
                                                                </ul>
                                                                Filtrar por código postal:
                                                                <ul>
                                                                    <li><strong>postal_code:</strong> 01006</li>
                                                                </ul>
                                                                Filtrar por la página:
                                                                <ul>
                                                                    <li><strong>page:</strong> 1</li>
                                                                </ul>
                                                            </p>
                                                            <SyntaxHighlighter
                                                                language={"json"}
                                                                style={dark}
                                                            >
                                                                {
                                                                    `{
    "ranking_consumption_letter":"D",
    "ranking_emissions_letter":"C",
    "postal_code": "01006",
    "num_register": "CEE/CAPV/2013/00000094/R01",
    "address": "Praga, 9, ",
    "page": 1
}`
                                                                }
                                                            </SyntaxHighlighter>
                                                            <h3>Response</h3>
                                                            <SyntaxHighlighter
                                                                language={"json"}
                                                                style={dark}
                                                            >
                                                                {
                                                                    `{
    "data": {
        "certificate": [
            {
                "num_register": "CEE/CAPV/2013/00000094/R01",
                "part": "1",
                "address_type": "Etorbidea/Avenida",
                "address": "Praga",
                "full_address": "Praga, 9, 11, 13 y 15. Boulevard, 22, 24 y 26. Nicosia, 1 y Varsovia 8",
                "address_num": "9",
                "float": ",11,13 y 15. Boulevard 22, 24 y 26",
                "extra": "Nicosia 1 y Varsovia 8",
                "municipality": "Vitoria-Gasteiz",
                "locale": "Vitoria-Gasteiz",
                "postal_code": "01003",
                "province": "Araba/Álava",
                "climatic_zone": "D1",
                "year": "2006",
                "regulation": "Anterior NBE-CT-79",
                "rc": "RC pa-213224349111",
                "building_type": "BloqueDeViviendaCompleto",
                "building_range": "Edif. Terminado",
                "surface": "18970.07",
                "compacity": "2.12",
                "heating_surface": "100",
                "refrigerate_surface": "0",
                "request_acs": "12520.25",
                "global": "142.5",
                "heating": "128.97",
                "refrigeration": "0.07",
                "acs": "13.46",
                "consumption": "180",
                "emissions": "38.1",
                "no_renewable_energy_consumption": {
                    "heating": "162.97",
                    "refrigeration": "0.07",
                    "acs": "17.01",
                    "lighting": "0"
                },
                "emissions_co2": {
                    "heating": "34.51",
                    "refrigeration": "0.01",
                    "acs": "3.6",
                    "lighting": "0"
                },
                "ranking_consumption": "E",
                "ranking_emissions": "E",
                "more_data": "https://api.euskadi.eus/energy-efficiency/certificates/CEE-CAPV-2013-00000094-R01",
                "address_code": "130105900008460",
                "portal_code": "80105900176490",
                "province_code": "01",
                "municipality_code": "059",
                "locale_code": "10105900000734",
                "num_houses": "249",
                "state": "Euskadi",
                "process": "CEXv2.3",
                "living_area": "47425.18",
                "glass_area": {
                    "N": "23",
                    "NE": "0",
                    "E": "11",
                    "SE": "0",
                    "S": "19",
                    "SO": "0",
                    "O": "18",
                    "NO": "0"
                },
                "residencial_use_airing": "0.63",
                "total_airing": "0.76",
                "building_request_join": "129.01",
                "consumption_emissions_co2": {
                    "lighting": "0.01",
                    "others": "0.01",
                    "total_lighting": "212.66",
                    "total_others": "723018.31"
                },
                "heating_ranking": {
                    "A": "11.7",
                    "B": "27",
                    "C": "48.7",
                    "D": "81.6",
                    "E": "144.1",
                    "F": "157.1"
                },
                "heating_request_ranking": "E",
                "energy_primary_no_renewable_ranking": {
                    "A": "37.5",
                    "B": "57.7",
                    "C": "86.1",
                    "D": "128.2",
                    "E": "271.9",
                    "F": "318.1"
                },
                "energy_primary_no_renewable_heating_ranking": "E",
                "energy_primary_no_renewable_acs_ranking": "D",
                "emissions_co2_ranking_global": {
                    "A": "8.4",
                    "B": "12.9",
                    "C": "19.3",
                    "D": "28.7",
                    "E": "59.9",
                    "F": "71.8"
                },
                "emissions_co2_heating": "E",
                "emissions_co2_acs": "D",
                "vector_final_energy": {
                    "natural_gas": {
                        "global": "151.24",
                        "heating": "136.95",
                        "refrigeration": "0",
                        "acs": "14.3",
                        "lighting": "0"
                    },
                    "diesel_c": {
                        "global": "0",
                        "heating": "0",
                        "refrigeration": "0",
                        "acs": "0",
                        "lighting": "0"
                    },
                    "GLP": {
                        "global": "0",
                        "heating": "0",
                        "refrigeration": "0",
                        "acs": "0",
                        "lighting": "0"
                    },
                    "carbon": {
                        "global": "0",
                        "heating": "0",
                        "refrigeration": "0",
                        "acs": "0",
                        "lighting": "0"
                    },
                    "biomass": {
                        "global": "0",
                        "heating": "0",
                        "refrigeration": "0",
                        "acs": "0",
                        "lighting": "0"
                    },
                    "biomass_others": {
                        "global": "0",
                        "heating": "0",
                        "refrigeration": "0",
                        "acs": "0",
                        "lighting": "0"
                    },
                    "peninsular_lighting": {
                        "global": "0.03",
                        "heating": "0",
                        "refrigeration": "0.03",
                        "acs": "0",
                        "lighting": "0"
                    },
                    "biofuel": {
                        "global": "0",
                        "heating": "0",
                        "refrigeration": "0",
                        "acs": "0",
                        "lighting": "0"
                    }
                }
            }
        ]
    },
    "meta": {
        "success": true,
        "errors": []
    }
}`
                                                                }
                                                            </SyntaxHighlighter>
                                                        </TabPane>
                                                        <TabPane tabId="iconTabs17">
                                                            <h3>Url</h3>
                                                            <p>Búsqueda de certificados por referencia catastral.</p>
                                                            <SyntaxHighlighter
                                                                language={"json"}
                                                                style={dark}
                                                            >
                                                                {
                                                                    `GET api/la-rioja/energy-certificate/{rc}`
                                                                }
                                                            </SyntaxHighlighter>
                                                            <h3>Request</h3>
                                                            <SyntaxHighlighter
                                                                language={"json"}
                                                                style={dark}
                                                            >
                                                                {
                                                                    `{

}`
                                                                }
                                                            </SyntaxHighlighter>

                                                            <h3>Response</h3>
                                                            <SyntaxHighlighter
                                                                language={"json"}
                                                                style={dark}
                                                            >
                                                                {
                                                                    `{
    "data": {
        "certificate": [
            {
                "rc": "000100300WM49A0001PE",
                "emission_date": "2023-08-28 15:16:41.000",
                "locale": "ENTRENA",
                "building_use": "30",
                "building_type_d": "Unifamiliar",
                "building_type": "Unifamiliar",
                "coordinate_y": 4691982,
                "coordinate_x": 540028,
                "emissions_CO2_ranking": "F",
                "consumption_norenov_ranking": "F",
                "address": "Calle ENTRENA 1 Es:T Pl:OD Pt:OS , ENTRENA",
                "door": "OS",
                "num_street": "OS",
                "street": "ENTRENA",
                "type_street": "CL",
                "primary_energy_consumption": 26376,
                "year_kwh_m2": 471,
                "year_CO2_m2": 123,
                "stair": "T",
                "registration_type_d": "Edificio Existente",
                "year": 1986,
                "num_municipality": "059",
                "num_province": "26",
                "num_country": "072",
                "flat": "OD",
                "letter": "",
                "climatic_zone": "D2",
                "registration_type": "EE",
                "util_surface_m2": 66,
                "global_emission_co2": 6888,
                "magnitude": ""
            }
        ]
    },
    "meta": {
        "success": true,
        "errors": []
    }
}`
                                                                }
                                                            </SyntaxHighlighter>

                                                            <h3>Url</h3>
                                                            <p>Búsqueda de certificados por filtros.</p>
                                                            <SyntaxHighlighter
                                                                language={"json"}
                                                                style={dark}
                                                            >
                                                                {
                                                                    `POST api/la-rioja/energy-certificate/search`
                                                                }
                                                            </SyntaxHighlighter>
                                                            <h3>Request</h3>
                                                            <p>
                                                                Filtrar por la letra de consumo:
                                                                <ul>
                                                                    <li><strong>ranking_consumption_letter:</strong> A,
                                                                        B, C, D, E, F
                                                                    </li>
                                                                </ul>
                                                                Filtrar por la letra de emisiones:
                                                                <ul>
                                                                    <li><strong>ranking_emissions_letter:</strong> A, B,
                                                                        C, D, E, F
                                                                    </li>
                                                                </ul>
                                                                Filtrar por la dirección:
                                                                <ul>
                                                                    <li><strong>address:</strong> Praga, 9,</li>
                                                                </ul>
                                                                Filtrar por la página:
                                                                <ul>
                                                                    <li><strong>page:</strong> 1</li>
                                                                </ul>
                                                            </p>
                                                            <SyntaxHighlighter
                                                                language={"json"}
                                                                style={dark}
                                                            >
                                                                {
                                                                    `{
    "ranking_consumption_letter":"D",
    "ranking_emissions_letter":"C",
    "address": "Calle ENTRENA 1",
    "page": 1
}`
                                                                }
                                                            </SyntaxHighlighter>
                                                            <h3>Response</h3>
                                                            <SyntaxHighlighter
                                                                language={"json"}
                                                                style={dark}
                                                            >
                                                                {
                                                                    `{
    "data": {
        "certificate": [
            {
                "rc": "000100300WM49A0001PE",
                "emission_date": "2023-08-28 15:16:41.000",
                "locale": "ENTRENA",
                "building_use": "30",
                "building_type_d": "Unifamiliar",
                "building_type": "Unifamiliar",
                "coordinate_y": 4691982,
                "coordinate_x": 540028,
                "emissions_CO2_ranking": "F",
                "consumption_norenov_ranking": "F",
                "address": "Calle ENTRENA 1 Es:T Pl:OD Pt:OS , ENTRENA",
                "door": "OS",
                "num_street": "OS",
                "street": "ENTRENA",
                "type_street": "CL",
                "primary_energy_consumption": 26376,
                "year_kwh_m2": 471,
                "year_CO2_m2": 123,
                "stair": "T",
                "registration_type_d": "Edificio Existente",
                "year": 1986,
                "num_municipality": "059",
                "num_province": "26",
                "num_country": "072",
                "flat": "OD",
                "letter": "",
                "climatic_zone": "D2",
                "registration_type": "EE",
                "util_surface_m2": 66,
                "global_emission_co2": 6888,
                "magnitude": ""
            }
        ]
    },
    "meta": {
        "success": true,
        "errors": []
    }
}`
                                                                }
                                                            </SyntaxHighlighter>
                                                        </TabPane>
                                                        <TabPane tabId="iconTabs18">
                                                            <h3>Url</h3>
                                                            <p>Búsqueda de certificados por referencia catastral.</p>
                                                            <SyntaxHighlighter
                                                                language={"json"}
                                                                style={dark}
                                                            >
                                                                {
                                                                    `GET api/valencia/energy-certificate/{rc}`
                                                                }
                                                            </SyntaxHighlighter>
                                                            <h3>Request</h3>
                                                            <SyntaxHighlighter
                                                                language={"json"}
                                                                style={dark}
                                                            >
                                                                {
                                                                    `{

}`
                                                                }
                                                            </SyntaxHighlighter>

                                                            <h3>Response</h3>
                                                            <SyntaxHighlighter
                                                                language={"json"}
                                                                style={dark}
                                                            >
                                                                {
                                                                    `{
    "data": {
        "certificate": [
            {
                "register_number": "N2012VX000244",
                "register_type": "NUEVO",
                "expired_date": "2029-03-15",
                "year": 2012,
                "building_use": "VIVIENDA/S UNIFAMILIAR",
                "address": "NOU 27 - , 03780, PEGO",
                "municipality": "PEGO",
                "province": "ALICANTE",
                "postal_code": 3780,
                "rc": "0231205YJ5003S0001PZ",
                "ranking_consumption": "C",
                "consumption": 64,
                "ranking_emissions": "C",
                "emissions": 12
            }
        ]
    },
    "meta": {
        "success": true,
        "errors": []
    }
}`
                                                                }
                                                            </SyntaxHighlighter>

                                                            <h3>Url</h3>
                                                            <p>Búsqueda de certificados por filtros.</p>
                                                            <SyntaxHighlighter
                                                                language={"json"}
                                                                style={dark}
                                                            >
                                                                {
                                                                    `POST api/valencia/energy-certificate/search`
                                                                }
                                                            </SyntaxHighlighter>
                                                            <h3>Request</h3>
                                                            <p>
                                                                Filtrar por la letra de consumo:
                                                                <ul>
                                                                    <li><strong>ranking_consumption_letter:</strong> A,
                                                                        B, C, D, E, F
                                                                    </li>
                                                                </ul>
                                                                Filtrar por la letra de emisiones:
                                                                <ul>
                                                                    <li><strong>ranking_emissions_letter:</strong> A, B,
                                                                        C, D, E, F
                                                                    </li>
                                                                </ul>
                                                                Filtrar por la dirección:
                                                                <ul>
                                                                    <li><strong>address:</strong> Praga, 9,</li>
                                                                </ul>
                                                                Filtrar por código postal:
                                                                <ul>
                                                                    <li><strong>postal_code:</strong> 03158</li>
                                                                </ul>
                                                                Filtrar por número de registro:
                                                                <ul>
                                                                    <li><strong>register_number:</strong> N2012VX000244
                                                                    </li>
                                                                </ul>
                                                                Filtrar por la página:
                                                                <ul>
                                                                    <li><strong>page:</strong> 1</li>
                                                                </ul>
                                                            </p>
                                                            <SyntaxHighlighter
                                                                language={"json"}
                                                                style={dark}
                                                            >
                                                                {
                                                                    `{
    "ranking_consumption_letter":"D",
    "ranking_emissions_letter":"C",
    "register_number": "N2012VX000244"
    "postal_code": "03158",
    "address": "NOU 27 ",
    "page": 1
}`
                                                                }
                                                            </SyntaxHighlighter>
                                                            <h3>Response</h3>
                                                            <SyntaxHighlighter
                                                                language={"json"}
                                                                style={dark}
                                                            >
                                                                {
                                                                    `{
    "data": {
        "certificate": [
            {
                "register_number": "N2012VX000244",
                "register_type": "NUEVO",
                "expired_date": "2029-03-15",
                "year": 2012,
                "building_use": "VIVIENDA/S UNIFAMILIAR",
                "address": "NOU 27 - , 03780, PEGO",
                "municipality": "PEGO",
                "province": "ALICANTE",
                "postal_code": 3780,
                "rc": "0231205YJ5003S0001PZ",
                "ranking_consumption": "C",
                "consumption": 64,
                "ranking_emissions": "C",
                "emissions": 12
            }
        ]
    },
    "meta": {
        "success": true,
        "errors": []
    }
}`
                                                                }
                                                            </SyntaxHighlighter>
                                                        </TabPane>
                                                        <TabPane tabId="iconTabs19">
                                                            <h3>Próximamente</h3>
                                                        </TabPane>
                                                        <TabPane tabId="iconTabs20">
                                                            <h3>Próximamente</h3>
                                                        </TabPane>
                                                    </TabContent>
                                                </CardBody>
                                            </Card>
                                        </Row>
                                    </Container>
                                </section>

                            </div>
                        </main>
                        <SimpleFooter/>
                    </CookiesProvider>
                </ModalProvider>

            </>);
    }
}

export default Documentation;