import React from "react";
import CertificateLetter from "./Letters/CertificateLetter";

const CertificateExtremadura = ({certificate}) => {

    return (
        <>
            <div className="card-body">
                <p><strong>Fecha</strong>: {certificate.date}</p>
                <p><strong>Dirección</strong>: {certificate.address}</p>
                <p><strong>Municipio</strong>: {certificate.municipality}</p>
                <p><strong>Codigo postal</strong>: {certificate.postal_code}</p>
                <p><strong>Consumo</strong>: {certificate.energy_primary_consumption}</p>
                <p><strong>Emisiones </strong>: {certificate.emissions_co2}</p>
                <p><strong>Classificación consumo </strong>: <CertificateLetter
                    letter={certificate.rating_primary_energy_consumption}/></p>
                <p><strong>Classificación emisiones </strong>:<CertificateLetter
                    letter={certificate.rating_emissions_co2}/></p>
            </div>
        </>

    );
}


export default CertificateExtremadura;