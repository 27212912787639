import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import {HelmetProvider} from "react-helmet-async";
import Landing from "./examples/Landing";
import PolicyCookies from "./examples/PolicyCookies";
import PolicyPrivacy from "./examples/PolicyPrivacy";
import Prices from "./examples/Prices";
import Faqs from "./examples/Faqs";
import TermsConditions from "./examples/TermsConditions";
import Documentation from "./examples/Documentation";
import Sitemap from "./examples/Sitemap";
import NotFoundPage from "./examples/NotFoundPage";
import React from "react";


function RoutesPath() {
    return (
        <HelmetProvider>
            <Router>
                <Routes>
                    <Route path="/" exact element={<Landing/>}/>
                    <Route path="/politica-de-cookies" exact element={<PolicyCookies/>}/>
                    <Route path="/politica-de-privacidad" exact element={<PolicyPrivacy/>}/>
                    <Route path="/suscripciones" exact element={<Prices/>}/>
                    <Route path="/faqs" exact element={<Faqs/>}/>
                    <Route path="/terminos-y-condiciones" exact element={<TermsConditions/>}/>
                    <Route path="/documentacion" exact element={<Documentation/>}/>
                    <Route path="/sitemap.xml" exact element={<Sitemap/>}/>
                    <Route path="*" exact element={<NotFoundPage/>}/>
                </Routes>
            </Router>
        </HelmetProvider>
    );
}

export default RoutesPath;