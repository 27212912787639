import axios from 'axios';
import {apiURL} from "../Libs/methods";

export const sendFormCatastralReference = (data, dispatch) => {
    console.log(data);
    return axios
        .post(`${apiURL()}/api/search`, data)
        .then(res => {
            if (res.data.meta.success) {
                dispatch({type: 'SET_RC_DATA', mapData: res.data.data})
            } else {
                dispatch({type: 'SET_RC_DATA', mapData: []})
            }
        })
        .catch(err => {
            dispatch({type: 'SET_RC_DATA', mapData: []})
        })
}

export const getStatesUpdated = () => {

    return new Promise((response, error) => {
        return axios
            .get(`${apiURL()}/api/update-state`)
            .then(res => {
                if (res.data.meta.success) {
                    console.log('success');
                    response({error: false, data: res.data.data.dateCertificatesUpdated})
                } else {
                    console.log('error');
                    error({error: true, data: []})
                }
            })
            .catch(err => {
                error({error: true, data: []})
            })
    })
}
