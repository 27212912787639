import React, {useState} from "react";

const ModalContext = React.createContext();

export function ModalProvider(props) {
    const [modal, setModal] = useState({
        cookies: false,
        modalOpen: false
    })

    const [showStiker, setShowStiker] = useState(true);

    async function openModal(action) {
        setModal(
            {
                cookies: action.modal === "cookies" ? true : false,
                modalOpen: true
            }
        )
    }

    async function closeModal() {
        setModal({
            modalOpen: false,
            cookies: false
        })
    }

    return (
        <ModalContext.Provider
            value={{
                modal,
                setShowStiker,
                showStiker,
                openModal,
                closeModal
            }}
            {...props}
            />
    );
}

export function useModal() {
    const context = React.useContext(ModalContext);
    if (!context) {
        throw new Error("useModal debe estar dentro del proveedor ModelContext");
    }
    return context;
}