import React from "react";
import {Alert} from "reactstrap";
import CertificateMadrid from "./CertificateMadrid";
import CertificateCatalunya from "./CertificateCatalunya";
import CertificateAndalucia from "./CertificateAndalucia";
import CertificateAragon from "./CertificateAragon";
import CertificateAsturias from "./CertificateAsturias";
import CertificateCanarias from "./CertificateCanarias";
import CertificateBaleares from "./CertificateBaleares";
import CertificateCantabria from "./CertificateCantabria";
import CertificateCastillaLaMancha from "./CertificateCastillaLaMancha";
import CertificateCastillaLeon from "./CertificateCastillaLeon";
import CertificateExtremadura from "./CertificateExtremadura";
import CertificateGalicia from "./CertificateGalicia";
import CertificateMurcia from "./CertificateMurcia";
import CertificateNavarra from "./CertificateNavarra";
import CertificatePaisVasco from "./CertificatePaisVasco";
import CertificateLaRioja from "./CertificateLaRioja";
import CertificateValencia from "./CertificateValencia";

const Certificate = ({certificate, state}) => {


    return (
        <>
        <div className={'justify-content-center row'}>
            <div className="mt-5 col-lg-12">
                <div className={'row-grid row'}>
                    {certificate.meta.success && state === 'andalucia' &&
                        certificate.data.certificate.map((key, value) => (
                            <div className={'col-lg-4 mb-5'}>
                                <div className={'card-lift--hover shadow border-0 card'}>
                                    <CertificateAndalucia certificate={key}/>
                                </div>

                            </div>
                        ))
                    }

                    {certificate.meta.success && state === 'aragon' &&
                        certificate.data.certificate.map((key, value) => (
                            <div className={'col-lg-4 mb-5'}>
                                <div className={'card-lift--hover shadow border-0 card'}>
                                    <CertificateAragon certificate={key}/>
                                </div>

                            </div>
                        ))
                    }

                    {certificate.meta.success && state === 'asturias' &&
                        certificate.data.certificate.map((key, value) => (
                            <div className={'col-lg-4 mb-5'}>
                                <div className={'card-lift--hover shadow border-0 card'}>
                                    <CertificateAsturias certificate={key}/>
                                </div>

                            </div>
                        ))
                    }

                    {certificate.meta.success && state === 'baleares' &&
                        certificate.data.certificate.map((key, value) => (
                            <div className={'col-lg-4 mb-5'}>
                                <div className={'card-lift--hover shadow border-0 card'}>
                                    <CertificateBaleares certificate={key}/>
                                </div>

                            </div>
                        ))
                    }

                    {certificate.meta.success && state === 'canarias' &&
                        certificate.data.certificate.map((key, value) => (
                            <div className={'col-lg-4 mb-5'}>
                                <div className={'card-lift--hover shadow border-0 card'}>
                                    <CertificateCanarias certificate={key}/>
                                </div>

                            </div>
                        ))
                    }

                    {certificate.meta.success && state === 'castilla-la-mancha' &&
                        certificate.data.certificate.map((key, value) => (
                            <div className={'col-lg-4 mb-5'}>
                                <div className={'card-lift--hover shadow border-0 card'}>
                                    <CertificateCastillaLaMancha certificate={key}/>
                                </div>

                            </div>
                        ))
                    }

                    {certificate.meta.success && state === 'cantabria' &&
                        certificate.data.certificate.map((key, value) => (
                            <div className={'col-lg-4 mb-5'}>
                                <div className={'card-lift--hover shadow border-0 card'}>
                                    <CertificateCantabria certificate={key}/>
                                </div>

                            </div>
                        ))
                    }

                    {certificate.meta.success && state === 'castilla-y-leon' &&
                        certificate.data.certificate.map((key, value) => (
                            <div className={'col-lg-4 mb-5'}>
                                <div className={'card-lift--hover shadow border-0 card'}>
                                    <CertificateCastillaLeon certificate={key}/>
                                </div>

                            </div>
                        ))
                    }

                    {certificate.meta.success && state === 'madrid' &&
                        certificate.data.certificate.map((key, value) => (
                            <div className={'col-lg-4 mb-5'}>
                                <div className={'card-lift--hover shadow border-0 card'}>
                                    <CertificateMadrid certificate={key}/>
                                </div>

                            </div>
                        ))
                    }

                    {certificate.meta.success && state === 'catalunya' &&
                        certificate.data.certificate.map((key, value) => (
                            <div className={'col-lg-4 mb-5'}>
                                <div className={'card-lift--hover shadow border-0 card'}>
                                    <CertificateCatalunya certificate={key}/>
                                </div>
                            </div>
                        ))
                    }

                    {certificate.meta.success && state === 'extremadura' &&
                        certificate.data.certificate.map((key, value) => (
                            <div className={'col-lg-4 mb-5'}>
                                <div className={'card-lift--hover shadow border-0 card'}>
                                    <CertificateExtremadura certificate={key}/>
                                </div>
                            </div>
                        ))
                    }

                    {certificate.meta.success && state === 'galicia' &&
                        certificate.data.certificate.map((key, value) => (
                            <div className={'col-lg-4 mb-5'}>
                                <div className={'card-lift--hover shadow border-0 card'}>
                                    <CertificateGalicia certificate={key}/>
                                </div>
                            </div>
                        ))
                    }

                    {certificate.meta.success && state === 'murcia' &&
                        certificate.data.certificate.map((key, value) => (
                            <div className={'col-lg-4 mb-5'}>
                                <div className={'card-lift--hover shadow border-0 card'}>
                                    <CertificateMurcia certificate={key}/>
                                </div>
                            </div>
                        ))
                    }

                    {certificate.meta.success && state === 'navarra' &&
                        certificate.data.certificate.map((key, value) => (
                            <div className={'col-lg-4 mb-5'}>
                                <div className={'card-lift--hover shadow border-0 card'}>
                                    <CertificateNavarra certificate={key}/>
                                </div>
                            </div>
                        ))
                    }

                    {certificate.meta.success && state === 'pais-vasco' &&
                        certificate.data.certificate.map((key, value) => (
                            <div className={'col-lg-4 mb-5'}>
                                <div className={'card-lift--hover shadow border-0 card'}>
                                    <CertificatePaisVasco certificate={key}/>
                                </div>
                            </div>
                        ))
                    }

                    {certificate.meta.success && state === 'la-rioja' &&
                        certificate.data.certificate.map((key, value) => (
                            <div className={'col-lg-4 mb-5'}>
                                <div className={'card-lift--hover shadow border-0 card'}>
                                    <CertificateLaRioja certificate={key}/>
                                </div>
                            </div>
                        ))
                    }

                    {certificate.meta.success && state === 'valencia' &&
                        certificate.data.certificate.map((key, value) => (
                            <div className={'col-lg-4 mb-5'}>
                                <div className={'card-lift--hover shadow border-0 card'}>
                                    <CertificateValencia certificate={key}/>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
        </div>
            {
                certificate.data.certificate.length === 0 &&
                <Alert className="alert-danger">No se ha encontrado certificado</Alert>
            }
        </>
    );
}


export default Certificate;