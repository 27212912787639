import React from "react";


const StateCard = ({image, name, date}) => {

    return (
        <>
            <div className="px-4">
                <img
                    alt={name}
                    className="rounded-circle img-center img-fluid shadow shadow-lg--hover"
                    src={image}
                    style={{width: "200px"}}
                />
                <div className="pt-4 text-center">
                    <h5 className="title">
                        <span className="d-block mb-1">{name}</span>
                        <small className="h6 text-muted">
                            {date}
                        </small>
                    </h5>
                </div>
            </div>
        </>
    );
}

export default StateCard;