import React, {useEffect, useRef, useState} from "react";
import {Alert, Button, Form, FormGroup, FormText, InputGroup, InputGroupText} from "reactstrap";
import classnames from "classnames";
import {useForm} from "react-hook-form";
import axios from "axios";
import {apiURL} from "../Libs/methods";
import Loading from "../Elements/Loading";


const Contact = () => {
    const {handleSubmit, register, formState: {errors}} = useForm();
    const [responseData, setResponseData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [disabledButton, setDisabledButton] = useState(false);
    let tokenRecaptcha = useRef('');

    function getRecaptcha()  {
       window.grecaptcha.ready(function () {
            window.grecaptcha.execute(process.env.REACT_APP_API_KEY_RECAPTCHA, {
                action: "contact"
            }).then(function (token) {
                tokenRecaptcha.current = token;
            })
        })
    }

    const onSubmit = async (data) => {
        setLoading(true);
        setResponseData(null);
        setDisabledButton(true);
        getRecaptcha();
        setTimeout(async () => {
            try {
                const formData = {
                    name: data.name,
                    email: data.email,
                    reason: data.reason,
                    message: data.message,
                    gRecaptchaResponse: tokenRecaptcha.current
                };

                const response = await axios.post(`${apiURL()}/api/contact`, formData);

                setResponseData(response.data.meta.success);

                if (response.data?.meta.success) {
                    document.getElementById("form-contact").reset();
                }
            } catch (error) {
                setResponseData(false);
            } finally {
                setLoading(false);
            }
            setDisabledButton(false);
        }, 3000);

    };

    useEffect(() => {
        if (!document.getElementById('recaptchat-EnergyCertiificate')) {
            const script = document.createElement('script');
            script.src = `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_API_KEY_RECAPTCHA}`;
            script.id = 'recaptchat-EnergyCertiificate';
            document.body.appendChild(script);
        }
    }, []);

    return (

        <>
            {responseData && <Alert closeAriaLabel={'Close'}>
                Se ha enviado correctamente, gracias por contactar con nosotros.
            </Alert>}
            {responseData === false && <Alert color="danger" closeAriaLabel={'Close'}>
                Error al enviar el formulario, por favor vuelve a intentarlo mas tarde.
            </Alert>}
            {loading && <Loading/>}
            <Form onSubmit={handleSubmit(onSubmit)} id="form-contact">
                <FormGroup
                    className={classnames("mt-5")}
                >
                    <InputGroup className="input-group-alternative">
                        <div className="input-group-append">
                            <InputGroupText>
                                <i className="ni ni-user-run"/>
                            </InputGroupText>
                        </div>
                        <input
                            placeholder="Nombre"
                            type="text"
                            className="basic-single form-control"
                            {...register('name', {required: true})}
                        />
                    </InputGroup>
                    {errors.name?.type === 'required' &&
                        <FormText color="danger">
                            El nombre es obligatorio
                        </FormText>
                    }
                </FormGroup>
                <FormGroup>
                    <InputGroup className="input-group-alternative">
                        <div className="input-group-append">
                            <InputGroupText>
                                <i className="ni ni-email-83"/>
                            </InputGroupText>
                        </div>
                        <input
                            placeholder="Dirección de correo"
                            type="email"
                            className="basic-single form-control"
                            {...register('email', {required: true})}
                        />
                    </InputGroup>
                    {errors.email?.type === 'required' &&
                        <FormText color="danger">
                            El email es obligatorio
                        </FormText>
                    }
                </FormGroup>
                <FormGroup>
                    <InputGroup className="input-group-alternative">
                        <div className="input-group-append">
                            <InputGroupText>
                                <i className="ni ni-briefcase-24"/>
                            </InputGroupText>
                        </div>
                        <label htmlFor="reason-select" className="sr-only"></label>
                        <select
                            id="reason-select"
                            placeholder="Dirección de correo"
                            className="basic-single form-control"
                            {...register('reason', {required: true})}
                        >
                            <option value="">Selecciona un motivo</option>
                            <option value="more_info">Más información</option>
                            <option value="buy_api">Me interesa el ApiRest</option>
                            <option value="i_want_my_own_api">Quiero mi propia ApiRest</option>
                            <option value="report_errors">Informar de un problema</option>
                            <option value="others">Otros</option>
                        </select>
                    </InputGroup>
                    {errors.reason?.type === 'required' &&
                        <FormText color="danger">
                            El motivo es obligatorio
                        </FormText>
                    }
                </FormGroup>
                <FormGroup className="mb-4">
                <textarea
                    className="form-control form-control-alternative"
                    cols="80"
                    name="message"
                    placeholder="Escribe un mensaje"
                    rows="4"
                    style={{"resize": "vertical"}}
                    {...register('message', {required: true})}
                />
                    {errors.message?.type === 'required' &&
                        <FormText color="danger">
                            El mensaje es obligatorio
                        </FormText>
                    }
                </FormGroup>
                <div>
                    <Button
                        block
                        className="btn-round"
                        color="default"
                        size="lg"
                        type="submit"
                        disabled={disabledButton}
                        style={{"background-color": "darkviolet", "border-color": "darkviolet"}}
                    >
                        Enviar
                    </Button>
                </div>
            </Form>
        </>);
};

export default Contact;