import React from "react";
import CertificateLetter from "./Letters/CertificateLetter";

const CertificateBaleares = ({certificate}) => {

    return (
        <>
            <div className="card-body">
                <p><strong>Fecha</strong>: {certificate.date}</p>
                <p><strong>Número de fichero</strong>: {certificate.num_file}</p>
                <p><strong>Dirección</strong>: {certificate.address}</p>
                <p><strong>Municipio</strong>: {certificate.municipality}</p>
                <p><strong>Codigo postal</strong>: {certificate.postal_code}</p>
                <p><strong>Consumo</strong>: {certificate.consumption}</p>
                <p><strong>Emisiones </strong>: {certificate.emission_co2}</p>
                <p><strong>Classificación </strong>: <CertificateLetter
                    letter={certificate.ranking}/></p>
            </div>
        </>

    );
}


export default CertificateBaleares;