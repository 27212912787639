import React from "react";
import CertificateLetter from "./Letters/CertificateLetter";

const CertificateLaRioja = ({certificate}) => {

    return (
        <>
            <div className="card-body">
                <p><strong>Fecha</strong>: {certificate.emission_date}</p>
                <p><strong>Dirección</strong>: {certificate.address}</p>
                <p><strong>Zona climatica</strong>: {certificate.climatic_zone}</p>
                <p><strong>Consumo</strong>: {certificate.primary_energy_consumption}</p>
                <p><strong>Emisiones </strong>: {certificate.global_emission_co2}</p>
                <p><strong>Classificación consumo </strong>: <CertificateLetter
                    letter={certificate.consumption_norenov_ranking}/></p>
                <p><strong>Classificación emisiones </strong>:<CertificateLetter
                    letter={certificate.emissions_CO2_ranking}/></p>
            </div>
        </>

    );
}


export default CertificateLaRioja;