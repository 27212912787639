import React from "react";
import CertificateLetter from "./Letters/CertificateLetter";

const CertificateCanarias = ({certificate}) => {

    return (
        <>
            <div className="card-body">
                <p><strong>Fecha</strong>: {certificate.register_date}</p>
                <p><strong>Número de registro</strong>: {certificate.num_register}</p>
                <p><strong>Dirección</strong>: {certificate.address}</p>
                <p><strong>Municipio</strong>: {certificate.municipality}</p>
                <p><strong>Zona climatica</strong>: {certificate.climatic_zone}</p>
                <p><strong>Consumo</strong>: {certificate.consumption}</p>
                <p><strong>Emisiones </strong>: {certificate.emission}</p>
                <p><strong>Classificación consumo </strong>: <CertificateLetter
                    letter={certificate.ranking_consumption}/></p>
                <p><strong>Classificación emisiones </strong>:<CertificateLetter
                    letter={certificate.ranking_emission}/></p>
            </div>
        </>

    );
}


export default CertificateCanarias;