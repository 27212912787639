import React from "react";
import CertificateLetter from "./Letters/CertificateLetter";

const CertificateMadrid = ({certificate}) => {

    return (
        <>
            <div className="card-body">
                <p><strong>Fecha</strong>: {certificate.date}</p>
                <p><strong>Codigo de registro</strong>: {certificate.register_code}</p>
                <p><strong>Dirección</strong>: {certificate.address}</p>
                <p><strong>Municipio</strong>: {certificate.municipality}</p>
                <p><strong>Codigo postal</strong>: {certificate.postal_code}</p>
                <p><strong>Zona climatica</strong>: {certificate.climatic_zone}</p>
                <p><strong>Consumo global</strong>: {certificate.final_global}</p>
                <p><strong>Consumo energia no renovable</strong>: {certificate.energy_no_renewable_global}</p>
                <p><strong>Emisiones </strong>: {certificate.energy_no_renewable_emissions_co2_global}</p>
                <p><strong>Classificación consumo </strong>: <CertificateLetter
                    letter={certificate.ranking_no_renewable_global}/></p>
                <p><strong>Classificación emisiones </strong>:<CertificateLetter
                    letter={certificate.ranking_emissions_co2_global}/></p>
            </div>
        </>

    );
}


export default CertificateMadrid;