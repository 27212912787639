import React from "react";
import CertificateLetter from "./Letters/CertificateLetter";

const CertificateNavarra = ({certificate}) => {

    return (
        <>
            <div className="card-body">
                <p><strong>Fecha</strong>: {certificate.date}</p>
                <p><strong>Codigo de registro</strong>: {certificate.register_code}</p>
                <p><strong>Dirección</strong>: {certificate.address}</p>
                <p><strong>Municipio</strong>: {certificate.municipality}</p>
                <p><strong>Codigo postal</strong>: {certificate.postal_code}</p>
                <p><strong>Zona climatica</strong>: {certificate.zone}</p>
                <p><strong>Consumo</strong>: {certificate.no_renovable_primary_energy.GLOBAL}</p>
                <p><strong>Emisiones </strong>: {certificate.emissions_co2.GLOBAL}</p>
                <p><strong>Classificación consumo </strong>: <CertificateLetter
                    letter={certificate.
                        ranking_energy_no_renewable_primary_global}/></p>
                <p><strong>Classificación emisiones </strong>:<CertificateLetter
                    letter={certificate.ranking_emission_co2_global}/></p>
            </div>
        </>

    );
}


export default CertificateNavarra;