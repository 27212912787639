import React, {useEffect, useState} from 'react';
import {Swiper, SwiperSlide} from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import {Autoplay, Pagination} from 'swiper/modules';
import StateCard from "../Cards/StateCard";
import './SliderState.css';
import {getStatesUpdated} from "../Actions/methods";
import Loading from "../Elements/Loading";

const SliderState = () => {
    const [apiStates, setApiStates] = useState([])

    async function getData() {
        getStatesUpdated().then((response) => {
            if (!response.error) {
                setApiStates(response.data);
            }
        });
    }

    useEffect(() => {
        getData();
    }, []);

    return (

        <>
            {(apiStates?.length > 0) ?
                <Swiper
                    pagination={{
                        dynamicBullets: true,
                        clickable: true,
                    }}
                    modules={
                        [Autoplay, Pagination]
                    }
                    className="mySwiper"
                    spaceBetween={30}
                    grabCursor={true}
                    autoplay={{
                        delay: 2500,
                        disableOnInteraction: false,
                    }}
                    breakpoints={{
                        640: {
                            //width: 640,
                            slidesPerView: 1,
                        },
                        // when window width is >= 768px
                        768: {
                            //width: 768,
                            slidesPerView: 4,
                        }
                    }}>
                    {apiStates.map((value) => (
                    <SwiperSlide>

                            <StateCard image={require('assets/img/theme/state/' + value.image + '.jpg')}
                                       name={value.name}
                                       date={value.date}/>

                    </SwiperSlide>
                    ))}

                </Swiper> : <Loading></Loading>
            }
        </>
    );
}

export default SliderState;