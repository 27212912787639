import {Button, Form, FormGroup, FormText, InputGroup, InputGroupText} from "reactstrap";
import classnames from "classnames";
import React, {useEffect, useRef, useState} from "react";
import {useForm} from "react-hook-form";
import {apiURL} from "../Libs/methods";
import axios from "axios";
import Loading from "../Elements/Loading";
import Certificate from "../Certificates/Certificate";

const SearchRc = () => {
    const {handleSubmit, register, formState: {errors}} = useForm();
    const [responseData, setResponseData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [state, setState] = useState(null);
    const [disabledButton, setDisabledButton] = useState(false);
    let tokenRecaptcha = useRef('');

    function getRecaptcha() {
        window.grecaptcha.ready(function () {
            window.grecaptcha.execute(process.env.REACT_APP_API_KEY_RECAPTCHA, {
                action: "search"
            }).then(function (token) {
                tokenRecaptcha.current = token;
            })
        })
    }

    const onSubmit = async (data) => {
        getRecaptcha();
        setDisabledButton(true);
        setLoading(true);
        setResponseData(null);
        setTimeout(async () => {

            try {
                const formData = {
                    state: data.state,
                    rc: data.rc,
                    gRecaptchaResponse: tokenRecaptcha.current
                };

                setState(data.state);
                const response = await axios.post(`${apiURL()}/api/search`, formData);

                console.log('Respuesta del servidor:', response.data);
                setResponseData(response.data);
            } catch (error) {
                //Controlarlo
            } finally {
                setLoading(false);
            }
            setDisabledButton(false);
        }, 3000);
    };

    useEffect(() => {
        if (!document.getElementById('recaptchat-EnergyCertiificate')) {
            const script = document.createElement('script');
            script.src = `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_API_KEY_RECAPTCHA}`;
            script.id = 'recaptchat-EnergyCertiificate';
            document.body.appendChild(script);
        }
    }, []);

    return (

        <>
            <Form onSubmit={handleSubmit(onSubmit)}>
                <FormGroup
                    className={classnames("mt-5")}
                >
                    <label htmlFor="state-select" className="sr-only"></label>
                    <select
                        id="state-select"
                        className="basic-single form-control"
                        name="state"
                        {...register('state', {required: true})}
                    >
                        <option value="">Selecciona comunidad autónoma</option>
                        <option value="andalucia">Andalucía</option>
                        <option value="aragon">Aragón</option>
                        <option value="asturias">Asturias</option>
                        <option value="baleares">Baleares</option>
                        <option value="canarias">Canarias</option>
                        <option value="cantabria">Cantabria</option>
                        <option value="castilla-la-mancha">Castilla La Mancha</option>
                        <option value="castilla-y-leon">Castilla y León</option>
                        <option value="catalunya">Cataluña</option>
                        <option value="extremadura">Extremadura</option>
                        <option value="galicia">Galicia</option>
                        <option value="madrid">Madrid</option>
                        <option value="murcia">Murcia</option>
                        <option value="navarra">Navarra</option>
                        <option value="pais-vasco">Pais Vasco</option>
                        <option value="la-rioja">La Rioja</option>
                        <option value="valencia">Valencia</option>
                    </select>
                    {errors.state?.type === 'required' &&
                        <FormText color="danger">
                            Por favor, selecciona una comunidad autonoma
                        </FormText>
                    }
                </FormGroup>
                <FormGroup
                    className={classnames()}
                >
                    <InputGroup className="input-group-alternative">
                        <div className="input-group-append">
                            <InputGroupText>
                                <i className="ni ni-building"/>
                            </InputGroupText>
                        </div>
                        <input
                            className="basic-single form-control"
                            placeholder="Referencia catastral"
                            type="text"
                            name="rc"
                            {...register('rc', {required: true})}
                        />
                    </InputGroup>
                    {errors.rc?.type === 'required' &&
                        <FormText color="danger">
                            Por favor, escribe una referencia catastral.
                        </FormText>
                    }
                </FormGroup>

                <div>
                    <Button
                        block
                        className="btn-round"
                        color="default"
                        size="lg"
                        type="submit"
                        style={{"background-color": "darkviolet", "border-color": "darkviolet"}}
                        disabled={disabledButton}
                    >
                        Buscar
                    </Button>
                </div>
            </Form>
            {loading && <Loading/>}
            {responseData && <Certificate certificate={responseData} state={state}/>}

        </>
    )
}

export default SearchRc;
