import React from "react";
import {Label} from "reactstrap";
import './CertificateLetterStyles.css';

const CertificateLetter = ({letter}) => {

    let color = '';
    let labelClass = '';
    function letterColor(letter) {
        if(letter === 'A') {
            color = 'darkgreen';
            labelClass = 'badge badge-energetic-a';
        }else if(letter === 'B') {
            color = 'green';
            labelClass = 'badge badge-energetic-b';
        }else if(letter === 'C') {
            color = 'yellowgreen';
            labelClass = 'badge badge-energetic-c';
        } else if(letter === 'D') {
            color = 'yellow';
            labelClass = 'badge badge-energetic-d';
        } else if (letter === 'E') {
            color = 'orange';
            labelClass = 'badge badge-energetic-e';
        } else if (letter === 'F') {
            color = 'darkorange';
            labelClass = 'badge badge-energetic-f';
        } else if (letter === 'G'){
            color = 'red';
            labelClass = 'badge badge-energetic-g';
        }
    }
    letterColor(letter);
    return (
            <Label
                className={labelClass}
                color={color}
            >{letter}</Label>
    );
}


export default CertificateLetter;