import React from "react";

class Sitemap extends React.Component {
    componentDidMount() {
        document.documentElement.scrollTop = 0;
        document.scrollingElement.scrollTop = 0;
        this.refs.main.scrollTop = 0;
    }
    render() {
        return (
            <div>
                <a href="/sitemap.xml">Abrir archivo</a>
            </div>
        );
    }
}

export default Sitemap;
