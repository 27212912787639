import React, {Suspense, useEffect} from "react";

import {useModal} from "../Hooks/modal";
import {useCookies} from "../Hooks/cookies";
import {Helmet} from "react-helmet-async";
import {useLocation} from "react-router-dom";
import Loading from "../Elements/Loading";
import {pushDataLayerInit} from "../Actions/dataleyer";

const CookiesComponent = React.lazy(() => import('../Cookies'));
const Metas = ({title, description, keys, pageName}) => {

    const {modal, closeModal, openModal} = useModal();
    const {funcional, analitica, publicidad} = useCookies();
    const location = useLocation();

    useEffect(() => {
        if (!funcional) {
            openModal({modal: 'cookies'})
        }

        if (analitica) {
            //TODO Añadir funcion para agregar los scripts
            pushDataLayerInit()

        }
    }, [analitica, location.pathname]);

    useEffect(() => {
        if (modal.modalOpen) {
            closeModal()
        }
    }, [location.pathname]);

    return (
        <>
            <Helmet>
                {analitica && (
                    <script async src="https://www.googletagmanager.com/gtag/js?id=G-30YDD43156"></script>
                )}
                {funcional && (
                    <script></script>
                )}
                {publicidad && (
                    <script></script>
                )}
                <meta charSet="utf-8" />
                <meta
                    name="viewport"
                    content="width=device-width, initial-scale=1, shrink-to-fit=no"
                />
                <link rel="canonical" href={"https://energycertiificate.com/"+pageName}/>
                <meta name="theme-color" content="#000000" />
                <meta name="msapplication-TileColor" content="#ffffff"/>
                <meta name="msapplication-TileImage" content="/ms-icon-144x144.png"/>
                <meta name="theme-color" content="#ffffff"/>
                <title>{title}</title>
                <meta name="description" property="og:description"  content={description} data-rh="true"/>
                <meta name="keywords" content={keys} data-rh="true"/>
                <meta name={"locale"} property="og:locale" content="es_ES" data-rh="true"/>
                <meta name={"title"} property="og:title" content={title} data-rh="true"/>
                <meta name={"url"} property="og:url" content={"https://energycertiificate.com/"+pageName} data-rh="true"/>
                <meta name={"site_name"} property="og:site_name" content="EnergyCertiificate" data-rh="true"/>
                <meta name={"image"} property="og:image"
                      content={require("assets/img/meta/energy.webp")} data-rh="true"/>
                <meta property="og:image:width" content="1200" data-rh="true"/>
                <meta property="og:image:height" content="600" data-rh="true"/>
                <meta name="robots"
                      content="index, follow" data-rh="true"/>
                <meta name="googlebot"
                      content="index, follow" data-rh="true"/>
                <script type="application/ld+json">
                    {`
                        {
                            "@context": "https://schema.org",
                            "@type": "Organization",
                            "name": "EnergyCertiificate",
                            "url": "https://energycertiificate.com",
                            "logo": "https://energycertiificate.com/static/media/linear_inverse.8721dce6337c5dfda727.png"
                        }
                    `}
                </script>
            </Helmet>
            {modal.cookies && <Suspense fallback={<Loading/>}><CookiesComponent/></Suspense>}
        </>
    )
}


export default Metas;