import React from "react";
import {
    Card,
    CardBody,
    Col,
    UncontrolledCollapse
} from "reactstrap";


function QuestionsFaqs() {
    const urlCreateBack = process.env.REACT_APP_API_URL_SERVER_CREATE_ACCOUNT_BACK;
    const urlBack = process.env.REACT_APP_API_URL_SERVER_BACK;
    return (
        <>
            <Col lg="12">
                <div id="faq1">
                    <p><i className="fa fa-plus mr-2"></i>¿Cómo puedo registrarme?</p>
                </div>
                <UncontrolledCollapse toggler="#faq1" className="mb-3">
                    <Card>
                        <CardBody>
                            <p>
                                Puedes crearte una cuenta en el siguiente <a href={urlCreateBack}>enlace</a>
                            </p>
                        </CardBody>
                    </Card>
                </UncontrolledCollapse>
            </Col>

            <Col lg="12">
                <div id="faq2">
                    <p><i className="fa fa-plus mr-2"></i>¿Cómo puedo restablecer mi contraseña?</p>
                </div>
                <UncontrolledCollapse toggler="#faq2" className="mb-3">
                    <Card>
                        <CardBody>
                            <p>
                                Puedes restablecer la contraseña en el siguiente <a href={urlBack+'forget-password'}>enlace</a>
                            </p>
                        </CardBody>
                    </Card>
                </UncontrolledCollapse>
            </Col>

            <Col lg="12">
                <div id="faq3">
                    <p><i className="fa fa-plus mr-2"></i>¿Cuáles son los métodos de pago aceptados?</p>
                </div>
                <UncontrolledCollapse toggler="#faq3" className="mb-3">
                    <Card>
                        <CardBody>
                            <p>
                                Se aceptan todo tipo de pagos con tarjetas. Visa, MasterCard
                            </p>
                        </CardBody>
                    </Card>
                </UncontrolledCollapse>
            </Col>

            <Col lg="12">
                <div id="faq4">
                    <p><i className="fa fa-plus mr-2"></i>¿Cómo puedo contactar al servicio de atención al cliente?</p>
                </div>
                <UncontrolledCollapse toggler="#faq4" className="mb-3">
                    <Card>
                        <CardBody>
                            <p>
                                Puedes contactar con nosotros mediante el formulario que hay en nuestra página web.
                            </p>
                        </CardBody>
                    </Card>
                </UncontrolledCollapse>
            </Col>
            <Col lg="12">
                <div id="faq5">
                    <p><i className="fa fa-plus mr-2"></i>¿Dónde puedo encontrar información sobre los términos y
                        condiciones de uso del sitio?</p>
                </div>
                <UncontrolledCollapse toggler="#faq5" className="mb-3">
                    <Card>
                        <CardBody>
                            <p>
                                Puedes leer los terminos y condiciones en nuestra web mediante el siguiente en <a
                                href="/terminos-y-condiciones">enlace</a>.
                            </p>
                        </CardBody>
                    </Card>
                </UncontrolledCollapse>
            </Col>
            <Col lg="12">
                <div id="faq6">
                    <p><i className="fa fa-plus mr-2"></i>¿Cómo puedo actualizar mi información de contacto?</p>
                </div>
                <UncontrolledCollapse toggler="#faq6" className="mb-3">
                    <Card>
                        <CardBody>
                            <p>
                                Puedes modificar tu información de contacto una vez has iniciado sesión en tu cuenta >
                                Mi Perfil > Pestaña configuración.
                            </p>
                        </CardBody>
                    </Card>
                </UncontrolledCollapse>
            </Col>
            <Col lg="12">
                <div id="faq7">
                    <p><i className="fa fa-plus mr-2"></i>¿El sitio es seguro para realizar compras en línea?</p>
                </div>
                <UncontrolledCollapse toggler="#faq7" className="mb-3">
                    <Card>
                        <CardBody>
                            <p>
                                En nuestro sitio web no almacenamos ningún tipo de tarjeta. Ya que utilizamos la
                                pasarela de pago Stripe para procesar todos los pagos.
                                Nosotros no tenemos acceso a ningún numero de tarjeta (solo tenemos aceso a los 4
                                últimos dígitos). Puedes consultar en el siguiente <a
                                href="https://stripe.com/es/privacy">enlace</a> sobre Stripe.
                            </p>
                        </CardBody>
                    </Card>
                </UncontrolledCollapse>
            </Col>
            <Col lg="12">
                <div id="faq8">
                    <p><i className="fa fa-plus mr-2"></i>¿Qué debo hacer si tengo problemas para iniciar sesión en mi
                        cuenta?</p>
                </div>
                <UncontrolledCollapse toggler="#faq8" className="mb-3">
                    <Card>
                        <CardBody>
                            <p>
                                Puedes restablecer tu contraseña en el siguiente <a href={urlBack+'forget-password'}>enlace</a>. Si por este
                                medio no puedes restablecer tu contraseña contacta con nosotros en el correo
                                electrónico <a
                                href='mailto:admin@energycertiificate.com'>admin@energycertiificate.com</a>
                            </p>
                        </CardBody>
                    </Card>
                </UncontrolledCollapse>
            </Col>
            <Col lg="12">
                <div id="faq9">
                    <p><i className="fa fa-plus mr-2"></i>¿Como puedo darme de baja del servicio?</p>
                </div>
                <UncontrolledCollapse toggler="#faq9" className="mb-3">
                    <Card>
                        <CardBody>
                            <p>
                                Desde Mi perfil se ven las suscripciones que tienes actualmente, puedes darte de baja de
                                la suscripción cuando tu quieras. Lo que hay que tener en cuenta es que no se te
                                devolverá el dinero del servicio, si no que cuando acabe el mes vigente ya no se volverá
                                a hacer el cobro del servicio que has dado de baja.
                            </p>
                        </CardBody>
                    </Card>
                </UncontrolledCollapse>
            </Col>
            <Col lg="12">
                <div id="faq10">
                    <p><i className="fa fa-plus mr-2"></i>¿De donde se obtiene la información de los certificados
                        energéticos?</p>
                </div>
                <UncontrolledCollapse toggler="#faq10" className="mb-3">
                    <Card>
                        <CardBody>
                            <p>
                                Los certificados energéticos se obtienen de los portales oficiales de cada comunidad
                                autónoma. Estos son datos públicos facilitados para su uso, energy certiificate solo ha
                                facilitado su acceso y ha creado una ApiRest para facilitar el trabajo de buscar
                                certificados energéticos de cada comunidad autónoma.
                            </p>
                        </CardBody>
                    </Card>
                </UncontrolledCollapse>
            </Col>
            <Col lg="12">
                <div id="faq11">
                    <p><i className="fa fa-plus mr-2"></i>¿Con que regularidad se iran actualizando los certificados
                        energéticos?</p>
                </div>
                <UncontrolledCollapse toggler="#faq11" className="mb-3">
                    <Card>
                        <CardBody>
                            <p>
                                Cada comunidad autonoma suele publicar los certificados energéticos cada tantos meses.
                                Desde Energy Certiificate nos comprometemos a tener los certificados actualizados tan
                                pronto estén disponibles.
                            </p>
                        </CardBody>
                    </Card>
                </UncontrolledCollapse>
            </Col>
            <Col lg="12">
                <div id="faq12">
                    <p><i className="fa fa-plus mr-2"></i>¿No encuentro el certificado energético de un inmueble que
                        tiene?</p>
                </div>
                <UncontrolledCollapse toggler="#faq12" className="mb-3">
                    <Card>
                        <CardBody>
                            <p>
                                Si no encuentras tu certificado energético puede ser que sea reciente o la base de datos
                                no se encuentre actualizada. Nosotros obtenemos los certificados energéticos de las
                                publicaciones de las comunidades autónomas, puede ser que hayan publicado un nuevo
                                fichero. Puedes ponerte en contacto con nosotros en el formulario de la web, para
                                hacernos saber de que hay certificados que no se encuentran disponibles.
                            </p>
                        </CardBody>
                    </Card>
                </UncontrolledCollapse>
            </Col>
        </>);
}

export default QuestionsFaqs;