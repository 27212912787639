import React from "react";
import CertificateLetter from "./Letters/CertificateLetter";

const CertificateCastillaLeon = ({certificate}) => {

    return (
        <>
            <div className="card-body">
                <p><strong>Fecha</strong>: {certificate.emission_date}</p>
                <p><strong>Codigo de registro</strong>: {certificate.num_registration}</p>
                <p><strong>Dirección</strong>: {certificate.address}</p>
                <p><strong>Municipio</strong>: {certificate.municipality}</p>
                <p><strong>Consumo</strong>: {certificate.consumption_rate_primary}</p>
                <p><strong>Emisiones </strong>: {certificate.emission_co2_rate}</p>
                <p><strong>Classificación consumo </strong>: <CertificateLetter
                    letter={certificate.consumption_rating}/></p>
                <p><strong>Classificación emisiones </strong>:<CertificateLetter
                    letter={certificate.emission_rating}/></p>
            </div>
        </>

    );
}


export default CertificateCastillaLeon;