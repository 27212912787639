import React from "react";
import CertificateLetter from "./Letters/CertificateLetter";

const CertificateMadrid = ({certificate}) => {

    return (
        <>
            <div className="card-body">
                <p><strong>Fecha</strong>: {certificate.certificate_data.date}</p>
                <p><strong>Codigo de registro</strong>: {certificate.register_data.state_code}</p>
                <p><strong>Dirección</strong>: {certificate.building_identification.address}</p>
                <p><strong>Municipio</strong>: {certificate.building_identification.municipality}</p>
                <p><strong>Codigo postal</strong>: {certificate.building_identification.postal_code}</p>
                <p><strong>Zona climatica</strong>: {certificate.building_identification.climatic_zone}</p>
                <p><strong>Consumo</strong>: {certificate.consumption.primary_energy_no_renewable.global}</p>
                <p><strong>Emisiones </strong>: {certificate.emissions_co2.global}</p>
                <p><strong>Classificación consumo </strong>: <CertificateLetter
                    letter={certificate.rankings.energy_primary_no_renewable.global}/></p>
                <p><strong>Classificación emisiones </strong>:<CertificateLetter
                    letter={certificate.rankings.emissions_co2.global}/></p>
            </div>
        </>

    );
}


export default CertificateMadrid;