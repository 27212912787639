import React from "react";
import {createRoot, hydrateRoot} from "react-dom/client";
import RoutesPath from "./views/RoutesPath"

import "assets/vendor/nucleo/css/nucleo.css";
import "assets/vendor/font-awesome/css/font-awesome.min.css";
import "assets/scss/argon-design-system-react.scss?v1.1.0";

const container = document.getElementById("root");
const root = createRoot(container);


/*
if (container.hasChildNodes()) {
    hydrateRoot(container, <RoutesPath /> );
} else {
    root.render(<RoutesPath />, container);
}
*/
hydrateRoot(container, <RoutesPath /> );